import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Estate_Media_Type_Group_Enum,
  Estate_Media_Type_Name_Enum,
  EstateFragment,
  EstateMediaFragment,
} from '@gql'
import { useEstateMediaTypes } from '@/estate/hooks/useEstateMediaTypes'
import { EstateMedia } from '../EstateMedia/EstateMedia'
import { MissingEstateMedia } from '../EstateMedia/MissingEstateMedia'
import { NewEstateMedia } from '../EstateMedia/NewEstateMedia'

const config = {
  expectedMediaTypes: [
    { name: Estate_Media_Type_Name_Enum.Title, hasCensoredFile: true },
    { name: Estate_Media_Type_Name_Enum.CadastralPlan, hasCensoredFile: true },
    { name: Estate_Media_Type_Name_Enum.UrbanismNotice, hasCensoredFile: true },
    {
      name: Estate_Media_Type_Name_Enum.InsuranceCertificate,
      hasCensoredFile: true,
    },
    { name: Estate_Media_Type_Name_Enum.Plans, hasCensoredFile: true },
    { name: Estate_Media_Type_Name_Enum.PropertyTax, hasCensoredFile: true },
    {
      name: Estate_Media_Type_Name_Enum.ResidenceOrEmptyPropertyTax,
      hasCensoredFile: true,
    },
    {
      name: Estate_Media_Type_Name_Enum.DraftCompromise,
      hasCensoredFile: true,
    },
  ],
}

export type EstateEstateTabProps = {
  estate: EstateFragment
}
export const EstateEstateTab = ({ estate }: EstateEstateTabProps) => {
  const { t } = useTranslation()

  const estateMedias = useMemo(() => {
    return estate.estate_media.filter(
      (em) => em.type.group === Estate_Media_Type_Group_Enum.Estate
    )
  }, [estate])

  const expectedEstateMediasWithConfig = useMemo(() => {
    return config.expectedMediaTypes
      .map((e) => {
        const estateMedia = estate.estate_media.find(
          (em) =>
            em.type.group === Estate_Media_Type_Group_Enum.Estate &&
            em.type.name === e.name
        )
        return { estateMedia, hasCensoredFile: e.hasCensoredFile }
      })
      .filter((e) => !!e.estateMedia) as {
      estateMedia: EstateMediaFragment
      hasCensoredFile: boolean
    }[]
  }, [estate])

  const otherEstateMedias = useMemo(() => {
    return estateMedias.filter(
      (em) =>
        em.type.group === Estate_Media_Type_Group_Enum.Estate &&
        em.type.name === Estate_Media_Type_Name_Enum.Other
    )
  }, [estateMedias, expectedEstateMediasWithConfig])

  const { estateMediaTypes } = useEstateMediaTypes({
    group: Estate_Media_Type_Group_Enum.Estate,
  })

  const expectedMediaTypes = useMemo(() => {
    return estateMediaTypes.filter((type) =>
      config.expectedMediaTypes.map((e) => e.name).includes(type.name)
    )
  }, [estateMedias, estateMediaTypes])

  const missingMediaTypes = useMemo(() => {
    return expectedMediaTypes.filter(
      (type) => !estateMedias.find((em) => em.type.id === type.id)
    )
  }, [estateMedias, expectedMediaTypes])

  const otherType = useMemo(() => {
    return estateMediaTypes.find(
      (type) => type.name === Estate_Media_Type_Name_Enum.Other
    )
  }, [estateMediaTypes])

  return (
    <>
      <h2 className="tw-text-2xl tw-font-semibold tw-mb-5">
        {t('EstateEstateTab.title')}
      </h2>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-w-[650px]">
        {expectedEstateMediasWithConfig.map(
          ({ estateMedia, hasCensoredFile }) => (
            <EstateMedia
              key={estateMedia.id}
              estate={estate}
              estateMedia={estateMedia}
              hasCensoredFile={hasCensoredFile}
            />
          )
        )}
        {otherEstateMedias.map((estateMedia) => (
          <EstateMedia
            key={estateMedia.id}
            estate={estate}
            estateMedia={estateMedia}
          />
        ))}
        {missingMediaTypes.map((missingMediaType) => (
          <MissingEstateMedia
            key={missingMediaType.id}
            type={missingMediaType}
            estate={estate}
          />
        ))}
        {otherType && <NewEstateMedia estate={estate} type={otherType} />}
      </div>
    </>
  )
}
