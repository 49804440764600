import useCurrentMember from '@/sellerMember/hooks/useCurrentMember'
import useCurrentSeller from '@/seller/hooks/useCurrentSeller'
import { useUserData } from '@nhost/react'
import { Crisp } from 'crisp-sdk-web'
import { useEffect } from 'react'

export default function CrispSetUser() {
  const user = useUserData()
  const member = useCurrentMember()
  const seller = useCurrentSeller() // TODO use agency instead of seller

  const email = user?.email
  const nickname = member?.name || user?.displayName

  useEffect(() => {
    if (!email) return
    Crisp.user.setEmail(email)
  }, [email])

  useEffect(() => {
    if (!nickname) return
    Crisp.user.setNickname(nickname)
  }, [nickname])

  useEffect(() => {
    if (!seller) return
    Crisp.user.setCompany(seller.name, {})
  }, [seller?.name])

  return null
}
