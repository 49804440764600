import React from 'react'
import { useTranslation } from 'react-i18next'

type SellerHeaderProps = {
  candidatName: string
}

const SellerHeader = ({ candidatName }: SellerHeaderProps) => {
  const { t } = useTranslation()
  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-gap-2">
      <div className="tw-flex tw-flex-col tw-justify-center tw-text-stone-400 tw-text-xs tw-font-bold">
        {t('ApplicationSheet.offerBy')}
        <p className="tw-text-sm tw-font-medium tw-text-zinc-900">
          {candidatName}
        </p>
      </div>
    </div>
  )
}

export default SellerHeader
