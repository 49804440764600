import * as React from 'react'

import { cn } from '@/lib/utils'
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field'

export interface InputPriceProps extends CurrencyInputProps {
  className?: string
  disabled?: boolean
}

// Use onValueChange instead of onChange to avoid the input value being nan
const InputPrice = React.forwardRef<HTMLInputElement, InputPriceProps>(
  ({ className, ...props }, ref) => {
    return (
      <CurrencyInput
        className={cn(
          'tw-flex tw-h-10 tw-w-full tw-rounded-md tw-border tw-border-input tw-bg-background tw-px-3 tw-py-2 tw-text-sm tw-ring-offset-background placeholder:tw-text-muted-foreground focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-cursor-not-allowed disabled:tw-opacity-50 disabled:tw-bg-zinc-50',
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
InputPrice.displayName = 'InputPrice'

export { InputPrice }
