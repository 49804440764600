import React from 'react'
import { Table } from '@tanstack/react-table'

import { Input } from '@/components/ui/input'
import { Checkbox } from '@/components/ui/checkbox'
import { useTranslation } from 'react-i18next'
import { Label } from '@/components/ui/label'

interface SellersTableToolbarProps<T> {
  table: Table<T>
  setIncludeArchived: (value: boolean) => void
}

export function SellersTableToolbar<T>({
  table,
  setIncludeArchived,
}: SellersTableToolbarProps<T>) {
  const { t } = useTranslation()

  return (
    <div className="tw-flex tw-items-center tw-py-4 tw-gap-2 tw-justify-between">
      <div className="tw-flex tw-flex-1 tw-items-center tw-space-x-2">
        <Input
          placeholder={t('SellersTableToolbar.searchPlaceholder')}
          value={(table.getColumn('name')?.getFilterValue() as string) ?? ''}
          onChange={(event) =>
            table.getColumn('name')?.setFilterValue(event.target.value)
          }
          className="tw-max-w-sm"
        />
        <div className="tw-flex tw-items-center tw-space-x-2">
          <Checkbox
            id="archived"
            onCheckedChange={(event) => setIncludeArchived(event as boolean)}
          />
          <Label
            htmlFor="archived"
            className="tw-text-sm tw-font-medium peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70"
          >
            {t('SellersTableToolbar.checkbox.archived')}
          </Label>
        </div>
      </div>
    </div>
  )
}
