import { Button } from '@/components/ui/button'
import { Skeleton } from '@/components/ui/skeleton'
import { useGetPresignedUrl } from '@/file/hooks/useGetPresignedUrl'
import { useNavigateSeller } from '@/seller/hooks/useNavigateSeller'
import { Estate_Media_Type_Group_Enum, EstateFragment } from '@gql'
import { ExternalLinkIcon } from 'lucide-react'
import React from 'react'

type EstateCardListMediaPreviewProps = {
  estate: EstateFragment
}

export const EstateCardListMediaPreview = ({
  estate,
}: EstateCardListMediaPreviewProps) => {
  const navigate = useNavigateSeller()

  const fileId = estate.estate_media.find(
    (media) => media.type.group === Estate_Media_Type_Group_Enum.Media
  )?.file.id

  const { url, loading } = useGetPresignedUrl({
    fileId: String(fileId),
  })

  return (
    <div className="tw-max-w-[380px] tw-h-[270px] tw-overflow-hidden">
      {loading ? (
        <Skeleton className="tw-h-10 tw-w-10" />
      ) : (
        <>
          <Button
            variant="outline"
            className="tw-absolute tw-top-7 tw-left-7 tw-opacity-0 group-hover:tw-opacity-100"
            onClick={() => navigate(`estates/${estate.id}`)}
          >
            <ExternalLinkIcon className="tw-h-4 tw-w-4" />
          </Button>
          <img
            src={url ?? ''}
            alt="Estate picture"
            className="tw-object-cover"
          />
        </>
      )}
    </div>
  )
}
