import { SellerFragment } from '@gql'
import React from 'react'
import { columns } from './SellersTableColumns'
import { SellersTableToolbar } from './SellersTableToolbar'
import { SellersCoreTable } from './SellersCoreTable'
import { useTranslation } from 'react-i18next'

type SellersTableProps = {
  sellers?: SellerFragment[]
  setIncludeArchived: (value: boolean) => void
}
export const SellersTable = ({
  sellers,
  setIncludeArchived,
}: SellersTableProps) => {
  const { t } = useTranslation()

  return (
    <SellersCoreTable
      columns={columns(t)}
      data={sellers}
      toolbar={(table) => (
        <SellersTableToolbar
          table={table}
          setIncludeArchived={setIncludeArchived}
        />
      )}
    />
  )
}
