/**
 * Génère un nouveau numéro de registre unique selon le pattern 0101 à 9999.
 * @param existingNumbers - Un tableau de numéros de registre existants.
 * @returns Un nouveau numéro de registre unique.
 */

export default function useGenerateRegisterNumber(
  existingNumbers?: (string | undefined | null)[]
): string {
  if (!existingNumbers) {
    return '0101'
  }
  // Convertir les numéros existants en nombres
  const existingNumbersSet = new Set(
    existingNumbers.map((num) => parseInt(num || '0', 10))
  )

  // Commencer à 101 pour éviter le zéro initial
  let newNumber = 101

  // Chercher un numéro unique
  while (existingNumbersSet.has(newNumber)) {
    newNumber++
  }

  // Convertir le numéro en string avec le format "0101" jusqu'à "9999"
  const newRegisterNumber = newNumber.toString().padStart(4, '0')

  // Ajouter le nouveau numéro au set des numéros existants pour maintenir l'unicité
  existingNumbersSet.add(newNumber)

  return newRegisterNumber
}
