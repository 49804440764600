import { StarScore } from '@/common/atoms/StarScore'
import { useToast } from '@/components/ui/use-toast'
import { ApplicationFragment, useUpdateApplicationMutation } from '@gql'
import React from 'react'
import { useTranslation } from 'react-i18next'

type ApplicationsStarScoreRowProps = {
  application: ApplicationFragment
}
export const ApplicationsStarScoreRow = ({
  application: { score, id },
}: ApplicationsStarScoreRowProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const [updateApplicationScore] = useUpdateApplicationMutation()

  const changeScore = async (value: number) => {
    await updateApplicationScore({
      variables: { id, values: { score: value.toLocaleString() } },
      onCompleted: () => {
        toast({
          description: t('ApplicationsStarScoreRow.toast.success'),
          variant: 'success',
        })
      },
      onError: (error) => {
        toast({
          title: t('ApplicationsStarScoreRow.toast.error'),
          description: error.message,
          variant: 'destructive',
        })
      },
    })
  }

  return (
    <StarScore
      value={parseInt(score || '0')}
      onChange={(value) => changeScore(value)}
    />
  )
}
