import { Button } from '@/components/ui/button'
import { Input, InputProps } from '@/components/ui/input'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  TooltipArrow,
} from '@/components/ui/tooltip'
import { cn } from '@/lib/utils'
import { EyeIcon, EyeOffIcon } from 'lucide-react'
import React, { useState, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

const PasswordInput = forwardRef<HTMLInputElement, InputProps>(
  ({ className, onChange, ...props }, ref) => {
    const { t } = useTranslation()
    const [show, setShow] = useState(false)
    const [showButton, setShowButton] = useState(false)

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      setShowButton(() => e.target.value.length > 0)
      onChange?.(e)
    }

    const handleShowClick = (e: React.MouseEvent) => {
      e.preventDefault()
      setShow((s) => !s)
    }

    return (
      <div className="tw-relative">
        <Input
          type={show ? 'text' : 'password'}
          className={cn('tw-hide-password-toggle tw-pr-10', className)}
          onChange={handleChange}
          ref={ref}
          {...props}
        />

        {showButton && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  className="tw-absolute tw-top-0 tw-right-0 tw-h-full tw-px-3 tw-py-2 hover:tw-bg-transparent"
                  onClick={handleShowClick}
                >
                  {show ? <EyeOffIcon size={18} /> : <EyeIcon size={18} />}
                  <span className="tw-sr-only">{t('PasswordInput.show')}</span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <TooltipArrow />
                <p>{t(show ? 'common.hide' : 'common.show')}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
    )
  }
)

PasswordInput.displayName = 'PasswordInput'

export default PasswordInput
