import React from 'react'

type ApplicationEntryProps = {
  title: string
  value: React.JSX.Element | string
}

const ApplicationEntry = ({ title, value }: ApplicationEntryProps) => {
  return (
    <div className="tw-w-full tw-flex tw-flex-row tw-justify-between tw-items-center tw-gap-4">
      <p className="tw-text-stone-400 tw-text-sm">{title}</p>
      {typeof value === 'string' ? (
        <p className="tw-text-sm tw-font-medium tw-text-zinc-900">{value}</p>
      ) : (
        value
      )}
    </div>
  )
}

export default ApplicationEntry
