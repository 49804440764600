import React from 'react'
import { MembersList } from '../components/MembersList'

export const MembersPage = () => {
  return (
    <div className="tw-p-8 tw-flex tw-justify-center">
      <MembersList />
    </div>
  )
}
