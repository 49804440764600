import React, { useMemo } from 'react'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { InputPrice } from '@/components/ui/inputPrice'
import { TimePicker } from '@/components/ui/timePicker'
import { useToast } from '@/components/ui/use-toast'
import { EstateFragment, useUpdateEstateMutation } from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import { InfoIcon } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { useTranslation, TFunction } from 'react-i18next'
import * as yup from 'yup'
import { Button } from '@/components/ui/button'
import { DialogFooter } from '@/components/ui/dialog'
import { InputDatePicker } from '@/components/ui/InputDatePicker'

const resolver = (t: TFunction) =>
  yupResolver(
    yup.object().shape({
      firstPrice: yup.number().required(),
      reservePrice: yup.number().required(),
      startDate: yup
        .object()
        .shape({ day: yup.date().required(), hour: yup.date().required() }),
      endDate: yup
        .object()
        .shape({ day: yup.date().required(), hour: yup.date().required() }),
    })
  )

export type EstateSettingOpeningFormValues = {
  firstPrice: number
  reservePrice: number
  startDate: {
    day: Date
    hour: Date
  }
  endDate: {
    day: Date
    hour: Date
  }
}

type EstateSettingOpeningFormProps = {
  estate: EstateFragment
  readOnly?: boolean
}

export const EstateSettingOpeningForm = ({
  estate,
  readOnly,
}: EstateSettingOpeningFormProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const [updateEstate] = useUpdateEstateMutation()

  const defaultValues = useMemo(() => {
    const startDate = estate.saleData.firstRound.startDate
      ? new Date(estate.saleData.firstRound.startDate)
      : undefined

    const endDate = estate.saleData.firstRound.endDate
      ? new Date(estate.saleData.firstRound.endDate)
      : undefined

    return {
      saleOpen: estate.saleData.saleOpen,
      firstPrice: estate.saleData.firstRound.firstPrice,
      reservePrice: estate.saleData.firstRound.reservePrice,
      startDate: {
        day: startDate,
        hour: startDate,
      },
      endDate: {
        day: endDate,
        hour: endDate,
      },
    }
  }, [estate.saleData])

  const form = useForm<EstateSettingOpeningFormValues>({
    resolver: resolver(t),
    defaultValues,
  })

  const hasDirtyFields = Object.keys(form.formState.dirtyFields).length > 0

  const onSubmit = async (values: EstateSettingOpeningFormValues) => {
    await updateEstate({
      variables: {
        id: estate.id,
        values: {
          saleData: {
            saleOpen: true,
            firstRound: {
              startDate: values.startDate.hour,
              endDate: values.endDate.hour,
              firstPrice: values.firstPrice,
              reservePrice: values.reservePrice,
            },
          },
        },
      },
      onCompleted: () => {
        toast({
          variant: 'success',
          description: t('EstateSettingOpeningForm.toast.success'),
        })
      },
      onError: (error) => {
        toast({
          variant: 'destructive',
          title: t('EstateSettingOpeningForm.toast.error'),
          description: error.message,
        })
      },
    })
  }

  return (
    <Form {...form}>
      <form
        className="tw-space-y-6 tw-pt-6 tw-h-full"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="firstPrice"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('EstateSettingOpeningForm.firstPrice')}</FormLabel>
              <FormControl>
                <InputPrice
                  step={1}
                  suffix="€"
                  value={field.value}
                  onValueChange={(value) => field.onChange(value)}
                  disabled={readOnly}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="reservePrice"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('EstateSettingOpeningForm.reservePrice')}
              </FormLabel>
              <FormControl>
                <InputPrice
                  step={1}
                  suffix="€"
                  value={field.value}
                  onValueChange={(value) => field.onChange(value)}
                  disabled={readOnly}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {!readOnly && (
          <div className="tw-py-3 tw-p-4 tw-bg-green-200 tw-rounded-lg">
            <p className="tw-text-sm tw-font-normal tw-text-green-700 tw-flex tw-items-center">
              <InfoIcon strokeWidth={3} className="tw-mr-3" />
              {t('EstateSettingOpeningForm.description', {
                price: form.getValues('firstPrice') || '?',
              })}
            </p>
          </div>
        )}
        <div className="tw-flex tw-space-x-4 ">
          <FormField
            control={form.control}
            name="startDate.day"
            render={({ field }) => (
              <FormItem className="tw-flex tw-flex-col tw-w-full">
                <FormLabel>
                  {t('EstateSettingOpeningForm.startDate.date')}
                </FormLabel>
                <InputDatePicker
                  {...field}
                  fromYear={1900}
                  toYear={2100}
                  disabled={readOnly}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="startDate.hour"
            render={({ field }) => (
              <FormItem className="tw-flex tw-flex-col tw-w-full">
                <FormLabel>
                  {t('EstateSettingOpeningForm.startDate.hour')}
                </FormLabel>
                <TimePicker
                  date={form.getValues('startDate.day')}
                  startTime="09:00"
                  endTime="19:00"
                  value={field.value}
                  onValueChange={field.onChange}
                  disabled={readOnly}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="tw-flex tw-space-x-4">
          <FormField
            control={form.control}
            name="endDate.day"
            render={({ field }) => (
              <FormItem className="tw-flex tw-flex-col tw-w-full">
                <FormLabel>
                  {t('EstateSettingOpeningForm.endDate.date')}
                </FormLabel>
                <InputDatePicker
                  {...field}
                  fromYear={1900}
                  toYear={2100}
                  disabled={readOnly}
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="endDate.hour"
            render={({ field }) => (
              <FormItem className="tw-flex tw-flex-col tw-w-full">
                <FormLabel>
                  {t('EstateSettingOpeningForm.endDate.hour')}
                </FormLabel>
                <TimePicker
                  date={form.getValues('endDate.day')}
                  startTime="09:00"
                  endTime="19:00"
                  value={field.value}
                  onValueChange={field.onChange}
                  disabled={readOnly}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <DialogFooter>
          {!readOnly && (
            <Button
              variant="secondary"
              type="submit"
              disabled={!hasDirtyFields}
            >
              {t('EstateSettingOpeningForm.submit')}
            </Button>
          )}
        </DialogFooter>
      </form>
    </Form>
  )
}
