import React, { forwardRef } from 'react'
import { EnumSelect, EnumSelectProps } from '../../../enum/EnumSelect'
import { useTranslation } from 'react-i18next'
import { Estate_Type_Enum } from '@gql'

export type EstateTypeSelectProps = Omit<
  EnumSelectProps,
  'entries' | 'label' | 'placeholder'
>
export const EstateTypeSelect = forwardRef<
  HTMLInputElement,
  EstateTypeSelectProps
>((props, ref) => {
  const { t } = useTranslation()

  const entries = Object.values(Estate_Type_Enum).reduce(
    (acc, type) => {
      acc[type] = t(`common.estate.${type}`)
      return acc
    },
    {} as Record<Estate_Type_Enum, string>
  )

  return (
    <div>
      <EnumSelect
        {...props}
        entries={entries}
        label={t('EstateTypeSelect.label')}
        placeholder={t('EstateTypeSelect.placeholder')}
        ref={ref}
      />
    </div>
  )
})

EstateTypeSelect.displayName = 'EstateTypeSelect'
