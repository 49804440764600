import { ApolloError } from '@apollo/client'
import { AuthErrorPayload } from '@nhost/hasura-auth-js'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  error: Error | ApolloError | AuthErrorPayload
}

export default function TextError({ error }: Props) {
  const { t } = useTranslation()

  return (
    <p className="tw-text-red-500 tw-font-bold">
      {t(`TextError.${(error as any).code}` as any, {
        defaultValue:
          error.message || `Error ${(error as AuthErrorPayload).status}`,
      })}
    </p>
  )
}
