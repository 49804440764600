import React, { forwardRef, useMemo } from 'react'
import { MultiSelect, MultiSelectProps } from '@/components/ui/multi-select'
import { useTranslation } from 'react-i18next'
import { XIcon } from 'lucide-react'
import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { getInitials } from '@utils/getInitials'
import { StarScore } from '@/common/atoms/StarScore'
import { useGetEstateApplicantsQuery } from '@gql'
import Loading from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'

type EstateBuyerMemberMultiSelectProps = MultiSelectProps & {
  className?: string
  estateId: string
}

export const EstateBuyerMemberMultiSelect = forwardRef<
  HTMLSelectElement,
  EstateBuyerMemberMultiSelectProps
>(({ className, estateId, ...props }, ref) => {
  const { t } = useTranslation()

  const { data, loading, error } = useGetEstateApplicantsQuery({
    variables: {
      id: estateId,
    },
  })

  if (!data?.estate_by_pk?.applications) return null

  const buyerMembers: MultiSelectProps['options'] = useMemo(() => {
    const applications = data.estate_by_pk?.applications ?? []
    return applications.map((application) => ({
      value: application.buyer_member.id,
      label: application.buyer_member.name,
    }))
  }, [data])

  if (loading) return <Loading center active />
  if (error) return <TextError error={error} />

  const displaySelectedItems = (id: string) => {
    const buyerMember = buyerMembers.find(
      (buyerMember) => buyerMember.value === id
    )
    if (!buyerMember) return null
    return (
      <div
        key={id}
        className="tw-flex tw-items-center tw-justify-between tw-bg-zinc-50 tw-px-3 tw-py-2 tw-rounded-md "
      >
        <div className="tw-flex tw-items-center tw-gap-2">
          <Avatar className="tw-flex tw-h-8 tw-w-8 tw-border">
            <AvatarFallback>
              {getInitials(buyerMember.label ?? '')}
            </AvatarFallback>
          </Avatar>
          <p className="tw-text-sm">{buyerMember.label}</p>
        </div>
        <div className="tw-flex tw-items-center tw-gap-4">
          <StarScore value={3} disabled />
          <XIcon
            className="tw-ml-2 tw-h-4 tw-w-4 tw-cursor-pointer"
            onClick={(event) => {
              event.stopPropagation()
              props.onValueChange(
                props.defaultValue.filter((f) => f !== buyerMember.value)
              )
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <div>
      <FormItem className="tw-flex tw-flex-col tw-w-full">
        <FormLabel>{t('EstateBuyerMemberMultiSelect.label')}</FormLabel>
        <FormControl>
          <MultiSelect
            options={buyerMembers}
            placeholder={t('EstateBuyerMemberMultiSelect.placeholder')}
            variant="outline"
            {...props}
          />
        </FormControl>
        <FormMessage />
      </FormItem>

      <div className="tw-my-4 tw-flex tw-flex-col tw-gap-2">
        {props.defaultValue.map((value) => displaySelectedItems(value))}
      </div>
    </div>
  )
})

EstateBuyerMemberMultiSelect.displayName = 'EstateBuyerMemberMultiSelect'
