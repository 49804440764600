import { EstateFragment } from '@gql'
import React, { useMemo, useState } from 'react'
import { columns } from './EstatesTableColumns'
import { EstatesTableToolbar } from './EstatesTableToolbar'
import { useTranslation } from 'react-i18next'
import { useDebounce } from '@utils/useDebounce'
import { EstatesCoreTable } from './EstatesCoreTable'

type EstatesTableProps = {
  estates?: EstateFragment[]
  setIncludeArchived: (value: boolean) => void
}
export const EstatesTable = ({
  estates,
  setIncludeArchived,
}: EstatesTableProps) => {
  const { t } = useTranslation()

  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const filteredData = useMemo(() => {
    if (!debouncedSearchTerm) return estates

    return estates?.filter((estate) => {
      const lowercasedTerm = debouncedSearchTerm.toLowerCase()
      return (
        (estate.name && estate.name.toLowerCase().includes(lowercasedTerm)) ||
        estate.address.toLowerCase().includes(lowercasedTerm) ||
        estate.city.toLowerCase().includes(lowercasedTerm) ||
        estate.zipCode.includes(lowercasedTerm)
      )
    })
  }, [debouncedSearchTerm, estates])

  return (
    <EstatesCoreTable
      columns={columns(t)}
      data={filteredData}
      toolbar={(table, grouping, setGrouping) => (
        <EstatesTableToolbar
          table={table}
          setIncludeArchived={setIncludeArchived}
          grouping={grouping}
          setGrouping={setGrouping}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      )}
    />
  )
}
