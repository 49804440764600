'use client'
import React, { ForwardedRef, forwardRef } from 'react'
import { Button } from '@/components/ui/button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { Locale } from 'date-fns'
import { CalendarDaysIcon } from 'lucide-react'
import { fr } from 'date-fns/locale'
import { DayPickerMultipleProps } from 'react-day-picker'
import { DatePicker } from './datePicker'

interface InputMultipleDatePickerProps
  extends Omit<
    DayPickerMultipleProps,
    'value' | 'disabled' | 'onSelect' | 'mode'
  > {
  placeholder?: string
  locale?: Locale
  disabled?: boolean
  value?: Date[]
  onChange?: (value?: Date[]) => void
  disabledDates?: Date[]
}

const InputMultipleDatePickerInner = (
  {
    placeholder,
    disabled,
    locale = fr,
    value,
    onChange,
    disabledDates,
    ...props
  }: InputMultipleDatePickerProps,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  return (
    <Popover>
      <div className="tw-relative tw-w-full">
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            disabled={disabled}
            variant="outline"
            type="button"
            className={cn(
              'tw-relative tw-flex !tw-justify-start tw-font-normal',
              !value && 'tw-text-muted-foreground',
              disabled && 'tw-opacity-50 tw-cursor-not-allowed'
            )}
          >
            <CalendarDaysIcon className="tw-w-4 tw-h-4" />
            <span className="tw-ml-2">{placeholder}</span>
          </Button>
        </PopoverTrigger>
      </div>
      <PopoverContent align="end" className="tw-w-auto tw-p-0">
        <DatePicker
          locale={locale}
          mode="multiple"
          captionLayout="dropdown-buttons"
          selected={value}
          disabled={disabledDates}
          onSelect={(selectedDate) => {
            if (!selectedDate) return
            onChange?.(selectedDate)
          }}
          toYear={props.toYear ?? undefined}
          {...props}
        />
      </PopoverContent>
    </Popover>
  )
}

export const InputMultipleDatePicker = forwardRef(InputMultipleDatePickerInner)
