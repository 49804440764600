import { SellerFragment } from '@gql'
import React from 'react'
import { SellerInfoForm } from './SellerInfoForm'

type SellerInfoCardProps = {
  seller?: SellerFragment
}

export const SellerInfoCard = ({ seller }: SellerInfoCardProps) => {
  return <SellerInfoForm seller={seller} />
}
