import React from 'react'
import { EstateFragment } from '@gql'
import { GenericForm, GenericFormConfig } from '../../../form/GenericForm'
import { useTranslation } from 'react-i18next'

export type EstateCoownershipFormProps = {
  estate: EstateFragment
  onSubmit: (values: Partial<EstateFragment>) => void
}
export const EstateCoownershipForm = ({
  estate,
  onSubmit,
}: EstateCoownershipFormProps) => {
  const { t } = useTranslation()

  const isCoownedCondition = ({ isCoowned }: any) => isCoowned

  const config: GenericFormConfig = [
    {
      name: 'isCoowned',
      type: 'boolean',
    },
    {
      name: 'syndicExtranetURL',
      condition: isCoownedCondition,
    },
    {
      name: 'syndicExtranetLogin',
      condition: isCoownedCondition,
    },
    {
      name: 'syndicExtranetPassword',
      condition: isCoownedCondition,
    },
    {
      name: 'totalLotsNumber',
      type: 'number',
      condition: isCoownedCondition,
    },
    {
      name: 'ongoingProcedures',
      type: 'boolean',
      condition: isCoownedCondition,
    },
  ]

  const handleSubmit = (
    coownership: Partial<EstateFragment['coownership']>
  ) => {
    onSubmit({ coownership })
  }

  return (
    <GenericForm
      t={t}
      t_key="EstateCoownershipForm"
      config={config}
      values={estate.coownership}
      onSubmit={handleSubmit}
      context={{ estate }}
    />
  )
}
