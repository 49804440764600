import React, {
  ChangeEvent,
  InputHTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { FileUploadParams, FileUploadProps } from '@/file/FileUpload'
import { DialogProps } from '@radix-ui/react-dialog'
import { useTranslation } from 'react-i18next'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/use-toast'
import { useFileUpload } from '@nhost/react'
import { Button } from '@/components/ui/button'
import { Progress } from '@/components/ui/progress'
import Loading from '@/common/atoms/Loading'

export type OnUploadProps = FileUploadParams & {
  customName?: string
}

export type FileUploadDialogProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type'
> &
  Pick<FileUploadProps, 'uploadParams'> &
  Pick<DialogProps, 'open' | 'onOpenChange'> & {
    children?: ReactNode
    title?: ReactNode
    description?: ReactNode
    customName?: string
    onUpload?: (params: OnUploadProps) => void
    canSetCustomName?: boolean
    loading?: boolean
  }
export const FileUploadDialog = ({
  onUpload,
  uploadParams = {},
  open,
  onOpenChange,
  children,
  title,
  description,
  customName: _customName = '',
  canSetCustomName,
  loading,
  ...props
}: FileUploadDialogProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const [customName, setCustomName] = useState(_customName)
  const handleCustomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomName(event.target.value)
  }

  const [isFileSet, setIsFileSet] = useState(false)
  const {
    add,
    upload,
    cancel,
    isUploaded,
    isUploading,
    isError,
    progress,
    id,
    bucketId,
    name,
  } = useFileUpload()

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return
    add({ file })
    setIsFileSet(true)
  }

  const handleSubmit = useCallback(async () => {
    if (!isFileSet) return
    await upload(uploadParams)
    setIsFileSet(false)
  }, [isFileSet])

  const handleCancel = useCallback(() => {
    cancel()
  }, [])

  useEffect(() => {
    if (isUploaded) {
      const params = { id, bucketId, name }
      if (canSetCustomName) {
        onUpload?.({ ...params, customName })
      } else {
        onUpload?.(params)
      }
      toast({
        variant: 'success',
        description: t('FileUpload.toast.success'),
      })
    }
  }, [isUploaded])

  useEffect(() => {
    if (isError) {
      toast({
        variant: 'destructive',
        description: t('FileUpload.toast.error'),
      })
    }
  }, [isError])

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {children}
      {loading && <Loading active={true} />}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title || t('FileUploadDialog.title')}</DialogTitle>
          {!!description && (
            <DialogDescription>{description}</DialogDescription>
          )}
        </DialogHeader>
        {canSetCustomName && (
          <Label className="tw-flex tw-flex-col tw-gap-2">
            <>{t('FileUploadDialog.customName')}</>
            <Input
              type="text"
              value={customName}
              onChange={handleCustomNameChange}
            />
          </Label>
        )}
        {isUploading ? (
          <>
            <Progress value={progress} />
            <Button variant="destructive" onClick={handleCancel}>
              {t('common.cancel')}
            </Button>
          </>
        ) : (
          <>
            <Input
              type="file"
              multiple={false}
              onChange={handleFileChange}
              {...props}
            />
            <Button onClick={handleSubmit} disabled={!isFileSet}>
              {t('common.send')}
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
