import { useState, useEffect } from 'react'

export type UseVideoThumbnailProps = {
  url?: string
}
export const useVideoThumbnail = ({ url }: UseVideoThumbnailProps) => {
  const [thumbnail, setThumbnail] = useState<string>()
  // TODO use cache

  useEffect(() => {
    if (!url) return

    const videoElement = document.createElement('video')
    videoElement.crossOrigin = 'anonymous'
    videoElement.style.display = 'none'
    document.body.appendChild(videoElement)

    const canvasElement = document.createElement('canvas')
    canvasElement.style.display = 'none'
    document.body.appendChild(canvasElement)

    const context = canvasElement.getContext('2d')
    if (!context) return

    const handleLoadedData = () => {
      canvasElement.width = videoElement.videoWidth
      canvasElement.height = videoElement.videoHeight
      videoElement.currentTime = videoElement.duration / 2
    }

    const handleSeeked = () => {
      context.drawImage(
        videoElement,
        0,
        0,
        canvasElement.width,
        canvasElement.height
      )
      const dataUrl = canvasElement.toDataURL('image/png')
      setThumbnail(dataUrl)
    }

    videoElement.addEventListener('loadeddata', handleLoadedData)
    videoElement.addEventListener('seeked', handleSeeked)

    videoElement.src = url

    return () => {
      videoElement.removeEventListener('loadeddata', handleLoadedData)
      videoElement.removeEventListener('seeked', handleSeeked)
      document.body.removeChild(videoElement)
      document.body.removeChild(canvasElement)
    }
  }, [url])

  return { thumbnail }
}
