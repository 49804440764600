import { Card, CardContent } from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Mandate_Fee_Person_Enum,
  Mandate_Type_Enum,
  MandateFragment,
  SellerFragment,
} from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useMemo } from 'react'
import { useForm, DefaultValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { MandateTypeSelect } from './MandateTypeSelect'
import { MandateFeePersonSelect } from './MandateFeePersonSelect'
import { Textarea } from '@/components/ui/textarea'
import { format } from 'date-fns'
import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'
import { Label } from '@/components/ui/label'
import { fr } from 'date-fns/locale'
import { InputPrice } from '@/components/ui/inputPrice'
import { omit } from '@utils/omit'
import { InputDatePicker } from '@/components/ui/InputDatePicker'

const schema = yup.object().shape({
  type: yup.mixed<Mandate_Type_Enum>().oneOf(Object.values(Mandate_Type_Enum)),
  registryNumber: yup.string().optional(),
  nameOfRepresentatives: yup.string().optional(),
  addressOfRepresentatives: yup.string().optional(),
  object: yup.string().optional(),
  estate: yup.string().optional(),
  amendmentNumber: yup.string().optional(),
  precontractualDate: yup.date().optional(),
  mainSignDate: yup.date().optional(),
  reservePrice: yup.string().optional(),
  firstPrice: yup.string().optional(),
  effectiveDate: yup.date().optional(),
  initialDuration: yup.number().required(), // default = 6
  renewalDuration: yup.number().required(), // default = 3
  maxRenewal: yup.number().required(), // default = 6
  fixedFee: yup.number().required(), // default = 3500
  transactionalFee: yup.number().required(), // default = 3.5
  earnedMoney: yup.number().optional(), // default = 10
  earnedMoneyPaid: yup.number().optional(), // default = 5
  feePerson: yup
    .mixed<Mandate_Fee_Person_Enum>()
    .oneOf(Object.values(Mandate_Fee_Person_Enum)),
  feeAmount: yup.number().required(),
  particularConditions: yup.string().optional(),
  cancellationDate: yup.date().optional(),
  effectiveCancellationDate: yup.date().optional(),
})

const resolver = yupResolver(schema)

type MandateInfoFormValues = yup.InferType<typeof schema>

type MandateInfoFormProps = {
  mandate?: MandateFragment | null
  sellers: SellerFragment[]
  readOnly?: boolean
  onSubmit: (
    values: Partial<MandateFragment>,
    mandateId?: MandateFragment['id']
  ) => void
}

export const MandateInfoForm = ({
  mandate,
  sellers,
  readOnly = false,
  onSubmit,
}: MandateInfoFormProps) => {
  const { t } = useTranslation()
  const isAgencyMember = useIsAgencyMember()

  const availableSellers = useMemo(() => {
    const otherSellers = sellers.filter((s) =>
      mandate?.estate.sellerData?.sellerStructures?.find((ss) => {
        return ss.sellerId === s.id
      })
    )
    return [mandate?.estate.seller].concat(otherSellers)
  }, [sellers])

  const nameOfRepresentatives = useMemo(() => {
    return (
      mandate &&
      availableSellers
        .map(
          (seller, index) =>
            `${index + 1}. ${seller?.name ?? t('MandateInfoForm.defaultValues.representatives.emptyValue')}`
        )
        .join('\n')
    )
  }, [mandate])

  const addressOfRepresentatives = useMemo(() => {
    return (
      mandate &&
      availableSellers
        .map(
          (seller, index) =>
            `${index + 1}. ${seller?.address ?? t('MandateInfoForm.defaultValues.representatives.emptyValue')}`
        )
        .join('\n')
    )
  }, [mandate])

  const defaultValues: DefaultValues<MandateInfoFormValues> = useMemo(() => {
    return {
      type: mandate?.type || Mandate_Type_Enum.Delegation,
      registryNumber: mandate?.registryNumber || undefined,
      nameOfRepresentatives: nameOfRepresentatives || '',
      addressOfRepresentatives: addressOfRepresentatives || '',
      object: mandate?.type ? t(`common.mandate.${mandate?.type}`) : '',
      estate:
        t('MandateInfoForm.defaultValues.estateLabel', {
          type: mandate?.estate.type
            ? t(`common.estate.${mandate?.estate.type}`)
            : '',
          address: mandate?.estate.address,
          zipCode: mandate?.estate.zipCode,
          city: mandate?.estate.city,
        }) || '',
      amendmentNumber: mandate?.amendmentNumber || '',
      precontractualDate: mandate?.precontractualDate
        ? new Date(mandate?.precontractualDate)
        : new Date(),
      mainSignDate: mandate?.mainSignDate
        ? new Date(mandate?.mainSignDate)
        : undefined,
      reservePrice: mandate?.reservePrice || '',
      firstPrice: mandate?.firstPrice || '',
      effectiveDate: mandate?.effectiveDate
        ? new Date(mandate?.effectiveDate)
        : undefined,
      initialDuration: mandate?.initialDuration || 6,
      renewalDuration: mandate?.renewalDuration || 3,
      maxRenewal: mandate?.maxRenewal || 6,
      fixedFee: mandate?.fixedFee || 3500,
      transactionalFee: mandate?.transactionalFee || 3.5,
      feePerson: mandate?.feePerson || Mandate_Fee_Person_Enum.Buyer,
      feeAmount: mandate?.feeAmount || 0,
      particularConditions: mandate?.particularConditions || '',
      earnedMoney: mandate?.earnedMoney || 10,
      earnedMoneyPaid: mandate?.earnedMoneyPaid || 5,
      cancellationDate: mandate?.cancellationDate
        ? new Date(mandate?.cancellationDate)
        : undefined,
      effectiveCancellationDate: mandate?.effectiveCancellationDate
        ? new Date(mandate?.effectiveCancellationDate)
        : undefined,
    }
  }, [mandate])

  const form = useForm<MandateInfoFormValues>({
    resolver,
    defaultValues,
  })
  const { handleSubmit, control } = form

  const isReadonly = useMemo(() => {
    return !isAgencyMember || readOnly
  }, [isAgencyMember, readOnly])

  useEffect(() => {
    const subscription = form.watch(() => {
      handleSubmit((values) => {
        const mandateValues = omit(
          values,
          'estate',
          'object',
          'nameOfRepresentatives',
          'addressOfRepresentatives'
        )
        onSubmit(
          {
            ...mandateValues,
            mainSignDate: mandateValues.mainSignDate?.toISOString(),
            precontractualDate: mandateValues.precontractualDate?.toISOString(),
            effectiveDate: mandateValues.effectiveDate?.toISOString(),
            cancellationDate: mandateValues.cancellationDate?.toISOString(),
            effectiveCancellationDate:
              mandateValues.effectiveCancellationDate?.toISOString(),
          },
          mandate?.id
        )
      })()
    })

    return () => subscription.unsubscribe()
  }, [form, mandate])

  return (
    <Form {...form}>
      <Card>
        <CardContent className="tw-space-y-6 tw-pt-6">
          <FormField
            control={control}
            name="type"
            render={({ field }) => (
              <MandateTypeSelect field={field} disabled={isReadonly} />
            )}
          />
          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="registryNumber"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.registryNumber')}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="tw-w-1/2 tw-space-y-2">
              <Label htmlFor="generatedAt">
                {t('MandateInfoForm.field.generatedAt')}
              </Label>
              <Input
                id="generatedAt"
                disabled
                value={
                  mandate?.generatedAt
                    ? format(
                        new Date(mandate?.generatedAt),
                        'dd MMMM yyyy HH:mm',
                        { locale: fr }
                      )
                    : ''
                }
              />
            </div>
          </div>

          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="nameOfRepresentatives"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.nameOfRepresentatives')}
                  </FormLabel>
                  <FormControl>
                    <Textarea {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="addressOfRepresentatives"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.addressOfRepresentatives')}
                  </FormLabel>
                  <FormControl>
                    <Textarea {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="tw-flex tw-space-x-4 tw-w-full">
            <FormField
              control={control}
              name="object"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>{t('MandateInfoForm.field.object')}</FormLabel>
                  <FormControl>
                    <Input {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="estate"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>{t('MandateInfoForm.field.estate')}</FormLabel>
                  <FormControl>
                    <Textarea {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={control}
            name="amendmentNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('MandateInfoForm.field.amendmentNumber')}
                </FormLabel>
                <FormControl>
                  <Input {...field} disabled={isReadonly} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="precontractualDate"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.precontractualDate')}
                  </FormLabel>
                  <FormControl>
                    <InputDatePicker
                      {...field}
                      fromYear={1900}
                      toYear={2100}
                      disabled={isReadonly}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="mainSignDate"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.mainSignDate')}
                  </FormLabel>
                  <FormControl>
                    <InputDatePicker
                      {...field}
                      fromYear={1900}
                      toYear={2100}
                      disabled={isReadonly}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="reservePrice"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.reservePrice')}
                  </FormLabel>
                  <FormControl>
                    <InputPrice
                      step={1}
                      suffix="€"
                      value={field.value}
                      onValueChange={(value) => field.onChange(value)}
                      disabled={isReadonly}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="firstPrice"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>{t('MandateInfoForm.field.firstPrice')}</FormLabel>
                  <FormControl>
                    <InputPrice
                      step={1}
                      suffix="€"
                      value={field.value}
                      onValueChange={(value) => field.onChange(value)}
                      disabled={isReadonly}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="effectiveDate"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.effectiveDate')}
                  </FormLabel>
                  <FormControl>
                    <InputDatePicker
                      {...field}
                      fromYear={1900}
                      toYear={2100}
                      disabled={isReadonly}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="initialDuration"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.initialDuration')}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} disabled={isReadonly} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="renewalDuration"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.renewalDuration')}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} disabled={isReadonly} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="maxRenewal"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>{t('MandateInfoForm.field.maxRenewal')}</FormLabel>
                  <FormControl>
                    <Input {...field} disabled={isReadonly} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="fixedFee"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>{t('MandateInfoForm.field.fixedFee')}</FormLabel>
                  <FormControl>
                    <InputPrice
                      step={1}
                      suffix="€"
                      value={field.value}
                      onValueChange={(value) => field.onChange(value)}
                      disabled={isReadonly}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="transactionalFee"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.transactionalFee')}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} disabled={isReadonly} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={control}
            name="earnedMoney"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('MandateInfoForm.field.earnedMoney')}</FormLabel>
                <FormControl>
                  <Input {...field} disabled={isReadonly} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="earnedMoneyPaid"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('MandateInfoForm.field.earnedMoneyPaid')}
                </FormLabel>
                <FormControl>
                  <Input {...field} disabled={isReadonly} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="feePerson"
            render={({ field }) => (
              <MandateFeePersonSelect field={field} disabled={isReadonly} />
            )}
          />

          <FormField
            control={control}
            name="feeAmount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('MandateInfoForm.field.feeAmount')}</FormLabel>
                <FormControl>
                  <InputPrice
                    step={1}
                    suffix="€"
                    value={field.value}
                    onValueChange={(value) => field.onChange(value)}
                    disabled={isReadonly}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="particularConditions"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('MandateInfoForm.field.particularConditions')}
                </FormLabel>
                <FormControl>
                  <Textarea {...field} disabled={isReadonly} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="cancellationDate"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.cancellationDate')}
                  </FormLabel>
                  <FormControl>
                    <InputDatePicker
                      {...field}
                      fromYear={1900}
                      toYear={2100}
                      disabled={isReadonly}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="effectiveCancellationDate"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.effectiveCancellationDate')}
                  </FormLabel>
                  <FormControl>
                    <InputDatePicker
                      {...field}
                      fromYear={1900}
                      toYear={2100}
                      disabled={isReadonly}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </CardContent>
      </Card>
    </Form>
  )
}
