import { Progress } from '@/components/ui/progress'
import { EstateFragment } from '@gql'
import { format, isAfter, isBefore, isToday } from 'date-fns'
import { fr } from 'date-fns/locale'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type ApplicationsTimelineProps = {
  estate: EstateFragment
}

export const ApplicationsTimeline = ({ estate }: ApplicationsTimelineProps) => {
  const { t } = useTranslation()
  const { saleData, visit_spans } = estate

  const startValue = useMemo(() => {
    if (saleData.firstRound.startDate.length > 0) return 100
    if (saleData.firstRound.endDate.length > 0) return 0
    return 0
  }, [saleData.firstRound.startDate, saleData.firstRound.endDate])

  const openingSaleDate = useMemo(() => {
    return new Date(saleData.firstRound.startDate)
  }, [saleData.firstRound.startDate])

  const openingSaleValue = useMemo(() => {
    return isToday(openingSaleDate) || isBefore(openingSaleDate, new Date())
      ? 100
      : 0
  }, [openingSaleDate])

  const sortedVisitSpans: string[] = useMemo(() => {
    return visit_spans
      .flatMap((visit) => visit.span[0].startTime)
      .sort((a, b) => (new Date(a) > new Date(b) ? 1 : -1))
  }, [visit_spans])

  const openingVisitsDate = useMemo(() => {
    return sortedVisitSpans.length > 0 ? new Date(sortedVisitSpans[0]) : null
  }, [sortedVisitSpans])

  const closingVisitsDate = useMemo(() => {
    return sortedVisitSpans.length > 0
      ? new Date(sortedVisitSpans[sortedVisitSpans.length - 1])
      : null
  }, [sortedVisitSpans])

  const visitsValue = useMemo(() => {
    return !openingVisitsDate || !closingVisitsDate
      ? 0
      : isAfter(openingVisitsDate, new Date())
        ? 0
        : isBefore(openingVisitsDate, closingVisitsDate)
          ? 50
          : 100
  }, [openingVisitsDate, closingVisitsDate])

  const closingSaleDate = useMemo(() => {
    return new Date(saleData.firstRound.endDate)
  }, [saleData.firstRound.endDate])

  const closingSaleValue = useMemo(() => {
    return openingSaleValue === 100 && isToday(closingSaleDate) ? 100 : 0
  }, [openingSaleValue, closingSaleDate])

  const endValue = useMemo(() => {
    return openingSaleValue === 100 && closingSaleValue === 100 ? 100 : 0
  }, [openingSaleValue, closingSaleValue])

  return (
    <div className="tw-flex tw-gap-2 tw-px-8 tw-mb-8 tw-w-full">
      <Progress className="tw-h-1 tw-w-[75px]" value={startValue} />
      <div>
        <Progress className="tw-h-1 tw-w-44" value={openingSaleValue} />
        <p className="tw-text-sm tw-text-zinc-500">
          {t('ApplicationsTimeline.openingSale')}
        </p>
        <p className="tw-text-sm tw-text-zinc-500">
          {format(openingSaleDate, 'dd MMMM yyyy', { locale: fr })}
        </p>
      </div>
      <div className="tw-grid tw-w-[80%]">
        <Progress className="tw-h-1" value={visitsValue} />
        <div className="tw-flex tw-gap-1 tw-justify-between">
          <div>
            <p className="tw-text-sm tw-text-zinc-500">
              {t('ApplicationsTimeline.startVisits')}
            </p>
            {openingVisitsDate && (
              <p className="tw-text-sm tw-text-zinc-500">
                {format(openingVisitsDate, 'dd MMMM yyyy', { locale: fr })}
              </p>
            )}
          </div>
          <div>
            <p className="tw-text-sm tw-text-zinc-500">
              {t('ApplicationsTimeline.endVisits')}
            </p>
            {closingVisitsDate && (
              <p className="tw-text-sm tw-text-zinc-500">
                {format(closingVisitsDate, 'dd MMMM yyyy', { locale: fr })}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="tw-grid tw-w-full">
        <Progress className="tw-h-1 tw-w-full" value={closingSaleValue} />
        <div className="tw-justify-self-end">
          <p className="tw-text-sm tw-text-zinc-500">
            {t('ApplicationsTimeline.closingSale')}
          </p>
          <p className="tw-text-sm tw-text-zinc-500">
            {format(closingSaleDate, 'dd MMMM yyyy', { locale: fr })}
          </p>
        </div>
      </div>
      <div className="tw-grid tw-w-[170px]">
        <Progress className="tw-h-1 tw-w-[170px]" value={endValue} />
        <div className="tw-justify-self-end">
          <p className="tw-text-sm tw-text-zinc-500">
            {t('ApplicationsTimeline.endSale')}
          </p>
        </div>
      </div>
    </div>
  )
}
