import { Button } from '@/components/ui/button'
import { ArrowLeft, ArrowRight } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

type AdminHeaderProps = {
  propertyAddress: string
  onNext: (() => void) | null
  onPrevious: (() => void) | null
}

const AdminHeader = ({
  propertyAddress,
  onNext,
  onPrevious,
}: AdminHeaderProps) => {
  const { t } = useTranslation()
  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-gap-2">
      <div className="tw-flex tw-flex-col tw-justify-center tw-text-stone-400 tw-text-xs tw-font-bold">
        {t('ApplicationSheet.offerFor')}
        <p className="tw-text-sm tw-font-medium tw-text-zinc-900">
          {propertyAddress}
        </p>
      </div>
      <div className="tw-mr-6">
        <Button
          disabled={onPrevious === null}
          onClick={() => onPrevious?.()}
          variant="outline"
          size="sm"
          className="tw-rounded-r-none"
        >
          <ArrowLeft />
        </Button>
        <Button
          disabled={onNext === null}
          onClick={() => onNext?.()}
          variant="outline"
          size="sm"
          className="tw-rounded-l-none"
        >
          <ArrowRight />
        </Button>
      </div>
    </div>
  )
}

export default AdminHeader
