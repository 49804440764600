import * as React from 'react'

import { cn } from '@/lib/utils'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './select'
import { FormControl } from './form'
import generateTimeSeriesRecursive from '../../../../../shared/helpers/generateTimeSeriesRecursive'
import { format } from 'date-fns'

export interface TimePickerProps {
  placeholder?: string
  className?: string
  disabled?: boolean
  value?: Date
  onValueChange?: (value: Date) => void
  date?: Date
  startTime?: string
  endTime?: string
  step?: number
}

const TimePicker = React.forwardRef<HTMLSelectElement, TimePickerProps>(
  (
    {
      placeholder,
      className,
      disabled,
      value,
      onValueChange,
      date = new Date(),
      startTime = '08:00',
      endTime = '12:00',
      step = 30,
    },
    ref
  ) => {
    const times = generateTimeSeriesRecursive(date, startTime, endTime, step)

    return (
      <Select
        value={value?.toISOString()}
        onValueChange={(value) => onValueChange?.(new Date(value))}
      >
        <FormControl>
          <SelectTrigger
            className={cn('tw-w-full', className)}
            disabled={disabled}
            {...ref}
          >
            <SelectValue placeholder={placeholder} />
          </SelectTrigger>
        </FormControl>
        <SelectContent className="tw-flex tw-justify-items-center">
          <SelectGroup>
            {times.map((time) => (
              <SelectItem key={time.toISOString()} value={time.toISOString()}>
                {format(new Date(time), 'HH:mm')}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    )
  }
)

TimePicker.displayName = 'TimePicker'

export { TimePicker }
