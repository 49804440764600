import React, { forwardRef, useMemo, useState } from 'react'
import { StarsRating, StarsRatingProps } from '@/common/atoms/StarsRating'
import { useTranslation } from 'react-i18next'

export type ConditionRatingProps = StarsRatingProps
const ConditionRating = forwardRef<HTMLDivElement, ConditionRatingProps>(
  ({ max, value, onChange, ...props }, ref) => {
    const { t } = useTranslation()
    const [currentValue, setCurrentValue] = useState(value)
    const handleChange = (value: number) => {
      setCurrentValue(value)
      onChange(value)
    }
    const label = useMemo(() => {
      if (currentValue === 0) return t('ConditionRating.unknown')
      if (currentValue === 1) return t('ConditionRating.poor')
      if (currentValue === 2) return t('ConditionRating.good')
      if (currentValue === 3) return t('ConditionRating.new')
    }, [currentValue, t])
    return (
      <StarsRating
        ref={ref}
        {...props}
        max={3}
        onChange={handleChange}
        value={currentValue}
        label={label}
      />
    )
  }
)

ConditionRating.displayName = 'ConditionRating'
export { ConditionRating }
