import { EstateFragment } from '@gql'
import React, { useMemo } from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'
import { EstateVisitSpanRows } from './EstateVisitSpanRows'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'

type EstateVisitSpanModalProps = {
  open: boolean
  setOpenModal: (open: boolean) => void
  estate: EstateFragment
  disabled?: boolean
}

export const EstateVisitSpanModal = ({
  open,
  setOpenModal,
  estate,
  disabled,
}: EstateVisitSpanModalProps) => {
  const { t } = useTranslation()

  const sortedVisitSpans = useMemo(() => {
    return (
      estate.visit_spans
        ?.flatMap((visit) => visit)
        .sort((a, b) =>
          new Date(a.span[0].startTime) > new Date(b.span[0].startTime) ? 1 : -1
        ) ?? []
    )
  }, [estate.visit_spans])

  return (
    <Dialog open={open} onOpenChange={setOpenModal}>
      <DialogTrigger asChild>
        <Button variant="secondary" disabled={disabled}>
          {t('EstateVisitSpanModal.title')}
        </Button>
      </DialogTrigger>
      <DialogContent className="tw-min-w-[500px]">
        <DialogHeader className="tw-mb-5">
          <DialogTitle>{t('EstateVisitSpanModal.title')}</DialogTitle>
        </DialogHeader>
        {!sortedVisitSpans.length && (
          <p>{t('EstateVisitSpanModal.noVisitSlots')}</p>
        )}
        {!!sortedVisitSpans.length && (
          <Tabs defaultValue={sortedVisitSpans[0]?.id}>
            <TabsList className="tw-h-auto tw-max-h-[100px] tw-flex-wrap tw-overflow-auto tw-gap-2 tw-bg-transparent tw-justify-stretch">
              {sortedVisitSpans.map((visitSpan) => (
                <TabsTrigger
                  value={visitSpan.id}
                  key={visitSpan.id}
                  className="tw-border !tw-rounded-full tw-border-zinc data-[state=active]:tw-border-black"
                >
                  {format(
                    new Date(visitSpan.span[0].startTime),
                    'E dd/MM/yyyy',
                    {
                      locale: fr,
                    }
                  )}
                </TabsTrigger>
              ))}
            </TabsList>
            {sortedVisitSpans.map((visitSpan) => (
              <TabsContent value={visitSpan.id} key={visitSpan.id}>
                <EstateVisitSpanRows visitSpan={visitSpan} />
              </TabsContent>
            ))}
          </Tabs>
        )}
      </DialogContent>
    </Dialog>
  )
}
