import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Seller_Type_Enum } from '@gql'
import React, { forwardRef } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type SellerTypeSelectProps = {
  field: ControllerRenderProps<any>
}

export const SellerTypeSelect = forwardRef<
  HTMLInputElement,
  SellerTypeSelectProps
>(({ field }, ref) => {
  const { t } = useTranslation()

  return (
    <FormItem>
      <FormLabel>{t('SellerTypeSelect.label')}</FormLabel>
      <Select onValueChange={field.onChange} value={field.value}>
        <FormControl>
          <SelectTrigger className="tw-w-full">
            <SelectValue
              ref={ref}
              placeholder={t('SellerTypeSelect.placeholder')}
            />
          </SelectTrigger>
        </FormControl>
        <SelectContent>
          {Object.values(Seller_Type_Enum).map((type) => (
            <SelectItem value={type} key={type}>
              {t(`SellerTypeSelect.${type}`)}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <FormMessage />
    </FormItem>
  )
})

SellerTypeSelect.displayName = 'SellerTypeSelect'
