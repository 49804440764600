import { SellerFragment } from '@gql'
import { useStoreState } from '@store/hooks'
import { useMemo } from 'react'

export default function useSeller(id?: string): SellerFragment | undefined {
  const getById = useStoreState((state) => state.sellers.getById)

  const seller = useMemo(() => (id ? getById(id) : undefined), [getById, id])
  return seller
}
