import './index.css'
import React from 'react'
import { NhostProvider } from '@nhost/react'
import { NhostApolloProvider } from '@nhost/react-apollo'
import { StoreProvider } from 'easy-peasy'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter as Router } from 'react-router-dom'
import { nhost } from 'src/nhost'
import i18n from './i18n'
import AppRoute from './routes/AppRoute'
import { store } from './store'
import { Toaster } from '@/components/ui/toaster'
import 'non.geist'

export default function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <NhostProvider nhost={nhost}>
        <NhostApolloProvider
          nhost={nhost}
          headers={{ 'X-Hasura-Role': 'user' }}
        >
          <StoreProvider store={store}>
            <Router>
              <AppRoute />
            </Router>
            <Toaster />
          </StoreProvider>
        </NhostApolloProvider>
      </NhostProvider>
    </I18nextProvider>
  )
}
