import {
  Estate_Media_Type_Group_Enum,
  EstateMediaTypeFragment,
  useGetEstateMediaTypesQuery,
} from '@gql'
import { useMemo } from 'react'
export type UseEstateMediaTypesOptions = {
  group?: Estate_Media_Type_Group_Enum
}
export const useEstateMediaTypes = ({ group }: UseEstateMediaTypesOptions) => {
  const { data, loading, error } = useGetEstateMediaTypesQuery()

  const allEstateMediaTypes = useMemo<EstateMediaTypeFragment[]>(() => {
    if (data?.estate_media_type) {
      return data.estate_media_type
    }
    return []
  }, [data])

  const estateMediaTypes = useMemo<EstateMediaTypeFragment[]>(() => {
    if (group) {
      return allEstateMediaTypes.filter((type) => type.group === group)
    }
    return allEstateMediaTypes
  }, [allEstateMediaTypes, group])

  return {
    estateMediaTypes,
    loading,
    error,
  }
}
