import PasswordInput from '@/common/atoms/PasswordInput'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { useToast } from '@/components/ui/use-toast'
import { passwordSchema } from '@almaris/shared/schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { nhost } from 'src/nhost'
import * as yup from 'yup'

export interface ResetPasswordFormValues {
  ['new-password']: string
  ['confirm-password']: string
}

const resolver = (t: TFunction) =>
  yupResolver(
    yup.object().shape({
      ['new-password']: passwordSchema(
        t('common.schema.password.rule')
      ).required(),
      ['confirm-password']: passwordSchema(t('common.schema.password.rule'))
        .oneOf(
          [yup.ref('new-password'), undefined],
          t('common.schema.password.match')
        )
        .required(),
    })
  )

const ResetPasswordForm = () => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const navigate = useNavigate()

  const form = useForm<ResetPasswordFormValues>({
    resolver: resolver(t),
    defaultValues: {
      ['new-password']: '',
      ['confirm-password']: '',
    },
  })

  const onSubmit = async ({
    'new-password': password,
    'confirm-password': confirm,
  }: ResetPasswordFormValues) => {
    // Update password
    if (password && confirm) {
      const result = await nhost.auth.changePassword({ newPassword: password })

      if (result.error) {
        toast({
          variant: 'destructive',
          title: 'Erreur',
          description: result.error.message,
        })
        return
      } else {
        toast({
          variant: 'success',
          description: t('ResetPasswordForm.toast.success'),
        })
        navigate('/')
      }
      // Refresh user data
      await nhost.auth.refreshSession()
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="tw-space-y-5">
        <FormField
          control={form.control}
          name="new-password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('ResetPasswordForm.input.password')}</FormLabel>
              <FormControl>
                <PasswordInput
                  id="new-password"
                  required
                  autoComplete="new-password"
                  {...field}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="confirm-password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('ResetPasswordForm.input.confirm')}</FormLabel>
              <FormControl>
                <PasswordInput
                  id="confirm-password"
                  required
                  autoComplete="confirm-password"
                  {...field}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <Button variant="green" type="submit">
          {t('ResetPasswordForm.input.submit')}
        </Button>
      </form>
    </Form>
  )
}

export { ResetPasswordForm }
