'use client'

import * as React from 'react'
import { buttonVariants } from '@/components/ui/button'
import { ScrollArea } from '@/components/ui/scroll-area'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { DayPicker, DropdownProps } from 'react-day-picker'

export type DatePickerProps = React.ComponentProps<typeof DayPicker>

export const DatePicker = ({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: DatePickerProps) => {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('tw-p-3', className)}
      classNames={{
        months:
          'tw-flex tw-flex-col sm:tw-flex-row tw-space-y-4 sm:tw-space-x-4 sm:tw-space-y-0',
        month: 'tw-space-y-4',
        caption:
          'tw-flex tw-justify-center tw-pt-1 tw-relative tw-items-center',
        caption_label: 'tw-text-sm tw-font-medium tw-hidden',
        caption_dropdowns: 'tw-flex tw-justify-center tw-gap-1',
        nav: 'tw-space-x-1 tw-flex tw-items-center',
        nav_button: cn(
          buttonVariants({ variant: 'outline', size: 'icon' }),
          'tw-h-7 tw-w-7 tw-bg-transparent  tw-opacity-50 hover:tw-opacity-100'
        ),
        nav_button_previous: 'tw-absolute tw-left-1',
        nav_button_next: 'tw-absolute tw-right-1',
        table: 'tw-w-full tw-border-collapse tw-space-y-1',
        head_row: 'tw-flex',
        head_cell:
          'tw-text-muted-foreground tw-rounded-md tw-w-9 tw-font-normal tw-text-[0.8rem]',
        row: 'tw-flex tw-w-full tw-mt-2',
        cell: 'tw-text-center tw-text-sm tw-p-0 tw-relative [&:has([aria-selected])]:tw-bg-accent first:[&:has([aria-selected])]:tw-rounded-l-md last:[&:has([aria-selected])]:tw-rounded-r-md focus-within:tw-relative focus-within:tw-z-20',
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          'tw-h-9 tw-w-9 tw-p-0 tw-font-normal aria-selected:tw-opacity-100'
        ),
        day_selected:
          'tw-bg-primary tw-text-primary-foreground hover:tw-bg-primary hover:tw-text-primary-foreground focus:tw-bg-primary focus:tw-text-primary-foreground',
        day_today: 'tw-bg-accent tw-text-accent-foreground',
        day_outside: 'tw-text-muted-foreground tw-opacity-50',
        day_disabled: 'tw-text-muted-foreground tw-opacity-50',
        day_range_middle:
          'aria-selected:tw-bg-accent aria-selected:tw-text-accent-foreground',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        Dropdown: ({ value, onChange, children, ...props }: DropdownProps) => {
          const options = React.Children.toArray(
            children
          ) as React.ReactElement<React.HTMLProps<HTMLOptionElement>>[]
          const selected = options.find((child) => child.props.value === value)
          const handleChange = (value: string) => {
            const changeEvent = {
              target: { value },
            } as React.ChangeEvent<HTMLSelectElement>
            onChange?.(changeEvent)
          }
          return (
            <Select
              value={value?.toString()}
              onValueChange={(value) => {
                handleChange(value)
              }}
            >
              <SelectTrigger className="tw-h-[28px] tw-pr-1.5 focus:tw-ring-0">
                <SelectValue>{selected?.props?.children}</SelectValue>
              </SelectTrigger>
              <SelectContent position="popper">
                <ScrollArea className="tw-h-80">
                  {options.map((option, id: number) => (
                    <SelectItem
                      key={`${option.props.value}-${id}`}
                      value={option.props.value?.toString() ?? ''}
                      className="tw-capitalize"
                    >
                      {option.props.children}
                    </SelectItem>
                  ))}
                </ScrollArea>
              </SelectContent>
            </Select>
          )
        },
        IconLeft: ({ ...props }) => <ChevronLeft className="tw-h-4 tw-w-4" />,
        IconRight: ({ ...props }) => <ChevronRight className="tw-h-4 tw-w-4" />,
      }}
      {...props}
    />
  )
}
