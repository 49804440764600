import { Application_Status_Enum, EstateFragment } from '@gql'
import { CalendarCheckIcon, CoinsIcon, FileTextIcon } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

type ApplicationsStatsBlockProps = {
  estate: EstateFragment
}
export const ApplicationsStatsBlock = ({
  estate,
}: ApplicationsStatsBlockProps) => {
  const { t } = useTranslation()
  const { applications } = estate

  const plannedVisitsLength = applications?.filter(
    (application) => application.status === Application_Status_Enum.VisitPlanned
  ).length

  const doneVisitsLength = applications?.filter(
    (application) =>
      application.status === Application_Status_Enum.VisitDone &&
      application.roundNumber === 0
  ).length

  const inProgressApplicationsLength = applications?.filter(
    (application) =>
      application.status === Application_Status_Enum.VisitDone &&
      application.roundNumber > 0
  ).length

  const receivedApplicationsLength = applications?.filter(
    (application) =>
      application.status === Application_Status_Enum.OfferSubmitted
  ).length

  return (
    <div className="tw-p-8 tw-flex tw-gap-6 tw-items-center tw-justify-between">
      <div className="tw-w-full tw-flex tw-gap-2 tw-bg-green-100 tw-rounded-lg tw-p-4 tw-items-center">
        <CoinsIcon />
        <div>
          <p className="tw-text-2xl tw-font-medium">
            {estate.saleData.firstRound.firstPrice} €
          </p>
          <p>{t('ApplicationsStatsBlock.firstPrice')}</p>
        </div>
      </div>

      <div className="tw-w-full tw-flex tw-gap-2 tw-bg-green-100 tw-rounded-lg tw-p-4 tw-items-center">
        <CalendarCheckIcon />
        <div>
          <p>
            <span className="tw-text-2xl tw-font-medium">
              {doneVisitsLength}
            </span>
            /{plannedVisitsLength + doneVisitsLength}
          </p>
          <p>{t('ApplicationsStatsBlock.done', { count: doneVisitsLength })}</p>
        </div>
      </div>

      <div className="tw-w-full tw-flex tw-gap-2 tw-bg-green-100 tw-rounded-lg tw-p-4 tw-items-center">
        <FileTextIcon />
        <div>
          <p>
            <span className="tw-text-2xl tw-font-medium">
              {receivedApplicationsLength}
            </span>
            /{inProgressApplicationsLength + receivedApplicationsLength}
          </p>
          <p>
            {t('ApplicationsStatsBlock.received', {
              count: receivedApplicationsLength,
            })}
          </p>
        </div>
      </div>
    </div>
  )
}
