import React, { createContext, useContext, useState } from 'react'

interface EstateArchiveDialogContextType {
  estateId: string | null
  setEstateId: (estateId: string | null) => void
}

const EstateArchiveDialogContext = createContext<
  EstateArchiveDialogContextType | undefined
>(undefined)

export const useEstateArchiveDialog = () => {
  const context = useContext(EstateArchiveDialogContext)
  if (!context) {
    throw new Error(
      'useEstateArchiveDialog must be used within an EstateArchiveDialogProvider'
    )
  }
  return context
}

export const EstateArchiveDialogProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [estateId, setEstateId] = useState<string | null>(null)

  return (
    <EstateArchiveDialogContext.Provider value={{ estateId, setEstateId }}>
      {children}
    </EstateArchiveDialogContext.Provider>
  )
}
