import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { InfoIcon, SettingsIcon } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { EstateSettingLinkForm } from './EstateSettingLinkForm'
import { EstateFragment } from '@gql'
import { EstateSettingOpeningForm } from './EstateSettingOpeningForm'
import { EstateSettingVisitSpanForm } from './EstateSettingVisitSpanForm'
import { EstateSettingOffersForm } from './EstateSettingOffersForm'

type EstateSettingModalProps = {
  open: boolean
  setOpenModal: (open: boolean) => void
  estate: EstateFragment
  disabled?: boolean
  readOnly?: boolean
}

export default function EstateSettingModal({
  open,
  setOpenModal,
  estate,
  disabled,
  readOnly,
}: EstateSettingModalProps) {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onOpenChange={setOpenModal}>
      <DialogTrigger asChild>
        <Button disabled={disabled}>
          <SettingsIcon className="tw-h-4 tw-w-4 tw-mr-2" />
          {t('EstateSettingModal.button')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader className="tw-mb-5">
          <DialogTitle>{t('EstateSettingModal.heading')}</DialogTitle>
        </DialogHeader>
        <Tabs defaultValue="opening">
          <TabsList className="tw-w-full">
            <TabsTrigger className="tw-w-full" value="opening">
              {t('EstateSettingModal.tabs.opening')}
            </TabsTrigger>
            <TabsTrigger className="tw-w-full" value="visits">
              {t('EstateSettingModal.tabs.visits')}
            </TabsTrigger>
            <TabsTrigger className="tw-w-full" value="offers">
              {t('EstateSettingModal.tabs.offers')}
            </TabsTrigger>
            <TabsTrigger className="tw-w-full" value="publication">
              {t('EstateSettingModal.tabs.publication')}
            </TabsTrigger>
          </TabsList>
          <TabsContent
            value="opening"
            className="tw-h-[550px] tw-overflow-auto"
          >
            {readOnly && <EstateSettingModalReadOnlyCard estate={estate} />}
            <EstateSettingOpeningForm estate={estate} readOnly={readOnly} />
          </TabsContent>
          <TabsContent value="visits" className="tw-h-[550px] tw-overflow-auto">
            {readOnly && <EstateSettingModalReadOnlyCard estate={estate} />}
            <EstateSettingVisitSpanForm estate={estate} readOnly={readOnly} />
          </TabsContent>
          <TabsContent value="offers" className="tw-h-[550px] tw-overflow-auto">
            {readOnly && <EstateSettingModalReadOnlyCard estate={estate} />}
            <EstateSettingOffersForm estate={estate} readOnly={readOnly} />
          </TabsContent>
          <TabsContent
            value="publication"
            className="tw-h-[550px] tw-overflow-auto"
          >
            {readOnly && <EstateSettingModalReadOnlyCard estate={estate} />}
            <EstateSettingLinkForm estate={estate} readOnly={readOnly} />
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  )
}

const EstateSettingModalReadOnlyCard = ({
  estate,
}: {
  estate: EstateFragment
}) => {
  const { t } = useTranslation()
  const agency = estate.seller.agency

  return (
    <div className="tw-py-3 tw-p-4 tw-bg-green-200 tw-rounded-lg">
      <p className="tw-text-sm tw-font-normal tw-text-green-700 tw-flex tw-items-center">
        <div className="tw-p-2">
          <InfoIcon className="tw-h-[18px] tw-w-[18px]" />
        </div>
        {t('EstateSettingModal.readOnly.title', {
          agency: agency.name,
        })}
      </p>
    </div>
  )
}
