import { useTranslation } from 'react-i18next'
import {
  EnumSelectFormItem,
  EnumSelectFormItemWrapperProps,
} from './EnumSelectFormItem'
import { ForwardedRef, forwardRef } from 'react'
import React from 'react'
import { countries } from 'src/countries'

const Country_Enum = countries.getAlpha3Codes()

export type CountrySelectFormItemProps = EnumSelectFormItemWrapperProps<
  typeof Country_Enum
>

export const CountrySelectFormItemInner = (
  {
    label,
    placeholder,
    getEnumValueLabel,
    ...props
  }: CountrySelectFormItemProps,
  ref: ForwardedRef<HTMLSpanElement>
) => {
  const { t } = useTranslation()

  return (
    <EnumSelectFormItem
      {...props}
      enumList={Country_Enum}
      label={label ?? t('CountrySelectFormItem.label').toString()}
      placeholder={
        placeholder ?? t('CountrySelectFormItem.placeholder').toString()
      }
      getEnumValueLabel={
        getEnumValueLabel ??
        ((enumValue) => {
          // @ts-ignore - ignore translation typing for countries
          return t(`countries.${enumValue}`).toString()
        })
      }
      ref={ref}
    />
  )
}

export const CountrySelectFormItem = forwardRef(CountrySelectFormItemInner)
