import { Application_Status_Enum, ApplicationFragment } from '@gql'
import { GroupingState } from '@tanstack/react-table'
import React from 'react'
import { Table } from '@tanstack/react-table'
import { Input } from '@/components/ui/input'
import { useTranslation } from 'react-i18next'
import { TableGroupBySelect } from '@/common/molecules/TableGroupBySelect'
import {
  TableFacetedFilter,
  TableFilter,
} from '@/common/molecules/TableFacetedFilters'

type ApplicationsTableToolbarProps = {
  table: Table<ApplicationFragment>
  grouping: GroupingState
  setGrouping: (value: GroupingState) => void
}

export const ApplicationsTableToolbar = ({
  grouping,
  setGrouping,
  table,
}: ApplicationsTableToolbarProps) => {
  const { t } = useTranslation()

  const statusOptions: TableFilter[] = Object.values(
    Application_Status_Enum
  ).map((status) => ({
    value: status,
    label: t(`common.applicationStatus.${status}`),
  }))

  const groupByOptions: TableFilter[] = [
    {
      value: 'status',
      label: t('ApplicationsTableToolbar.facetedFilter.status'),
    },
    {
      value: 'createdAt',
      label: t('ApplicationsTableToolbar.facetedFilter.date'),
    },
    {
      value: 'offerAmount',
      label: t('ApplicationsTableToolbar.facetedFilter.offerAmount'),
    },
    {
      value: 'score',
      label: t('ApplicationsTableToolbar.facetedFilter.score'),
    },
  ]

  const setGroupByValue = (value: string) => {
    setGrouping([value])
  }

  return (
    <div className="tw-flex tw-justify-between tw-items-center tw-py-4 tw-gap-2">
      <div className="tw-flex tw-items-center tw-space-x-2 tw-w-full">
        <Input
          placeholder={t('ApplicationsTableToolbar.searchPlaceholder')}
          value={(table.getColumn('name')?.getFilterValue() as string) ?? ''}
          onChange={(event) =>
            table.getColumn('name')?.setFilterValue(event.target.value)
          }
          className="tw-max-w-sm"
        />
        {table.getColumn('status') && (
          <TableFacetedFilter
            column={table.getColumn('status')}
            title="Status"
            options={statusOptions}
          />
        )}
      </div>
      <TableGroupBySelect
        groupingValue={grouping?.[0]}
        setGroupingValue={setGroupByValue}
        options={groupByOptions}
      />
    </div>
  )
}
