import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Seller_Marital_Status_Enum } from '@gql'
import React, { forwardRef } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type SellerMaritalStatusProps = {
  field: ControllerRenderProps<any>
  className?: string
}
export const SellerMaritalStatusSelect = forwardRef<
  HTMLInputElement,
  SellerMaritalStatusProps
>(({ field, className }, ref) => {
  const { t } = useTranslation()
  return (
    <FormItem className={className}>
      <FormLabel>{t('SellerMaritalStatusSelect.label')}</FormLabel>
      <Select onValueChange={field.onChange} value={field.value}>
        <FormControl>
          <SelectTrigger className="tw-w-full">
            <SelectValue
              ref={ref}
              placeholder={t('SellerMaritalStatusSelect.placeholder')}
            />
          </SelectTrigger>
        </FormControl>
        <SelectContent>
          {Object.values(Seller_Marital_Status_Enum).map((status) => (
            <SelectItem value={status} key={status}>
              {t(`SellerMaritalStatusSelect.${status}`)}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <FormMessage />
    </FormItem>
  )
})

SellerMaritalStatusSelect.displayName = 'SellerMaritalStatusSelect'
