import React from 'react'
import { useTranslation } from 'react-i18next'
import { EstateFragment } from '@gql'
import {
  GenericForm,
  GenericFormConfig,
  GenericFormFieldConfig,
} from '../../../form/GenericForm'
import { ConditionRating } from './ConditionRating'
import { ServiceRating } from './ServiceRating'

// const ratingSchema = (max: number = 5) => yup.number().min(0).max(max)
const serviceFieldConfig: Partial<Omit<GenericFormFieldConfig, 'name'>> = {
  type: 'number',
  FieldComponent: ServiceRating,
}
const conditionFieldConfig: Partial<Omit<GenericFormFieldConfig, 'name'>> = {
  type: 'number',
  FieldComponent: ConditionRating,
}
export type EstateConditionAndServicesFormProps = {
  estate: EstateFragment
  onSubmit: (values: Partial<EstateFragment>) => void
}
export const EstateConditionAndServicesForm = ({
  estate,
  onSubmit,
}: EstateConditionAndServicesFormProps) => {
  const { t } = useTranslation()

  const isHouseTypeCondition = (formValues: any, { estate }: any) =>
    estate.type === 'House'

  const config: GenericFormConfig = [
    {
      name: 'overallCondition',
      ...conditionFieldConfig,
      containerProps: { className: 'tw-col-span-6' },
    },
    {
      name: 'bathroomShowerServices',
      ...serviceFieldConfig,
    },
    {
      name: 'bathroomShowerCondition',
      ...conditionFieldConfig,
    },
    {
      name: 'kitchenServices',
      ...serviceFieldConfig,
    },
    {
      name: 'kitchenCondition',
      ...conditionFieldConfig,
    },
    {
      name: 'flooringServices',
      ...serviceFieldConfig,
    },
    {
      name: 'flooringCondition',
      ...conditionFieldConfig,
    },
    {
      name: 'windowServices',
      ...serviceFieldConfig,
    },
    {
      name: 'windowCondition',
      ...conditionFieldConfig,
    },
    {
      name: 'masonryServices',
      condition: isHouseTypeCondition,
      ...serviceFieldConfig,
    },
    {
      name: 'masonryCondition',
      condition: isHouseTypeCondition,
      ...conditionFieldConfig,
    },
    {
      name: 'roofingServices',
      condition: isHouseTypeCondition,
      ...serviceFieldConfig,
    },
    {
      name: 'roofingCondition',
      condition: isHouseTypeCondition,
      ...conditionFieldConfig,
    },
  ]

  const handleSubmit = (
    servicesAndConditions: Partial<EstateFragment['servicesAndConditions']>
  ) => {
    onSubmit({ servicesAndConditions })
  }

  return (
    <GenericForm
      t={t}
      t_key="EstateConditionAndServicesForm"
      config={config}
      values={estate.servicesAndConditions}
      onSubmit={handleSubmit}
      context={{ estate }}
    />
  )
}
