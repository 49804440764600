import React, { useMemo } from 'react'
import { Button } from '@/components/ui/button'
import { ColumnDef } from '@tanstack/react-table'
import { ChevronsUpDownIcon } from 'lucide-react'
import { TFunction } from 'react-i18next'
import { Application_Status_Enum, ApplicationFragment } from '@gql'
import { Badge } from '@/components/ui/badge'
import { closestTo, format, isValid } from 'date-fns'
import { ApplicationsStarScoreRow } from '../ApplicationsStarScoreRow'
import { fr } from 'date-fns/locale'

export const columns = (
  t: TFunction,
  openApplication: (applicationIndex: number) => void
): ColumnDef<ApplicationFragment, any>[] => [
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('ApplicationsTableColumns.name')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const application = row.original
      return (
        <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
          <div
            className="tw-flex tw-flex-col tw-items-start tw-cursor-pointer"
            onClick={() => openApplication(row.index)}
          >
            <p className="tw-text-sm tw-font-medium tw-text-zinc-900">
              {application.buyer_member.name}
            </p>
            <p className="tw-text-sm tw-font-medium tw-text-zinc-500">
              {application.buyer_member?.email ??
                t('ApplicationsTableColumns.noData')}
            </p>
          </div>
        </div>
      )
    },
  },

  {
    accessorKey: 'status',
    filterFn: (row, _columnId, filterValue: string[]) => {
      const status = row.original.status
      return filterValue.includes(status)
    },
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('ApplicationsTableColumns.status')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const application = row.original

      const isDefault =
        application.status === Application_Status_Enum.OfferSubmitted
      const isSuccess =
        application.status === Application_Status_Enum.OfferAccepted

      if (application.archived) {
        return (
          <Badge variant="destructive" className="tw-ml-2">
            {t('ApplicationsTableColumns.archived')}
          </Badge>
        )
      }
      if (application.roundNumber > 1) {
        return (
          <Badge variant="secondary" className="tw-ml-2">
            {t('ApplicationsTableColumns.newRound')}
          </Badge>
        )
      }
      return (
        <Badge
          variant={isDefault ? 'default' : isSuccess ? 'success' : 'secondary'}
          className="tw-ml-2"
        >
          {/* TODO make sure it works */}
          {t(`common.applicationStatus.${application.status}`)}
        </Badge>
      )
    },
  },
  {
    accessorKey: 'visitDate',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('ApplicationsTableColumns.visitDate')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const application = row.original

      const visitDates = application.buyer_member.visit_slots.map(
        (slot) => new Date(slot.createdAt)
      )

      const closestDate = closestTo(new Date(), visitDates)

      const latestVisitSlot = application.buyer_member.visit_slots.find(
        (visitSlot) =>
          new Date(visitSlot.createdAt).getTime() === closestDate?.getTime()
      )?.startTime

      if (latestVisitSlot && !isValid(new Date(latestVisitSlot))) return null

      const day =
        latestVisitSlot &&
        format(new Date(latestVisitSlot), 'dd MMMM yyyy', {
          locale: fr,
        })
      const hour =
        latestVisitSlot &&
        format(new Date(latestVisitSlot), 'HH:mm', { locale: fr })
      return (
        <div>
          <p>{day}</p>
          <p className="tw-text-zinc-500">{hour}</p>
        </div>
      )
    },
  },
  {
    accessorKey: 'offerDate',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('ApplicationsTableColumns.offerDate')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      {
        /* TODO add a submittedDate in DB for Application and use it here */
      }
      const application = row.original
      const offerDate =
        application.updatedAt &&
        (application.status === Application_Status_Enum.OfferSubmitted ||
          application.status === Application_Status_Enum.OfferAccepted)
          ? format(new Date(application.updatedAt), 'dd MMMM yyyy HH:mm', {
              locale: fr,
            })
          : t('ApplicationsTableColumns.noData')

      return <p className="tw-text-zinc-500">{offerDate}</p>
    },
  },
  {
    accessorKey: 'offerAmount',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('ApplicationsTableColumns.offerAmount.label')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const application = row.original
      return application.offerAmount ? (
        <p>
          {t('ApplicationsTableColumns.offerAmount.amount', {
            count: application.offerAmount,
          })}
        </p>
      ) : (
        <p className="tw-text-zinc-500">
          {t('ApplicationsTableColumns.noData')}
        </p>
      )
    },
  },
  {
    accessorKey: 'contribution',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('ApplicationsTableColumns.contribution.label')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const application = row.original

      const contributionPercent = useMemo(() => {
        return (
          ((application?.contribution ?? 0) / (application?.offerAmount ?? 0)) *
          100
        ).toFixed(2)
      }, [application])

      return application.contribution ? (
        <p>
          {t('ApplicationsTableColumns.contribution.amount', {
            percent: contributionPercent,
          })}
        </p>
      ) : (
        <p className="tw-text-zinc-500">
          {t('ApplicationsTableColumns.noData')}
        </p>
      )
    },
    enableGrouping: false,
  },

  {
    accessorKey: 'score',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('ApplicationsTableColumns.score')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const application = row.original
      return <ApplicationsStarScoreRow application={application} />
    },
  },

  {
    accessorKey: 'tags',
    header: () => {
      return <p>{t('ApplicationsTableColumns.tags')}</p>
    },
    cell: ({ row }) => {
      const application = row.original
      const tags = application.tags
      if (!tags) return null
      return (
        <div className="tw-flex tw-flex-row tw-gap-2 tw-flex-wrap tw-max-w-[200px]">
          {tags.map((tag: string) => (
            <Badge key={tag} variant="secondary">
              {tag}
            </Badge>
          ))}
        </div>
      )
    },
  },
]
