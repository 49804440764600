import React from 'react'
import { ResetPasswordForm } from '../components/ResetPasswordForm'
import { useTranslation } from 'react-i18next'

const ResetPasswordPage = () => {
  const { t } = useTranslation()

  return (
    <div className="tw-grid tw-justify-items-center tw-my-6">
      <div className="tw-w-[400px]">
        <h2 className="tw-text-2xl tw-font-semibold tw-mb-5">
          {t('ResetPasswordPage.heading')}
        </h2>

        <ResetPasswordForm />
      </div>
    </div>
  )
}

export { ResetPasswordPage }
