import Loading from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { EstateFragment, useApplicationsSubscription } from '@gql'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApplicationsCoreTable } from './ApplicationsCoreTable'
import { ApplicationsTableToolbar } from './ApplicationsTableToolbar'
import { columns } from './ApplicationsTableColumns'
import ApplicationSheet from '@/application/components/sheet/ApplicationSheet'
import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'

type ApplicationsTableProps = {
  estate: EstateFragment
}

export const ApplicationsTable = ({ estate }: ApplicationsTableProps) => {
  const { t } = useTranslation()
  const [openedApplicationIndex, setOpenedApplicationIndex] =
    useState<number>(-1)

  const openApplication = useCallback((applicationIndex: number) => {
    setOpenedApplicationIndex(applicationIndex)
  }, [])
  const cols = useMemo(() => columns(t, openApplication), [t, openApplication])

  const { data, error, loading } = useApplicationsSubscription({
    skip: !estate.id,
    variables: { estateId: estate.id },
  })
  const isAdmin = useIsAgencyMember()

  const openedApplication = useMemo(() => {
    if (
      openedApplicationIndex === -1 ||
      !data?.application[openedApplicationIndex]
    )
      return null
    return data?.application[openedApplicationIndex]
  }, [openedApplicationIndex, data])

  return (
    <>
      <Loading center active={loading} />
      {error && <TextError error={error} />}
      {data?.application && (
        <ApplicationsCoreTable
          columns={cols}
          data={data?.application}
          toolbar={(table, grouping, setGrouping) => (
            <ApplicationsTableToolbar
              table={table}
              grouping={grouping}
              setGrouping={setGrouping}
            />
          )}
        />
      )}
      <ApplicationSheet
        open={openedApplicationIndex !== -1}
        onOpenChange={(open) =>
          open
            ? setOpenedApplicationIndex(openedApplicationIndex)
            : setOpenedApplicationIndex(-1)
        }
        onNext={
          openedApplicationIndex === (data?.application.length ?? 0) - 1
            ? null
            : () => setOpenedApplicationIndex(openedApplicationIndex + 1)
        }
        onPrevious={
          openedApplicationIndex === 0
            ? null
            : () => setOpenedApplicationIndex(openedApplicationIndex - 1)
        }
        estate={estate}
        isAdmin={isAdmin ?? false}
        application={openedApplication}
      />
    </>
  )
}
