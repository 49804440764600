import EmptySaleSvg from 'src/images/empty-sale.svg'
import React, { useMemo, useState } from 'react'
import EstateSettingModal from '../components/EstateSetting/EstateSettingModal'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useEstateSubscription } from '@gql'
import Loading from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { ApplicationsTable } from '@/application/components/table/ApplicationsTable'
import { Separator } from '@/components/ui/separator'
import { ApplicationsStatsBlock } from '@/application/components/ApplicationsStatsBlock'
import { ApplicationsTimeline } from '@/application/components/ApplicationsTimeline'
import { EstateVisitSpanModal } from '../components/EstateVisitSpan/EstateVisitSpanModal'
import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'
import { isValid } from 'date-fns'

export const EstateSalePage = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenSpan, setIsOpenSpan] = useState(false)
  const isAgencyMember = useIsAgencyMember()

  const { estateId } = useParams<{ estateId: string }>()
  const { data, loading, error } = useEstateSubscription({
    variables: {
      id: estateId!,
    },
    skip: !estateId,
  })

  const isSaleOpen = useMemo(() => {
    if (!data?.estate_by_pk?.saleData?.firstRound?.endDate) return false
    return (
      data?.estate_by_pk?.saleData?.firstRound?.endDate.length &&
      data?.estate_by_pk?.saleData?.firstRound?.endDate.length > 0 &&
      isValid(new Date(data?.estate_by_pk?.saleData?.firstRound?.endDate))
    )
  }, [data])

  if (loading) return <Loading active center />
  if (error) return <TextError error={error} />
  if (!data?.estate_by_pk)
    return <TextError error={new Error('Estate not found')} />

  return (
    <div className="tw-h-[calc(100dvh-80px)]">
      {!isSaleOpen && (
        <div className="tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6">
          <EmptySaleSvg />
          <p className="tw-text-zinc-900 tw-font-semibold tw-text-lg">
            {isAgencyMember
              ? t('EstateSalePage.emptyState.title')
              : t('EstateSalePage.emptyState.sellerTitle', {
                  agency: data.estate_by_pk.seller.agency.name,
                })}
          </p>
          <p className="tw-text-zinc-900 tw-text-sm tw-max-w-80 tw-text-center">
            {t('EstateSalePage.emptyState.description')}
          </p>
          <EstateSettingModal
            estate={data.estate_by_pk}
            open={isOpen}
            setOpenModal={setIsOpen}
            disabled={data.estate_by_pk.archived}
            readOnly={!isAgencyMember}
          />
        </div>
      )}
      {isSaleOpen && (
        <>
          <ApplicationsStatsBlock estate={data.estate_by_pk} />
          <ApplicationsTimeline estate={data.estate_by_pk} />
          <Separator />
          <div className="tw-p-8">
            <div className="tw-flex tw-justify-between tw-items-baseline">
              <h1 className="tw-text-[40px] tw-font-semibold">
                {t('EstateSalePage.heading')}
              </h1>
              <div className="tw-flex tw-gap-2">
                <EstateVisitSpanModal
                  estate={data.estate_by_pk}
                  open={isOpenSpan}
                  setOpenModal={setIsOpenSpan}
                />
                <EstateSettingModal
                  estate={data.estate_by_pk}
                  open={isOpen}
                  setOpenModal={setIsOpen}
                  disabled={data.estate_by_pk.archived}
                  readOnly={!isAgencyMember}
                />
              </div>
            </div>
            <ApplicationsTable estate={data.estate_by_pk} />
          </div>
        </>
      )}
    </div>
  )
}
