import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Mandate_Type_Enum } from '@gql'
import React, { forwardRef } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type MandateTypeSelectProps = {
  field: ControllerRenderProps<any>
  className?: string
  disabled?: boolean
}
export const MandateTypeSelect = forwardRef<
  HTMLInputElement,
  MandateTypeSelectProps
>(({ field, className, disabled }, ref) => {
  const { t } = useTranslation()
  return (
    <FormItem className={className}>
      <FormLabel>{t('MandateTypeSelect.label')}</FormLabel>
      <Select
        onValueChange={field.onChange}
        value={field.value}
        disabled={disabled}
      >
        <FormControl>
          <SelectTrigger className="tw-w-full">
            <SelectValue
              ref={ref}
              placeholder={t('MandateTypeSelect.placeholder')}
            />
          </SelectTrigger>
        </FormControl>
        <SelectContent>
          {Object.values(Mandate_Type_Enum).map((type) => (
            <SelectItem value={type} key={type}>
              {t(`common.mandate.${type}`)}
            </SelectItem>
          ))}
        </SelectContent>
        <FormMessage />
      </Select>
    </FormItem>
  )
})

MandateTypeSelect.displayName = 'MandateTypeSelect'
