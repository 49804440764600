import { closestTo, compareAsc } from 'date-fns'
import { ApplicationFragment } from 'gql'

export default function getLatestApplication(
  applications: ApplicationFragment[]
) {
  const dates = applications.map(
    (application) => new Date(application.createdAt)
  )
  const closestToDate = closestTo(new Date(), dates)
  if (!closestToDate) return

  return applications.find(
    (application) =>
      compareAsc(new Date(application.createdAt), closestToDate) === 0
  )
}
