import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { emailSchema } from '@almaris/shared/schemas'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

interface MemberAddFormProps {
  onClick: (email: string) => Promise<void>
  value?: string
  loading: boolean
}

const schema = yup.object().shape({
  email: emailSchema.required(),
})

export const MemberAddForm = ({ onClick, value }: MemberAddFormProps) => {
  const { t } = useTranslation()
  const [email, setEmail] = useState(value ?? '')
  const [error, setError] = useState<string | null>(null)

  const handleValidate = async () => {
    try {
      await schema.validate({ email })
      await onClick(email)
      setError(null)
    } catch (validationError) {
      setError((validationError as yup.ValidationError).message)
    }
  }
  return (
    <div className="tw-mb-4 ">
      <div className="tw-flex tw-items-center tw-gap-4">
        <Input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t('MemberAddForm.emailPlaceholder') as string}
        />
        <Button onClick={handleValidate}>
          {t('MemberAddForm.actions.add')}
        </Button>
      </div>
      {error && <div className="tw-text-red-500">{error}</div>}
    </div>
  )
}
