import { StarIcon } from 'lucide-react'
import React, { useState } from 'react'

type StarScoreProps = {
  value: number
  max?: number
  onChange?: (value: number) => void
  disabled?: boolean
}

export const StarScore = ({
  value,
  max = 5,
  onChange,
  disabled,
}: StarScoreProps) => {
  const stars = new Array(max).fill(0).map((_, index) => index + 1)
  const [selected, setSelected] = useState(value)

  const handleChange = (value: number) => {
    setSelected(value)
    onChange?.(value)
  }

  return (
    <div className="tw-flex tw-items-center tw-gap-1">
      {stars.map((star) => (
        <StarIcon
          key={star}
          aria-disabled={disabled}
          aria-label={star.toString()}
          className="tw-h-4 tw-w-4 hover:tw-cursor-pointer hover:tw-fill-zinc-500 hover:tw-stroke-zinc-500 aria-disabled:tw-cursor-default"
          stroke={selected >= star ? '#18181B' : '#E4E4E7'}
          fill={selected >= star ? '#18181B' : '#E4E4E7'}
          onClick={() => {
            if (!disabled) {
              handleChange(star)
            }
          }}
        />
      ))}
    </div>
  )
}
