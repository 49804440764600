import { TableFilter } from '@/common/molecules/TableFacetedFilters'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import React from 'react'
import { useTranslation } from 'react-i18next'

type TableGroupBySelectProps = {
  options: TableFilter[]
  groupingValue?: string
  setGroupingValue: (value: string) => void
}

export function TableGroupBySelect({
  options,
  groupingValue,
  setGroupingValue,
}: TableGroupBySelectProps) {
  const { t } = useTranslation()

  // Handle value undefined when ungrouping the table
  const value = groupingValue ?? ''

  return (
    <Select onValueChange={setGroupingValue} value={value}>
      <SelectTrigger className="tw-max-w-40 tw-w-full">
        <SelectValue placeholder={t('TableGroupBySelect.placeholder')} />
      </SelectTrigger>
      <SelectContent>
        {options.map((data) => (
          <SelectItem value={data.value} key={data.value}>
            {data.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
