import * as React from 'react'

import { cn } from '@/lib/utils'

export interface HeadingProps
  extends React.AllHTMLAttributes<HTMLHeadingElement> {}

const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ className, ...props }, ref) => {
    return (
      <h1
        className={cn('tw-text-3xl tw-font-semibold ', className)}
        ref={ref}
        {...props}
      >
        {props.children}
      </h1>
    )
  }
)
Heading.displayName = 'Heading'

export { Heading }
