import { AgencyFragment } from '@gql'
import { useStoreState } from '@store/hooks'
import { useMemo } from 'react'

export default function useAgency(id?: string): {
  agency: AgencyFragment | undefined
  loading: boolean
  error: Error | undefined
} {
  const getById = useStoreState((state) => state.agencies.getById)
  const loading = useStoreState((state) => state.agencies.loading)
  const error = useStoreState((state) => state.agencies.error)

  const agency = useMemo(() => (id ? getById(id) : undefined), [getById, id])
  return { agency, loading, error }
}
