import React, { useState } from 'react'
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  GroupingState,
  SortingState,
  Table as TanstackTable,
  useReactTable,
} from '@tanstack/react-table'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Button } from '@/components/ui/button'
import { ChevronDownIcon, ChevronRightIcon, PinOffIcon } from 'lucide-react'
import { TableRowEmpty } from '@/common/atoms/TableRowEmpty'

interface EstatesCoreTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data?: TData[]
  toolbar: (
    table: TanstackTable<TData>,
    grouping: GroupingState,
    setGrouping: (value: GroupingState) => void
  ) => React.ReactNode
}

export function EstatesCoreTable<TData, TValue>({
  columns,
  data,
  toolbar,
}: EstatesCoreTableProps<TData, TValue>) {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [sorting, setSorting] = useState<SortingState>([])
  const [grouping, setGrouping] = useState<GroupingState>([])

  const table = useReactTable({
    data: data || [],
    columns,
    state: {
      sorting,
      columnFilters,
      grouping,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getGroupedRowModel: getGroupedRowModel(),
    onGroupingChange: setGrouping,
    getExpandedRowModel: getExpandedRowModel(),
  })

  return (
    <div>
      {toolbar(table, grouping, setGrouping)}
      <div className="tw-rounded-md tw-border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div className="tw-flex tw-items-center">
                          {header.column.getCanGroup() ? (
                            // If the header can be grouped, let's add a toggle
                            <button
                              onClick={() => {
                                setGrouping([])
                                header.column.getToggleGroupingHandler()
                              }}
                            >
                              {header.column.getIsGrouped() && (
                                <div className="tw-mr-2 tw-border tw-border-gray-300 tw-bg-zinc-300 tw-rounded-md tw-p-2">
                                  <PinOffIcon className="tw-h-4 tw-w-4 tw-stroke-white" />
                                </div>
                              )}
                            </button>
                          ) : null}{' '}
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      className={cell.column.id === 'actions' ? 'tw-w-4' : ''}
                      {...{
                        style: {
                          background: cell.getIsGrouped()
                            ? '#FAFAFA'
                            : cell.getIsAggregated()
                              ? '#FAFAFA'
                              : 'white',
                        },
                      }}
                    >
                      {cell.getIsGrouped() ? (
                        // If it's a grouped cell, add an expander and row count
                        <>
                          <Button
                            variant="ghost"
                            onClick={row.getToggleExpandedHandler()}
                            {...{
                              style: {
                                cursor: row.getCanExpand()
                                  ? 'pointer'
                                  : 'normal',
                              },
                            }}
                          >
                            {row.getIsExpanded() ? (
                              <ChevronDownIcon className="tw-mr-2 tw-h-4 tw-w-4" />
                            ) : (
                              <ChevronRightIcon className="tw-mr-2 tw-h-4 tw-w-4" />
                            )}{' '}
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}{' '}
                            ({row.subRows.length})
                          </Button>
                        </>
                      ) : cell.getIsAggregated() ? (
                        // If the cell is aggregated, use the Aggregated
                        // renderer for cell
                        flexRender(
                          // cell.column.columnDef.cell ??
                          cell.column.columnDef.aggregatedCell,
                          cell.getContext()
                        )
                      ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                        // Otherwise, just render the regular cell
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRowEmpty length={columns.length} />
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
