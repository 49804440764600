import { SellerFragment } from '@gql'
import { useStoreState } from '@store/hooks'

export default function useSellers(): {
  sellers: SellerFragment[] | undefined
  loading: boolean
  error: Error | undefined
} {
  const sellers = useStoreState((state) => state.sellers.entries)
  const loading = useStoreState((state) => state.sellers.loading)
  const error = useStoreState((state) => state.sellers.error)

  return {
    sellers,
    loading,
    error,
  }
}
