import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { usePathInSeller } from '@/seller/hooks/usePathInSeller'
import {
  ArrowLeftIcon,
  BookMarkedIcon,
  FileTextIcon,
  GavelIcon,
  HomeIcon,
  ImagesIcon,
} from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import useCurrentEstate from '../hooks/useCurrentEstate'
import TextError from '@/common/atoms/TextError'
import { EstateSidebarAgencyMemberSelect } from './EstateSidebarAgencyMemberSelect'
import { useNavigateSeller } from '@/seller/hooks/useNavigateSeller'

type Params = {
  slug: string
}

export const EstateSidebar = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const navigateSeller = useNavigateSeller()
  const { slug } = useParams<Params>()
  const rootPath = slug ? usePathInSeller('') : '/'

  const estate = useCurrentEstate()
  if (!estate) return <TextError error={new Error('Estate not found')} />

  return (
    <aside className="tw-border-r tw-border-zinc-200 tw-bg-zinc-50 tw-h-[calc(100vh_-_80px)] tw-flex tw-flex-col tw-justify-between">
      <div className="tw-flex tw-flex-col tw-gap-3">
        <div className="tw-flex tw-items-center tw-mt-3 tw-gap-4 tw-px-4">
          <Button
            aria-label="Retour"
            variant="outline"
            size="sm"
            className="tw-flex tw-items-center tw-rounded-lg "
            onClick={() => (slug ? navigateSeller('') : navigate('/'))}
          >
            <ArrowLeftIcon size={15} />
          </Button>
          {estate?.name && (
            <span className="tw-text-zinc-900 tw-text-lg tw-font-semibold">
              {estate?.name}
            </span>
          )}
          {!estate?.name && (
            <span className="tw-text-zinc-900 tw-text-lg tw-font-semibold">
              {estate?.address} {estate?.zipCode} {estate?.city}
            </span>
          )}
        </div>
        <Separator />
        <div className="tw-flex-1 tw-px-4">
          <nav className="tw-grid tw-items-start tw-text-sm tw-font-medium tw-gap-4">
            <EstateSidebarLink
              to={`${rootPath}estates/${estate.id}/sellers`}
              left={<HomeIcon className="tw-stroke-zinc-400" />}
            >
              {t('EstateSidebar.sellers')}
            </EstateSidebarLink>
            <EstateSidebarLink
              to={`${rootPath}estates/${estate.id}`}
              left={<HomeIcon className="tw-stroke-zinc-400" />}
            >
              {t('EstateSidebar.data')}
            </EstateSidebarLink>
            <EstateSidebarLink
              to={`${rootPath}estates/${estate.id}/files`}
              left={<FileTextIcon className="tw-stroke-zinc-400" />}
            >
              {t('EstateSidebar.files')}
            </EstateSidebarLink>
            <EstateSidebarLink
              to={`${rootPath}estates/${estate.id}/medias`}
              left={<ImagesIcon className="tw-stroke-zinc-400" />}
            >
              {t('EstateSidebar.medias')}
            </EstateSidebarLink>
            <EstateSidebarLink
              to={`${rootPath}estates/${estate.id}/mandate`}
              left={<BookMarkedIcon className="tw-stroke-zinc-400" />}
            >
              {t('EstateSidebar.mandate')}
            </EstateSidebarLink>
            <EstateSidebarLink
              to={`${rootPath}estates/${estate.id}/sale`}
              left={<GavelIcon className="tw-stroke-zinc-400" />}
            >
              {t('EstateSidebar.sale')}
            </EstateSidebarLink>
          </nav>
        </div>
      </div>
      <div className="tw-px-4 tw-mb-4 tw-space-y-2">
        <EstateSidebarAgencyMemberSelect estate={estate} />
      </div>
    </aside>
  )
}

const EstateSidebarLink = ({
  to,
  left,
  right,
  children,
}: {
  to: string
  left?: React.ReactNode
  right?: React.ReactNode
  children: React.ReactNode
}) => {
  return (
    <NavLink
      to={to}
      className="tw-flex tw-items-center tw-justify-between tw-gap-3 tw-rounded-[8px] tw-px-4 tw-py-3 tw-text-m tw-text-zinc-900 tw-transition-all tw-border tw-border-transparent hover:tw-bg-white hover:tw-border hover:tw-border-zinc-200 hover:tw-shadow-sm   aria-[current=page]:tw-bg-white aria-[current=page]:tw-border aria-[current=page]:tw-border-zinc-200 aria-[current=page]:tw-shadow-sm"
      end
    >
      <div className="tw-flex tw-items-center tw-gap-3">
        {left}
        <span>{children}</span>
      </div>
      {right}
    </NavLink>
  )
}
