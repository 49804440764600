import * as yup from 'yup'

export const nameSchema = yup.string().min(1)

export const emailSchema = yup.string().email()

export const slugSchema = yup
  .string()
  .min(5)
  .max(30)
  .test(
    'Slug',
    'Slug must be lowercase and contain only letters, numbers and dashes',
    (slug = '') => /^[a-z0-9]+(-[a-z0-9]+)*$/.test(slug)
  )

export const addressSchema = yup.object().shape({
  city: yup.string().nullable().default(null),
  country: yup.string().nullable().default(null),
  line1: yup.string().nullable().default(null),
  line2: yup.string().nullable().default(null),
  postal_code: yup.string().nullable().default(null),
  state: yup.string().nullable().default(null),
})

export const billingDetailsSchema = yup.object().shape({
  name: yup.string().nullable(),
  email: yup.string().nullable(),
  address: addressSchema.nullable().optional(),
})

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/
// TODO rename to getPasswordSchema
// TODO use options prop
// TODO use yup.addMethod
export const passwordSchema = (message: string) =>
  yup.string().matches(passwordRegex, {
    message,
  })

// TODO use yup.addMethod
export type GetDateStringSchemaProps = {
  optional?: boolean
}
export const getDateStringSchema = (options: GetDateStringSchemaProps) =>
  yup.string().test('Date', 'Invalid date', (value?: string) => {
    if (!value) return options.optional
    return !isNaN(new Date(value).getTime())
  })
