import CrispSetUser from '@/common/atoms/CrispSetUser'
import Loading from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import Page404 from '@/common/pages/Page404'
import MemberInvitationPage from '@/seller/pages/MemberInvitationPage'
import {
  useAgenciesSubscription,
  useAgencyMembersSubscription,
  useEstatesSubscription,
  useSellersSubscription,
} from '@gql'
import { useUserId, useUserLocale } from '@nhost/react'
import { useStoreActions } from '@store/hooks'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes } from 'react-router-dom'
import { langs, locales } from 'src/i18n'
import { AuthLayout } from '@/layout/AuthLayout'
import { ResetPasswordPage } from '@/user/pages/ResetPasswordPage'
import { ForgotPasswordForm } from '@/user/components/ForgotPasswordForm'
import { DefaultLayout } from '@/layout/DefaultLayout'
import { UserInfoPage } from '@/user/pages/UserInfoPage'
import SellersPage from '@/seller/pages/SellersPage'
import { EstateRoute } from './EstateRoute'
import { AdminUsersPage } from '@/agencyMember/pages/AdminUsersPage'
import AdminInvitationPage from '@/agencyMember/pages/AdminInvitationPage'
import { EstatesAgencyPage } from '@/estate/pages/EstatesAgencyPage'
import SellerRoute from './SellerRoute'

export default function PrivateRoute() {
  const userId = useUserId()

  const {
    i18n: { changeLanguage },
  } = useTranslation()

  // Update translation language with user's locale in DB
  const userLocale = useUserLocale()
  useEffect(() => {
    if (userLocale && langs.includes(userLocale as keyof typeof locales)) {
      changeLanguage(userLocale)
    }
  }, [])

  // TODO Redirect to slug when connected as SellerMember
  // const navigate = useNavigate()
  // const sellerClaim = nhost.auth.getHasuraClaim('seller-member-id')
  // const { data } = useGetSellerMemberQuery({
  //   variables: {
  //     id: String(sellerClaim),
  //   },
  //   skip: !sellerClaim,
  // })

  // useEffect(() => {
  //   if (!sellerClaim || !data?.seller_member_by_pk?.seller) return
  //   navigate(`/${data?.seller_member_by_pk?.seller?.slug}`)
  // }, [data])

  // Subscribe to agencies
  const agenciesResult = useAgenciesSubscription({
    skip: !userId,
  })

  const setAgenciesSubscriptionResult = useStoreActions(
    (actions) => actions.agencies.setSubscriptionResult
  )

  const actions = useStoreActions((actions) => ({
    setCurrentId: actions.agency.setCurrentId,
    setSubscriptionResult: actions.agency.setSubscriptionResult,
  }))

  useEffect(() => {
    setAgenciesSubscriptionResult({
      entries: agenciesResult.data?.agency,
      loading: agenciesResult.loading,
      error: agenciesResult.error,
    })
    // TODO: Set current agency in store when i only have one
    actions.setCurrentId(agenciesResult.data?.agency[0]?.id)
    actions.setSubscriptionResult({
      result: agenciesResult.data?.agency[0],
      loading: agenciesResult.loading,
      error: agenciesResult.error,
    })
  }, [agenciesResult])

  // Subscribe to agencyMembers
  const agencyMembersResult = useAgencyMembersSubscription({
    skip: !userId,
  })

  const setAgencyMembersSubscriptionResult = useStoreActions(
    (actions) => actions.agencyMembers.setSubscriptionResult
  )

  useEffect(() => {
    setAgencyMembersSubscriptionResult({
      entries: agencyMembersResult.data?.agency_member,
      loading: agencyMembersResult.loading,
      error: agencyMembersResult.error,
    })
  }, [agencyMembersResult])

  // Subscribe to sellers
  const sellersResult = useSellersSubscription({
    skip: !userId,
  })

  const setSellersSubscriptionResult = useStoreActions(
    (actions) => actions.sellers.setSubscriptionResult
  )

  useEffect(() => {
    setSellersSubscriptionResult({
      entries: sellersResult.data?.seller,
      loading: sellersResult.loading,
      error: sellersResult.error,
    })
  }, [sellersResult])

  // Subscribe to estates
  const estatesResult = useEstatesSubscription({
    skip: !userId,
  })

  const setEstatesSubscriptionResult = useStoreActions(
    (actions) => actions.estates.setSubscriptionResult
  )

  useEffect(() => {
    setEstatesSubscriptionResult({
      entries: estatesResult.data?.estate,
      loading: estatesResult.loading,
      error: estatesResult.error,
    })
  }, [estatesResult])

  return (
    <>
      <Loading
        center
        active={
          agenciesResult.loading ||
          estatesResult.loading ||
          sellersResult.loading ||
          agencyMembersResult.loading
        }
      />
      {agenciesResult.error && <TextError error={agenciesResult.error} />}
      <Routes>
        <Route path="/" element={<AuthLayout />}>
          <Route path="reset-password" element={<ResetPasswordPage />} />
          <Route path="forgot-password" element={<ForgotPasswordForm />} />
          <Route path="login" element={<Navigate to="/" />} />
          <Route path="signup" element={<Navigate to="/" />} />
          <Route
            path="sellers/:sellerId/invitation"
            element={<MemberInvitationPage />}
          />
          <Route
            path="agency/:agencyId/invitation"
            element={<AdminInvitationPage />}
          />
        </Route>
        <Route element={<DefaultLayout />}>
          <Route index element={<EstatesAgencyPage />} />
          <Route path="user-info/:userId" element={<UserInfoPage />} />
          <Route path="estates/:estateId/*" element={<EstateRoute />} />
          <Route path=":slug/*" element={<SellerRoute />} />
          <Route path="sellers" element={<SellersPage />} />
          <Route path="sellers/:sellerId/*" element={<SellerRoute />} />
          <Route path="agency-members" element={<AdminUsersPage />} />
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
      <CrispSetUser />
    </>
  )
}
