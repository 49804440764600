import React, { useMemo } from 'react'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { useTranslation } from 'react-i18next'
import { EstateFragment } from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Input } from '@/components/ui/input'
import { Card, CardTitle } from '@/components/ui/card'
import { Switch } from '@/components/ui/switch'
import { InputDatePicker } from '@/components/ui/InputDatePicker'

// TODO better typing
const schema = yup.object().shape({
  asbestos: yup.boolean().optional(),
  asbestosDate: yup.date().optional(),
  asbestosValidityDate: yup.date().optional(),
  // carrez: yup.boolean().optional(),
  // carrezArea: yup.string().optional(),
  // carrezFloorArea: yup.string().optional(),
  // carrezDate: yup.date().optional(),
  epc: yup.boolean().optional(),
  epcConsumption: yup.number().optional(),
  epcEmission: yup.number().optional(),
  epcDate: yup.date().optional(),
  elec: yup.boolean().optional(),
  elecDate: yup.date().optional(),
  elecValidityDate: yup.date().optional(),
  // fungalDecay: yup.boolean().optional(),
  // fungalDecayDate: yup.date().optional(),
  gas: yup.boolean().optional(),
  gasDate: yup.date().optional(),
  gasValidityDate: yup.date().optional(),
  lead: yup.boolean().optional(),
  leadDate: yup.date().optional(),
  leadValidityDate: yup.date().optional(),
  noise: yup.boolean().optional(),
  noiseDate: yup.date().optional(),
  noiseValidityDate: yup.date().optional(),
  ntr: yup.boolean().optional(),
  ntrDate: yup.date().optional(),
  ntrValidityDate: yup.date().optional(),
  // sanitation: yup.boolean().optional(),
  // sanitationDate: yup.date().optional(),
  // sanitationValidityDate: yup.date().optional(),
  // termites: yup.boolean().optional(),
  // termitesDate: yup.date().optional(),
  // termitesValidityDate: yup.date().optional(),
})

const resolver = yupResolver(schema)

export type FormValues = yup.InferType<typeof schema>

export type EstateDiagnosticsDataFormProps = {
  estate: EstateFragment
  onSubmit: (values: Partial<EstateFragment>) => void
}
export const EstateDiagnosticsDataForm = ({
  estate,
  onSubmit,
}: EstateDiagnosticsDataFormProps) => {
  // TODO use GenericForm
  const { t } = useTranslation()

  const defaultValues = useMemo<FormValues>(
    () => ({
      asbestos: estate.diagnosticsData?.asbestos ?? false,
      asbestosDate: estate.diagnosticsData?.asbestosDate
        ? new Date(estate.diagnosticsData?.asbestosDate)
        : undefined,
      asbestosValidityDate: estate.diagnosticsData?.asbestosValidityDate
        ? new Date(estate.diagnosticsData?.asbestosValidityDate)
        : undefined,
      // carrez: estate.diagnosticsData?.carrez ?? false,
      // carrezArea: estate.diagnosticsData?.carrezArea ?? undefined,
      // carrezFloorArea: estate.diagnosticsData?.carrezFloorArea ?? undefined,
      // carrezDate: estate.diagnosticsData?.carrezDate
      //   ? new Date(estate.diagnosticsData?.carrezDate)
      //   : undefined,
      epc: estate.diagnosticsData?.epc ?? false,
      epcConsumption: estate.diagnosticsData?.epcConsumption ?? undefined,
      epcEmission: estate.diagnosticsData?.epcEmission ?? undefined,
      epcDate: estate.diagnosticsData?.epcDate
        ? new Date(estate.diagnosticsData?.epcDate)
        : undefined,
      elec: estate.diagnosticsData?.elec ?? false,
      elecDate: estate.diagnosticsData?.elecDate
        ? new Date(estate.diagnosticsData?.elecDate)
        : undefined,
      elecValidityDate: estate.diagnosticsData?.elecValidityDate
        ? new Date(estate.diagnosticsData?.elecValidityDate)
        : undefined,
      // fungalDecay: estate.diagnosticsData?.fungalDecay ?? false,
      // fungalDecayDate: estate.diagnosticsData?.fungalDecayDate
      //   ? new Date(estate.diagnosticsData?.fungalDecayDate)
      //   : undefined,
      gas: estate.diagnosticsData?.gas ?? false,
      gasDate: estate.diagnosticsData?.gasDate
        ? new Date(estate.diagnosticsData?.gasDate)
        : undefined,
      gasValidityDate: estate.diagnosticsData?.gasValidityDate
        ? new Date(estate.diagnosticsData?.gasValidityDate)
        : undefined,
      lead: estate.diagnosticsData?.lead ?? false,
      leadDate: estate.diagnosticsData?.leadDate
        ? new Date(estate.diagnosticsData?.leadDate)
        : undefined,
      leadValidityDate: estate.diagnosticsData?.leadValidityDate
        ? new Date(estate.diagnosticsData?.leadValidityDate)
        : undefined,
      noise: estate.diagnosticsData?.noise ?? false,
      noiseDate: estate.diagnosticsData?.noiseDate
        ? new Date(estate.diagnosticsData?.noiseDate)
        : undefined,
      noiseValidityDate: estate.diagnosticsData?.noiseValidityDate
        ? new Date(estate.diagnosticsData?.noiseValidityDate)
        : undefined,
      ntr: estate.diagnosticsData?.ntr ?? false,
      ntrDate: estate.diagnosticsData?.ntrDate
        ? new Date(estate.diagnosticsData?.ntrDate)
        : undefined,
      ntrValidityDate: estate.diagnosticsData?.ntrValidityDate
        ? new Date(estate.diagnosticsData?.ntrValidityDate)
        : undefined,
      // sanitation: estate.diagnosticsData?.sanitation ?? false,
      // sanitationDate: estate.diagnosticsData?.sanitationDate
      //   ? new Date(estate.diagnosticsData?.sanitationDate)
      //   : undefined,
      // sanitationValidityDate: estate.diagnosticsData?.sanitationValidityDate
      //   ? new Date(estate.diagnosticsData?.sanitationValidityDate)
      //   : undefined,
      // termites: estate.diagnosticsData?.termites ?? false,
      // termitesDate: estate.diagnosticsData?.termitesDate
      //   ? new Date(estate.diagnosticsData?.termitesDate)
      //   : undefined,
      // termitesValidityDate: estate.diagnosticsData?.termitesValidityDate
      //   ? new Date(estate.diagnosticsData?.termitesValidityDate)
      //   : undefined,
    }),
    [estate]
  )

  const form = useForm<FormValues>({
    resolver,
    defaultValues,
  })

  const {
    asbestos,
    // carrez,
    elec,
    epc,
    // fungalDecay,
    gas,
    lead,
    noise,
    ntr,
    // sanitation,
    // termites,
  } = form.watch()

  form.watch(() => {
    form.handleSubmit((diagnosticsData) => onSubmit({ diagnosticsData }))()
  })

  return (
    <Form {...form}>
      <form className="tw-space-y-5">
        <Card className="tw-p-4">
          <CardTitle className="tw-flex tw-flex-row tw-justify-between tw-mb-4">
            <FormField
              control={form.control}
              name={'asbestos'}
              render={({ field: { value, ...field } }) => (
                <FormItem className="tw-flex tw-w-full tw-flex-row tw-justify-between tw-items-center">
                  <FormLabel className="tw-text-xl tw-font-semibold">
                    {t('EstateDiagnosticsDataForm.asbestos.title')}
                  </FormLabel>
                  <FormControl>
                    <Switch
                      {...field}
                      checked={!!value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardTitle>
          {asbestos && (
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
              <FormField
                control={form.control}
                name={'asbestosDate'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.asbestos.date')}
                    </FormLabel>
                    <FormControl>
                      <InputDatePicker
                        {...field}
                        fromYear={1900}
                        toYear={2100}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={'asbestosValidityDate'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.asbestos.validityDate')}
                    </FormLabel>
                    <FormControl>
                      <InputDatePicker
                        {...field}
                        fromYear={1900}
                        toYear={2100}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          )}
        </Card>

        {/* <Card className="tw-p-4">
          <CardTitle className="tw-flex tw-flex-row tw-justify-between tw-mb-4">
            <FormField
              control={form.control}
              name={'carrez'}
              render={({ field: { value, ...field } }) => (
                <FormItem className="tw-flex tw-w-full tw-flex-row tw-justify-between tw-items-center">
                  <FormLabel className="tw-text-xl tw-font-semibold">
                    {t('EstateDiagnosticsDataForm.carrez.title')}
                  </FormLabel>
                  <FormControl>
                    <Switch
                      {...field}
                      checked={!!value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardTitle>
          {carrez && (
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
              <FormField
                control={form.control}
                name={'carrezArea'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.carrez.area')}
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={'carrezFloorArea'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.carrez.floorArea')}
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={'carrezDate'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.carrez.date')}
                    </FormLabel>
                    <FormControl>
                    
                <InputDatePicker
                  {...field}
                />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          )}
        </Card> */}

        <Card className="tw-p-4">
          <CardTitle className="tw-flex tw-flex-row tw-justify-between tw-mb-4">
            <FormField
              control={form.control}
              name={'epc'}
              render={({ field: { value, ...field } }) => (
                <FormItem className="tw-flex tw-w-full tw-flex-row tw-justify-between tw-items-center">
                  <FormLabel className="tw-text-xl tw-font-semibold">
                    {t('EstateDiagnosticsDataForm.epc.title')}
                  </FormLabel>
                  <FormControl>
                    <Switch
                      {...field}
                      checked={!!value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardTitle>
          {epc && (
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
              <FormField
                control={form.control}
                name={'epcConsumption'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.epc.consumption')}
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={'epcEmission'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.epc.emission')}
                    </FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={'epcDate'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.epc.date')}
                    </FormLabel>
                    <FormControl>
                      <InputDatePicker
                        {...field}
                        fromYear={1900}
                        toYear={2100}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          )}
        </Card>

        <Card className="tw-p-4">
          <CardTitle className="tw-flex tw-flex-row tw-justify-between tw-mb-4">
            <FormField
              control={form.control}
              name={'elec'}
              render={({ field: { value, ...field } }) => (
                <FormItem className="tw-flex tw-w-full tw-flex-row tw-justify-between tw-items-center">
                  <FormLabel className="tw-text-xl tw-font-semibold">
                    {t('EstateDiagnosticsDataForm.elec.title')}
                  </FormLabel>
                  <FormControl>
                    <Switch
                      {...field}
                      checked={!!value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardTitle>
          {elec && (
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
              <FormField
                control={form.control}
                name={'elecDate'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.elec.date')}
                    </FormLabel>
                    <FormControl>
                      <InputDatePicker
                        {...field}
                        fromYear={1900}
                        toYear={2100}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={'elecValidityDate'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.elec.validityDate')}
                    </FormLabel>
                    <FormControl>
                      <InputDatePicker
                        {...field}
                        fromYear={1900}
                        toYear={2100}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          )}
        </Card>

        {/* <Card className="tw-p-4">
          <CardTitle className="tw-flex tw-flex-row tw-justify-between tw-mb-4">
            <FormField
              control={form.control}
              name={'fungalDecay'}
              render={({ field: { value, ...field } }) => (
                <FormItem className="tw-flex tw-w-full tw-flex-row tw-justify-between tw-items-center">
                  <FormLabel className="tw-text-xl tw-font-semibold">
                    {t('EstateDiagnosticsDataForm.fungalDecay.title')}
                  </FormLabel>
                  <FormControl>
                    <Switch
                      {...field}
                      checked={!!value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardTitle>
          {fungalDecay && (
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
              <FormField
                control={form.control}
                name={'fungalDecayDate'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.fungalDecay.date')}
                    </FormLabel>
                    <FormControl>
                    
                <InputDatePicker
                  {...field}
                />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          )}
        </Card> */}

        <Card className="tw-p-4">
          <CardTitle className="tw-flex tw-flex-row tw-justify-between tw-mb-4">
            <FormField
              control={form.control}
              name={'gas'}
              render={({ field: { value, ...field } }) => (
                <FormItem className="tw-flex tw-w-full tw-flex-row tw-justify-between tw-items-center">
                  <FormLabel className="tw-text-xl tw-font-semibold">
                    {t('EstateDiagnosticsDataForm.gas.title')}
                  </FormLabel>
                  <FormControl>
                    <Switch
                      {...field}
                      checked={!!value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardTitle>
          {gas && (
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
              <FormField
                control={form.control}
                name={'gasDate'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.gas.date')}
                    </FormLabel>
                    <FormControl>
                      <InputDatePicker
                        {...field}
                        fromYear={1900}
                        toYear={2100}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={'gasValidityDate'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.gas.validityDate')}
                    </FormLabel>
                    <FormControl>
                      <InputDatePicker
                        {...field}
                        fromYear={1900}
                        toYear={2100}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          )}
        </Card>

        <Card className="tw-p-4">
          <CardTitle className="tw-flex tw-flex-row tw-justify-between tw-mb-4">
            <FormField
              control={form.control}
              name={'lead'}
              render={({ field: { value, ...field } }) => (
                <FormItem className="tw-flex tw-w-full tw-flex-row tw-justify-between tw-items-center">
                  <FormLabel className="tw-text-xl tw-font-semibold">
                    {t('EstateDiagnosticsDataForm.lead.title')}
                  </FormLabel>
                  <FormControl>
                    <Switch
                      {...field}
                      checked={!!value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardTitle>
          {lead && (
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
              <FormField
                control={form.control}
                name={'leadDate'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.lead.date')}
                    </FormLabel>
                    <FormControl>
                      <InputDatePicker
                        {...field}
                        fromYear={1900}
                        toYear={2100}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={'leadValidityDate'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.lead.validityDate')}
                    </FormLabel>
                    <FormControl>
                      <InputDatePicker
                        {...field}
                        fromYear={1900}
                        toYear={2100}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          )}
        </Card>

        <Card className="tw-p-4">
          <CardTitle className="tw-flex tw-flex-row tw-justify-between tw-mb-4">
            <FormField
              control={form.control}
              name={'noise'}
              render={({ field: { value, ...field } }) => (
                <FormItem className="tw-flex tw-w-full tw-flex-row tw-justify-between tw-items-center">
                  <FormLabel className="tw-text-xl tw-font-semibold">
                    {t('EstateDiagnosticsDataForm.noise.title')}
                  </FormLabel>
                  <FormControl>
                    <Switch
                      {...field}
                      checked={!!value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardTitle>
          {noise && (
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
              <FormField
                control={form.control}
                name={'noiseDate'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.noise.date')}
                    </FormLabel>
                    <FormControl>
                      <InputDatePicker
                        {...field}
                        fromYear={1900}
                        toYear={2100}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={'noiseValidityDate'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.noise.validityDate')}
                    </FormLabel>
                    <FormControl>
                      <InputDatePicker
                        {...field}
                        fromYear={1900}
                        toYear={2100}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          )}
        </Card>

        <Card className="tw-p-4">
          <CardTitle className="tw-flex tw-flex-row tw-justify-between tw-mb-4">
            <FormField
              control={form.control}
              name={'ntr'}
              render={({ field: { value, ...field } }) => (
                <FormItem className="tw-flex tw-w-full tw-flex-row tw-justify-between tw-items-center">
                  <FormLabel className="tw-text-xl tw-font-semibold">
                    {t('EstateDiagnosticsDataForm.ntr.title')}
                  </FormLabel>
                  <FormControl>
                    <Switch
                      {...field}
                      checked={!!value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardTitle>
          {ntr && (
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
              <FormField
                control={form.control}
                name={'ntrDate'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.ntr.date')}
                    </FormLabel>
                    <FormControl>
                      <InputDatePicker
                        {...field}
                        fromYear={1900}
                        toYear={2100}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={'ntrValidityDate'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.ntr.validityDate')}
                    </FormLabel>
                    <FormControl>
                      <InputDatePicker
                        {...field}
                        fromYear={1900}
                        toYear={2100}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          )}
        </Card>

        {/* <Card className="tw-p-4">
          <CardTitle className="tw-flex tw-flex-row tw-justify-between tw-mb-4">
            <FormField
              control={form.control}
              name={'sanitation'}
              render={({ field: { value, ...field } }) => (
                <FormItem className="tw-flex tw-w-full tw-flex-row tw-justify-between tw-items-center">
                  <FormLabel className="tw-text-xl tw-font-semibold">
                    {t('EstateDiagnosticsDataForm.sanitation.title')}
                  </FormLabel>
                  <FormControl>
                    <Switch
                      {...field}
                      checked={!!value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardTitle>
          {sanitation && (
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
              <FormField
                control={form.control}
                name={'sanitationDate'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.sanitation.date')}
                    </FormLabel>
                    <FormControl>
                    
                <InputDatePicker
                  {...field}
                />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={'sanitationValidityDate'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.sanitation.validityDate')}
                    </FormLabel>
                    <FormControl>
                    
                <InputDatePicker
                  {...field}
                />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          )}
        </Card>

        <Card className="tw-p-4">
          <CardTitle className="tw-flex tw-flex-row tw-justify-between tw-mb-4">
            <FormField
              control={form.control}
              name={'termites'}
              render={({ field: { value, ...field } }) => (
                <FormItem className="tw-flex tw-w-full tw-flex-row tw-justify-between tw-items-center">
                  <FormLabel className="tw-text-xl tw-font-semibold">
                    {t('EstateDiagnosticsDataForm.termites.title')}
                  </FormLabel>
                  <FormControl>
                    <Switch
                      {...field}
                      checked={!!value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardTitle>
          {termites && (
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
              <FormField
                control={form.control}
                name={'termitesDate'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.termites.date')}
                    </FormLabel>
                    <FormControl>
                    
                <InputDatePicker
                  {...field}
                />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={'termitesValidityDate'}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('EstateDiagnosticsDataForm.termites.validityDate')}
                    </FormLabel>
                    <FormControl>
                    
                <InputDatePicker
                  {...field}
                />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          )}
        </Card> */}
      </form>
    </Form>
  )
}
