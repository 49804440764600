import { useGetPresignedUrl } from '@/file/hooks/useGetPresignedUrl'
import { ApplicationMediaFragment } from '@gql'
import React from 'react'
import { Link } from 'react-router-dom'

type ApplicationMediasPreviewProps = {
  applicationMedia: ApplicationMediaFragment
}
export const ApplicationMediasPreview = ({
  applicationMedia,
}: ApplicationMediasPreviewProps) => {
  const { url, loading } = useGetPresignedUrl({
    fileId: String(applicationMedia.file.id),
  })

  return (
    <>
      {loading ? (
        <div className="tw-w-[56px] tw-h-[56px] tw-rounded-lg tw-bg-zinc-100" />
      ) : (
        <Link to={url ?? '#'} target="_blank" className="tw-cursor-pointer">
          <img
            src={url ?? ''}
            alt="document de la candidature"
            className="tw-object-cover tw-w-[56px] tw-h-[56px] tw-rounded-lg"
          />
        </Link>
      )}
    </>
  )
}
