import React, { useMemo, useState } from 'react'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { useTranslation } from 'react-i18next'
import { EstateFragment, SellerFragment } from '@gql'
import { useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import useSellers from '@/seller/hooks/useSellers'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import Loading from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { InputNumber } from '@/components/ui/inputNumber'
import { Switch } from '@/components/ui/switch'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { X } from 'lucide-react'
import { SellerMemberCombobox } from '@/sellerMember/components/SellerMemberCombobox'

const schema = yup.object().shape({
  sellerStructures: yup.array(
    yup.object({
      sellerId: yup.string().required(),
      ownershipShare: yup.number().required().min(0).max(100),
    })
  ),
  singleRepresentativeForMultipleSellers: yup.boolean().optional(),
  mandatedSellerWithPowerDelegation: yup.string().optional(),
})

const resolver = yupResolver(schema)

export type FormValues = yup.InferType<typeof schema>

export type EstateSellerDataFormProps = {
  estate: EstateFragment
  onSubmit: (values: Partial<EstateFragment>) => void
}
export const EstateSellerDataForm = ({
  estate,
  onSubmit,
}: EstateSellerDataFormProps) => {
  const { t } = useTranslation()
  const [key, setKey] = useState(0) // key to force re-render with empty value

  const { sellers: allSellers, loading, error } = useSellers()
  const sellers = useMemo(() => {
    return (
      allSellers?.filter((s) => !s.archived && s.id !== estate.seller.id) || []
    )
  }, [allSellers])

  const getSellerLabel = (sellerId: string) => {
    const seller = sellers?.find((seller) => seller.id === sellerId)
    return seller?.name || ''
  }

  const defaultValues = {
    sellerStructures: estate.sellerData?.sellerStructures || [],
    singleRepresentativeForMultipleSellers:
      estate.sellerData?.singleRepresentativeForMultipleSellers || false,
    mandatedSellerWithPowerDelegation:
      estate.sellerData?.mandatedSellerWithPowerDelegation || '',
  }

  const form = useForm<FormValues>({
    resolver,
    defaultValues,
  })
  const {
    fields: sellerStructures,
    append: appendSellerStructure,
    remove: removeSellerStructure,
  } = useFieldArray({
    control: form.control,
    name: 'sellerStructures',
  })

  form.watch(() => {
    form.handleSubmit((sellerData) => {
      onSubmit({ sellerData })
    })()
  })

  const formValues = form.watch()

  const availableSellers = useMemo(() => {
    return sellers.filter(
      (s) =>
        !formValues.sellerStructures?.find((ss) => {
          return ss.sellerId === s.id
        })
    )
  }, [sellers, formValues.sellerStructures])

  const handleSellerSelect = (sellerId: string) => {
    const seller = sellers?.find((s) => s.id === sellerId)
    if (!seller) return
    if (sellerStructures.find((ss) => ss.sellerId === sellerId)) return
    appendSellerStructure({
      sellerId,
      ownershipShare: estateSellerOwnershipShare / 2,
    })
    setKey(key + 1) // key to force re-render with empty value
  }

  const estateSellerOwnershipShare = useMemo(() => {
    if (!formValues.sellerStructures) return 100
    return (
      100 -
        formValues.sellerStructures.reduce(
          (acc, s) => acc + parseFloat(`${s.ownershipShare}`),
          0
        ) || 0
    )
  }, [formValues])

  const involvedSellerIds = useMemo(() => {
    return [
      estate.seller.id,
      ...((sellerStructures?.map((ss) => ss.sellerId) ||
        []) as SellerFragment['id'][]),
    ]
  }, [sellerStructures])

  if (loading) return <Loading center active />
  if (error) return <TextError error={error} />
  return (
    <>
      <Form {...form}>
        <form className="tw-flex tw-flex-col tw-gap-4">
          <div className="tw-flex tw-flex-row tw-gap-4 tw-align-center tw-justify-between tw-items-center">
            <Label>{estate.seller.name}</Label>
            <Label className="tw-text-muted-foreground tw-flex tw-flex-col tw-items-start tw-gap-3">
              {t('EstateSellerDataForm.ownershipShare')}
              <InputNumber value={estateSellerOwnershipShare} disabled />
            </Label>
          </div>
          {sellerStructures.map(({ id, sellerId }, index: number) => (
            <div
              key={id}
              className="tw-flex tw-flex-row tw-gap-4 tw-align-center tw-justify-between tw-items-center"
            >
              <Button
                type="button"
                onClick={() => removeSellerStructure(index)}
              >
                <X className="tw-size-3 tw-stroke-current" />
              </Button>
              <Label>{getSellerLabel(sellerId)}</Label>
              <FormField
                control={form.control}
                name={`sellerStructures.${index}.ownershipShare`}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>
                        {t('EstateSellerDataForm.ownershipShare')}
                      </FormLabel>
                      <FormControl>
                        <InputNumber {...field} min={0} max={100} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )
                }}
              />
            </div>
          ))}

          {availableSellers && availableSellers.length > 0 && (
            <>
              <Label className="tw-flex tw-flex-col tw-items-start tw-gap-3">
                {t('EstateSellerDataForm.addSeller.label')}
                <Select onValueChange={handleSellerSelect} key={key}>
                  <SelectTrigger className="tw-w-full">
                    <SelectValue
                      placeholder={t(
                        'EstateSellerDataForm.addSeller.placeholder'
                      )}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.values(availableSellers).map(
                      (seller: SellerFragment) => (
                        <SelectItem value={seller.id} key={seller.id}>
                          {seller.name}
                        </SelectItem>
                      )
                    )}
                  </SelectContent>
                </Select>
              </Label>
            </>
          )}

          <FormField
            control={form.control}
            name={`singleRepresentativeForMultipleSellers`}
            render={({ field: { onChange, value, ...field } }) => (
              <FormItem className="tw-flex tw-flex-row tw-gap-4 tw-items-center">
                <FormLabel>
                  {t(
                    'EstateSellerDataForm.singleRepresentativeForMultipleSellers'
                  )}
                </FormLabel>
                <FormControl>
                  <Switch
                    {...field}
                    checked={!!value}
                    onCheckedChange={onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {formValues.singleRepresentativeForMultipleSellers && (
            <FormField
              control={form.control}
              name={`mandatedSellerWithPowerDelegation`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t(
                      'EstateSellerDataForm.mandatedSellerWithPowerDelegation'
                    )}
                  </FormLabel>
                  <FormControl>
                    <SellerMemberCombobox
                      sellerIds={involvedSellerIds}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </form>
      </Form>
    </>
  )
}
