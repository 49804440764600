import React from 'react'
import EstateConfirmDialogContent from './EstateArchiveDialogContent'
import { useEstateSubscription, useUpdateEstateMutation } from '@gql'
import { useTranslation } from 'react-i18next'
import { useToast } from '@/components/ui/use-toast'
import { ConfirmationDialog } from '@/components/ui/ConfirmationDialog'
import { useEstateArchiveDialog } from '@/estate/context/EstateArchiveDialogContext'

export const EstateArchiveDialog = () => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const [update] = useUpdateEstateMutation()

  const { estateId, setEstateId } = useEstateArchiveDialog()

  const { data } = useEstateSubscription({
    variables: {
      id: String(estateId),
    },
    skip: !estateId,
  })

  if (!estateId) return null
  if (!data?.estate_by_pk) return null

  const handleArchive = async () => {
    if (!estateId) return

    if (data?.estate_by_pk?.archived) {
      await update({
        variables: {
          id: estateId,
          values: {
            archived: false,
          },
        },
        onCompleted: () => {
          toast({
            description: t('EstatesTableActionDropdown.toast.activeDesc'),
            variant: 'success',
          })
        },
        onError: (error) => {
          toast({
            description: error.message,
            variant: 'destructive',
          })
        },
      })
    } else {
      await update({
        variables: {
          id: estateId,
          values: {
            archived: true,
          },
        },
        onCompleted: () => {
          toast({
            description: t('EstatesTableActionDropdown.toast.archivedDesc'),
            variant: 'success',
          })
        },
        onError: (error) => {
          toast({
            description: error.message,
            variant: 'destructive',
          })
        },
      })
    }
  }

  return (
    <ConfirmationDialog
      open={!!estateId}
      onOpenChange={() => setEstateId(null)}
      onConfirm={handleArchive}
      description={<EstateConfirmDialogContent estate={data.estate_by_pk} />}
    />
  )
}
