import { CardFooter } from '@/components/ui/card'
import { Application_Status_Enum, EstateFragment } from '@gql'
import React from 'react'
import { useTranslation } from 'react-i18next'

type EstatesCardListFooterProps = {
  estate: EstateFragment
}
export const EstatesCardListFooter = ({
  estate,
}: EstatesCardListFooterProps) => {
  const { t } = useTranslation()
  const { applications } = estate

  const plannedVisitsLength = applications?.filter(
    (application) => application.status === Application_Status_Enum.VisitPlanned
  ).length

  const doneVisitsLength = applications?.filter(
    (application) =>
      application.status === Application_Status_Enum.VisitDone &&
      application.roundNumber === 0
  ).length

  const inProgressApplicationsLength = applications?.filter(
    (application) =>
      application.status === Application_Status_Enum.VisitDone &&
      application.roundNumber > 0
  ).length

  const receivedApplicationsLength = applications?.filter(
    (application) =>
      application.status === Application_Status_Enum.OfferSubmitted
  ).length

  return (
    <CardFooter className="tw-flex tw-justify-between tw-items-center tw-gap-4">
      <div className="tw-font-medium tw-text-xs tw-flex tw-flex-wrap tw-max-w-16 tw-space-y-1">
        <p>
          {t('EstatesCardList.footer.visitsPlanned', {
            count: plannedVisitsLength,
          })}
        </p>
      </div>
      <div className="tw-font-medium tw-text-xs tw-flex tw-flex-wrap tw-max-w-16 tw-space-y-1">
        <p>
          {t('EstatesCardList.footer.visitsDone', {
            count: doneVisitsLength,
          })}
        </p>
      </div>
      <div className="tw-font-medium tw-text-xs tw-flex tw-flex-wrap tw-max-w-16 tw-space-y-1">
        <p>
          {t('EstatesCardList.footer.offersPrepared', {
            count: inProgressApplicationsLength,
          })}
        </p>
      </div>
      <div className="tw-font-medium tw-text-xs tw-flex tw-flex-wrap tw-max-w-16 tw-space-y-1">
        <p>
          {t('EstatesCardList.footer.offersReceived', {
            count: receivedApplicationsLength,
          })}
        </p>
      </div>
    </CardFooter>
  )
}
