import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, NavLinkProps } from 'react-router-dom'

export const AdminNavbarMenu = () => {
  const { t } = useTranslation()

  return (
    <nav className="tw-flex tw-items-center tw-space-x-8 lg:tw-space-x-8">
      <AdminNavbarMenuItem to={`/`}>
        {t('AdminNavbarMenu.estates')}
      </AdminNavbarMenuItem>
      <AdminNavbarMenuItem to={`sellers`}>
        {t('AdminNavbarMenu.sellers')}
      </AdminNavbarMenuItem>
    </nav>
  )
}

type AdminNavbarMenuItemProps = NavLinkProps

const AdminNavbarMenuItem = ({
  children,
  ...props
}: AdminNavbarMenuItemProps) => {
  return (
    <NavLink
      {...props}
      className="tw-text-sm tw-text-zinc-400 tw-font-medium tw-transition-colors hover:tw-text-primary aria-[current=page]:tw-text-primary"
    >
      {children}
    </NavLink>
  )
}
