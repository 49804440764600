import { differenceInMinutes } from 'date-fns'

export default function getTotalTimeSlots(
  spans: { startTime?: Date; endTime?: Date }[]
) {
  return spans.reduce((total, span) => {
    if (!span.startTime || !span.endTime) return total
    return total + calculateTimeSlots(span.startTime, span.endTime)
  }, 0)
}

function calculateTimeSlots(start: Date, end: Date) {
  if (!start || !end) return 0
  const diff = differenceInMinutes(end, start)
  return diff > 0 ? Math.floor(diff / 30) : 0
}
