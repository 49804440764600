import React from 'react'
import { useTranslation } from 'react-i18next'

export enum FileSizeUnit {
  BYTES = 'BYTES',
  KB = 'KB',
  MB = 'MB',
  GB = 'GB',
  TB = 'TB',
  PB = 'PB',
  EB = 'EB',
  ZB = 'ZB',
  YB = 'YB',
}

const kilo = 1024

export type FileSizeProps = {
  size: number
  unit?: FileSizeUnit
}
export const FileSize = ({
  size,
  unit = FileSizeUnit.BYTES,
}: FileSizeProps) => {
  const { t } = useTranslation()
  let unitIndex = Object.values(FileSizeUnit).indexOf(unit)
  while (size > kilo && unitIndex < Object.values(FileSizeUnit).length - 1) {
    size /= kilo
    unitIndex++
  }
  const newFileSizeUnit = Object.values(FileSizeUnit)[unitIndex]
  const formattedFileSize = size.toFixed(2)
  switch (newFileSizeUnit) {
    case FileSizeUnit.BYTES:
      return <>{t('common.fileSize.BYTES', { size: formattedFileSize })}</>
    case FileSizeUnit.KB:
      return <>{t('common.fileSize.KB', { size: formattedFileSize })}</>
    case FileSizeUnit.MB:
      return <>{t('common.fileSize.MB', { size: formattedFileSize })}</>
    case FileSizeUnit.GB:
      return <>{t('common.fileSize.GB', { size: formattedFileSize })}</>
    case FileSizeUnit.TB:
      return <>{t('common.fileSize.TB', { size: formattedFileSize })}</>
    case FileSizeUnit.PB:
      return <>{t('common.fileSize.PB', { size: formattedFileSize })}</>
    case FileSizeUnit.EB:
      return <>{t('common.fileSize.EB', { size: formattedFileSize })}</>
    case FileSizeUnit.ZB:
      return <>{t('common.fileSize.ZB', { size: formattedFileSize })}</>
    case FileSizeUnit.YB:
      return <>{t('common.fileSize.YB', { size: formattedFileSize })}</>
  }
}
