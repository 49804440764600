import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Title } from '@/common/atoms/Title'
import { useTranslation } from 'react-i18next'
import { SellerInfoCard } from '../components/SellerInfoCard'
import { MembersList } from '@/sellerMember/components/MembersList'
import useSeller from '../hooks/useSeller'

export const SellerPage = () => {
  const { t } = useTranslation()
  const { sellerId } = useParams<{ sellerId: string }>()
  const seller = useSeller(sellerId)

  return (
    <div className="tw-p-8 tw-flex tw-flex-col tw-w-full tw-items-center">
      <Title>{t('SellerPage.title', { seller: seller?.name })}</Title>
      <h1 className="tw-text-[40px] tw-font-semibold tw-mb-4">
        {seller?.name}
      </h1>

      <Tabs defaultValue="sellers" className="tw-w-[600px]">
        <TabsList className="tw-grid tw-w-full tw-grid-cols-2">
          <TabsTrigger value="sellers">
            {t('SellerPage.tabs.seller')}
          </TabsTrigger>
          <TabsTrigger value="members">
            {t('SellerPage.tabs.members')}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="sellers">
          {seller && <SellerInfoCard seller={seller} />}
        </TabsContent>
        <TabsContent value="members">
          <MembersList />
        </TabsContent>
      </Tabs>
    </div>
  )
}
