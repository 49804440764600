import React from 'react'
import TextError from '@/common/atoms/TextError'
import { useParams } from 'react-router-dom'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useTranslation } from 'react-i18next'
import { EstateMediasPhotosTab } from '../components/EstateMedias/EstateMediasPhotosTab'
import { EstateMediasVirtualVisitTab } from '../components/EstateMedias/EstateMediasVirtualVisitTab'
import { EstateMediasVideosTab } from '../components/EstateMedias/EstateMediasVideosTab'
import useCurrentEstate from '../hooks/useCurrentEstate'

export const EstateMediasPage = () => {
  const { t } = useTranslation()
  const { estateId } = useParams<{ estateId: string }>()

  const estate = useCurrentEstate()
  if (!estate) return <TextError error={new Error('Estate not found')} />

  return (
    <div className="tw-py-6 tw-px-8">
      <h1 className="tw-text-[40px] tw-font-semibold">
        {t('EstateMediasPage.title')}
      </h1>
      <Tabs defaultValue="photos" className="tw-w-[650px]">
        <TabsList>
          <TabsTrigger value="photos">
            {t('EstateMediasPage.photos')}
          </TabsTrigger>
          <TabsTrigger value="virtualVisit">
            {t('EstateMediasPage.virtualVisit')}
          </TabsTrigger>
          <TabsTrigger value="video">{t('EstateMediasPage.video')}</TabsTrigger>
        </TabsList>
        <TabsContent value="photos">
          {estateId && <EstateMediasPhotosTab estate={estate} />}
        </TabsContent>
        <TabsContent value="virtualVisit">
          {estateId && <EstateMediasVirtualVisitTab estate={estate} />}
        </TabsContent>
        <TabsContent value="video">
          {estateId && <EstateMediasVideosTab estate={estate} />}
        </TabsContent>
      </Tabs>
    </div>
  )
}
