import React from 'react'
import { Outlet } from 'react-router-dom'
import { EstateSidebar } from '../estate/components/EstateSidebar'
import { ScrollArea } from '@/components/ui/scroll-area'

export const EstateLayout = () => {
  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-grid tw-w-full lg:tw-grid-cols-[280px_1fr]">
        <EstateSidebar />
        <main>
          <ScrollArea className="tw-h-[calc(100vh_-_80px)]">
            <Outlet />
          </ScrollArea>
        </main>
      </div>
    </div>
  )
}
