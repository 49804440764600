import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { InputMultipleDatePicker } from '@/components/ui/InputMultipleDatePicker'
import { Separator } from '@/components/ui/separator'
import { EstateSettingVisitSpanNestedFieldArray } from './EstateSettingVisitSpanNestedFieldArray'
import {
  EstateFragment,
  useUpsertVisitSpanMutation,
  Visit_Span_Insert_Input,
  Visit_Span_Updates,
} from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { Trash2Icon } from 'lucide-react'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useToast } from '@/components/ui/use-toast'
import getTotalTimeSlots from '@utils/getTotalTimeSlots'

const resolver = (t: TFunction) =>
  yupResolver(
    yup.object().shape({
      visitSpans: yup.array().of(
        yup.object().shape({
          visitId: yup.string().optional(),
          date: yup.date().optional(),
          span: yup.array().of(
            yup.object().shape({
              startTime: yup.date().optional(),
              endTime: yup.date().optional(),
            })
          ),
        })
      ),
    })
  )

export type EstateSettingVisitSpanFormValues = {
  visitSpans: {
    visitId: string
    date: Date
    span: {
      startTime: Date | undefined
      endTime: Date | undefined
    }[]
  }[]
}

type EstateSettingVisitSpanFormProps = {
  estate: EstateFragment
  readOnly?: boolean
}

export const EstateSettingVisitSpanForm = ({
  estate,
  readOnly,
}: EstateSettingVisitSpanFormProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const [upsertVisit] = useUpsertVisitSpanMutation()

  const defaultValues = useMemo(() => {
    return {
      visitSpans: estate.visit_spans?.map((visitSpan) => ({
        visitId: visitSpan.id, // add real id to bypass useFieldArray ids
        date: visitSpan.span?.[0]?.startTime // add date to match selected dates array
          ? new Date(visitSpan.span?.[0]?.startTime)
          : new Date(),
        span:
          visitSpan.span?.map((span: { startTime: Date; endTime: Date }) => ({
            startTime: span.startTime ? new Date(span.startTime) : undefined,
            endTime: span.endTime ? new Date(span.endTime) : undefined,
          })) ?? [],
      })),
    }
  }, [estate])

  const form = useForm<EstateSettingVisitSpanFormValues>({
    resolver: resolver(t),
    defaultValues,
  })

  useEffect(() => {
    form.reset(defaultValues)
  }, [defaultValues])

  const [selectedDates, setSelectedDates] = useState<Date[]>(
    defaultValues.visitSpans.map((visitSpan) => new Date(visitSpan.date)) || []
  )

  const handleDateSelect = (dates?: Date[]) => {
    if (!dates) return
    setSelectedDates(dates)
  }

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'visitSpans',
  })

  useEffect(() => {
    //default values for startTime and endTime : 10h30 and 18h30
    selectedDates.forEach((date) => {
      if (
        !fields.some(
          (field) => new Date(field.date).toDateString() === date.toDateString()
        )
      ) {
        append({
          visitId: '',
          date,
          span: [
            {
              startTime: new Date(date.setHours(10, 30, 0, 0)),
              endTime: new Date(date.setHours(18, 30, 0, 0)),
            },
          ],
        })
      }
    })
  }, [selectedDates])

  const handleRemoveDate = (indexToRemove: number) => {
    const dateToRemove = fields[indexToRemove].date
    setSelectedDates((prevDates) =>
      prevDates.filter(
        (date) => date.toDateString() !== new Date(dateToRemove).toDateString()
      )
    )
    remove(indexToRemove)
  }

  const hasDirtyFields = Object.keys(form.formState.dirtyFields).length > 0

  const onSubmit = async (values: EstateSettingVisitSpanFormValues) => {
    if (!values.visitSpans) return

    let visitToCreate: Visit_Span_Insert_Input[] = []
    let visitToUpdate: Visit_Span_Updates[] = []

    const visitIdsToDelete: string[] = defaultValues.visitSpans
      .filter(
        (visitSpan) =>
          !values.visitSpans.some(
            (newVisitSpan) => newVisitSpan.visitId === visitSpan.visitId
          )
      )
      .map((visitSpan) => visitSpan.visitId)

    values.visitSpans.forEach((visitSpan) => {
      if (visitSpan.visitId) {
        visitToUpdate.push({
          _set: {
            span: visitSpan.span,
          },
          where: {
            id: {
              _eq: visitSpan.visitId,
            },
          },
        })
      } else {
        visitToCreate.push({
          estateId: estate.id,
          span: visitSpan.span,
        })
      }
    })

    await upsertVisit({
      variables: {
        valuesToCreate: visitToCreate,
        valuesToUpdate: visitToUpdate,
        idsToDelete: visitIdsToDelete,
      },
      onCompleted: () => {
        toast({
          description: t('EstateSettingVisitSpanForm.toast.success'),
          variant: 'success',
        })
      },
      onError: (error) => {
        toast({
          description: error.message,
          variant: 'destructive',
        })
      },
    })
  }

  const fromStartDate = useMemo(() => {
    return estate.saleData.firstRound.startDate
      ? new Date(estate.saleData.firstRound.startDate)
      : new Date()
  }, [])

  const toEndDate = useMemo(() => {
    return estate.saleData.firstRound.endDate
      ? new Date(estate.saleData.firstRound.endDate)
      : new Date()
  }, [])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="tw-grid tw-pr-3">
          {!readOnly && (
            <div className="tw-flex tw-mt-3 tw-gap-10 tw-justify-between">
              <InputMultipleDatePicker
                placeholder={t(
                  'EstateSettingVisitSpanForm.datePicker.placeholder'
                )}
                value={selectedDates}
                onChange={handleDateSelect}
                fromDate={fromStartDate}
                toDate={toEndDate}
              />
            </div>
          )}
          {fields.map((field, index) => {
            return (
              <React.Fragment key={field.id}>
                <div className="tw-flex tw-justify-between tw-items-center tw-mt-3">
                  <div className="tw-flex tw-space-x-4 tw-items-center">
                    <p className="tw-font-medium tw-text-sm tw-capitalize">
                      {format(field.date, 'EEEE d MMMM yyyy', { locale: fr })}
                    </p>
                    <Controller
                      control={form.control}
                      name={`visitSpans.${index}.date`}
                      disabled={readOnly}
                      render={({ field }) => (
                        <input
                          type="hidden"
                          value={field.value.toISOString()}
                        />
                      )}
                    />
                    {!readOnly && (
                      <Button
                        variant="ghost"
                        size="icon"
                        type="button"
                        className="tw-w-7 tw-h-7"
                        onClick={() => handleRemoveDate(index)}
                      >
                        <Trash2Icon className="tw-w-4 tw-h-4 tw-stroke-zinc-500" />
                      </Button>
                    )}
                  </div>
                  <p className="tw-text-xs tw-font-normal tw-text-zinc-500">
                    {form.watch('visitSpans')[index] &&
                      form.watch('visitSpans')[index].span.length &&
                      t('EstateSettingVisitSpanForm.spanLength', {
                        count: getTotalTimeSlots(
                          form.watch('visitSpans')[index].span
                        ),
                      })}
                  </p>
                </div>
                <EstateSettingVisitSpanNestedFieldArray
                  nestId={index}
                  currentDate={field.date}
                  readOnly={readOnly}
                  {...{ control: form.control }}
                />
                {index < fields.length - 1 && <Separator className="tw-mt-3" />}
              </React.Fragment>
            )
          })}
          {!readOnly && (
            <Button
              type="submit"
              variant="secondary"
              className="tw-justify-self-end tw-w-fit tw-mt-3"
              disabled={!hasDirtyFields}
            >
              {t('EstateSettingVisitSpanForm.submit')}
            </Button>
          )}
        </div>
      </form>
    </Form>
  )
}
