import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { cn } from '@/lib/utils'

import { Mandate_Fee_Person_Enum } from '@gql'
import React, { forwardRef } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type MandateFeePersonSelectProps = {
  field: ControllerRenderProps<any>
  className?: string
  disabled?: boolean
}
export const MandateFeePersonSelect = forwardRef<
  HTMLInputElement,
  MandateFeePersonSelectProps
>(({ field, className, disabled }, ref) => {
  const { t } = useTranslation()

  return (
    <FormItem className="tw-space-y-3">
      <FormLabel>{t('MandateFeePersonSelect.label')}</FormLabel>
      <FormControl>
        <RadioGroup
          disabled={disabled}
          onValueChange={field.onChange}
          value={field.value}
          className={cn('tw-flex tw-flex-col tw-space-y-1', className)}
          ref={ref}
        >
          {Object.values(Mandate_Fee_Person_Enum).map((type) => (
            <FormItem
              key={type}
              className="tw-flex tw-items-center tw-space-x-3 "
            >
              <FormControl>
                <RadioGroupItem value={type} />
              </FormControl>
              <FormLabel className="tw-font-normal tw-pb-2">
                {t(`MandateFeePersonSelect.${type}`)}
              </FormLabel>
            </FormItem>
          ))}
        </RadioGroup>
      </FormControl>
      <FormMessage />
    </FormItem>
  )
})

MandateFeePersonSelect.displayName = 'MandateFeePersonSelect'
