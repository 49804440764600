import React from 'react'
import TextError from '@/common/atoms/TextError'
import { EstateSellerForm } from '../components/Estate/EstateSellerForm'
import useCurrentEstate from '../hooks/useCurrentEstate'
import { Card } from '@/components/ui/card'
import { useTranslation } from 'react-i18next'

export const EstateSellersPage = () => {
  const { t } = useTranslation()
  const estate = useCurrentEstate()
  if (!estate) return <TextError error={new Error('Estate not found')} />

  return (
    <div className="tw-p-8 tw-flex tw-flex-col tw-gap-4 tw-w-[650px]">
      <h1 className="tw-text-[40px] tw-font-semibold">
        {t('EstateSellers.title')}
      </h1>
      <Card className="tw-p-4">
        <EstateSellerForm estate={estate} />
      </Card>
    </div>
  )
}
