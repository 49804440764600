import React, { forwardRef } from 'react'
import type { ForwardedRef } from 'react'
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

type EnumType = { [key: string]: string }

export type EnumSelectFormItemProps<T> = {
  onChange: (value: T) => void
  value?: string
  enumList: EnumType
  label: string
  placeholder: string
  getEnumValueLabel: (item: T) => string
}

export type EnumSelectFormItemWrapperProps<T> = Omit<
  EnumSelectFormItemProps<T>,
  'enumList' | 'label' | 'placeholder' | 'getEnumValueLabel'
> &
  Partial<
    Pick<
      EnumSelectFormItemProps<T>,
      'label' | 'placeholder' | 'getEnumValueLabel'
    >
  > & {
    displayName?: string
  }

const EnumSelectFormItemInner = <T,>(
  {
    onChange,
    value,
    enumList,
    label,
    placeholder,
    getEnumValueLabel: getItemLabel,
  }: EnumSelectFormItemProps<T>,
  ref: ForwardedRef<HTMLSpanElement>
) => {
  const handleValueChange = (value: string) => {
    onChange(value as T)
  }

  return (
    <FormItem>
      <FormLabel>{label}</FormLabel>
      <Select onValueChange={handleValueChange} value={value}>
        <FormControl>
          <SelectTrigger className="tw-w-full">
            <SelectValue ref={ref} placeholder={placeholder} />
          </SelectTrigger>
        </FormControl>
        <SelectContent>
          {Object.values(enumList).map((enumValue) => (
            <SelectItem value={enumValue} key={enumValue}>
              {getItemLabel(enumValue as T)}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <FormMessage />
    </FormItem>
  )
}

export const EnumSelectFormItem = forwardRef(EnumSelectFormItemInner) as <T>(
  props: EnumSelectFormItemProps<T> & { ref?: ForwardedRef<HTMLSpanElement> }
) => ReturnType<typeof EnumSelectFormItemInner>
