import React, { forwardRef, useEffect } from 'react'
import { uploadFile } from '../api/uploads'
import { Input, InputProps } from '@/components/ui/input'
import { nhost } from 'src/nhost'

export type FileUploadInputProps = InputProps & {
  storageName?: string
  accept?: string
  value?: string // value is the file ID stored in the database
  onChange?: (value: string | null) => void
}

export const FileUploadInput = forwardRef<
  HTMLInputElement,
  FileUploadInputProps
>(({ storageName, accept, value, onChange, ...props }, ref) => {
  const [preview, setPreview] = React.useState<string | undefined>(undefined)

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file || !storageName) return

    const { id } = await uploadFile({ file, name: storageName })
    onChange?.(id)
  }

  useEffect(() => {
    if (value) {
      // TODO maybe replace by nhost.storage.getPresignedUrl
      const fileUrl = nhost.storage.getPublicUrl({
        fileId: value,
      })
      setPreview(fileUrl)
    }
  }, [value])

  return (
    <>
      <Input
        ref={ref}
        type="file"
        onChange={handleChange}
        accept={accept}
        {...props}
      />
      {preview && (
        <img
          src={preview}
          alt="Preview"
          className="tw-h-44 tw-w-44 tw-object-cover tw-rounded-lg tw-mt-4"
        />
      )}
    </>
  )
})

FileUploadInput.displayName = 'FileUploadInput'
