import { SellerMemberFragment, SellerFragment, SellerFullFragment } from '@gql'
import { omit } from '@utils/omit'
import { Action, action } from 'easy-peasy'

interface SellerModel {
  currentId: string | undefined
  current: SellerFragment | undefined
  members: SellerMemberFragment[] | undefined
  loading: boolean
  error: Error | undefined
  // Set Id instantly from URL params
  setCurrentId: Action<SellerModel, string | undefined>
  // Set current after loading
  setSubscriptionResult: Action<
    SellerModel,
    {
      result: SellerFullFragment | undefined
      loading: boolean
      error: Error | undefined
    }
  >
}

const model: SellerModel = {
  currentId: undefined,
  current: undefined,
  members: undefined,
  loading: false,
  error: undefined,

  setCurrentId: action((state, id) => {
    if (state.currentId !== id) {
      state.currentId = id
      state.members = undefined
    }
  }),

  setSubscriptionResult: action((state, { result, loading, error }) => {
    if (result) {
      state.current = omit(result, 'seller_members')

      // Get and sort members
      state.members = [...result.seller_members].sort((a, b) =>
        a.name.localeCompare(b.name)
      )
    }
    state.loading = loading
    state.error = error
  }),
}

export default model
