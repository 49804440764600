import { Application_Status_Enum, EstateFragment } from '@gql'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EstateOfferAcceptedUpload } from './EstateOfferAcceptedUpload'

type EstateConfirmDialogContentProps = {
  estate?: EstateFragment
}

const EstateConfirmDialogContent = ({
  estate,
}: EstateConfirmDialogContentProps) => {
  const { t } = useTranslation()

  if (!estate) return null

  const isSaleOpen = useMemo(() => {
    return estate.saleData.saleOpen
  }, [estate.saleData])

  const isOfferAccepted = useMemo(() => {
    return estate.applications.some(
      (application) =>
        application.status === Application_Status_Enum.OfferAccepted
    )
  }, [estate.applications])

  return (
    <>
      {!isSaleOpen && isOfferAccepted ? (
        <EstateOfferAcceptedUpload estate={estate} />
      ) : (
        <div>
          <p>{t('EstateConfirmDialogContent.ongoing.description.title')}</p>
          <p>{t('EstateConfirmDialogContent.ongoing.description.content')}</p>
          <p>{t('EstateConfirmDialogContent.ongoing.description.confirm')}</p>
        </div>
      )}
    </>
  )
}

export default EstateConfirmDialogContent
