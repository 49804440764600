import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { emailSchema, passwordSchema } from '@almaris/shared/schemas'
import { SellerMemberFragment, useUpdateSellerMemberMutation } from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { nhost } from 'src/nhost'
import { TFunction } from 'react-i18next'
import * as yup from 'yup'
import { Input } from '@/components/ui/input'
import PasswordInput from '@/common/atoms/PasswordInput'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'

export interface MemberSigninFormValues {
  email: string
  ['new-password']: string
  ['confirm-password']: string
  phone: string
  job: string
}

const resolver = (t: TFunction) =>
  yupResolver(
    yup.object().shape({
      email: emailSchema.required(),
      ['new-password']: passwordSchema(
        t('common.schema.password.rule')
      ).required(),
      ['confirm-password']: passwordSchema(t('common.schema.password.rule'))
        .oneOf(
          [yup.ref('new-password'), undefined],
          t('common.schema.password.match')
        )
        .required(),
      phone: yup.string().required(),
      job: yup.string(),
    })
  )

type MemberSigninFormProps = {
  member?: SellerMemberFragment
  onSuccess?: () => void
}

export const MemberSigninForm = ({
  member,
  onSuccess,
}: MemberSigninFormProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const [updateMember] = useUpdateSellerMemberMutation()

  const form = useForm<MemberSigninFormValues>({
    resolver: resolver(t),
    defaultValues: {
      email: member?.inviteEmail ?? '',
      ['new-password']: '',
      ['confirm-password']: '',
      phone: member?.phone ?? '',
      job: member?.job ?? '',
    },
  })

  const onSubmit = async ({
    'new-password': password,
    'confirm-password': confirm,
    job,
    phone,
  }: MemberSigninFormValues) => {
    // Update member
    if (member?.id)
      await updateMember({
        variables: {
          id: member?.id,
          values: {
            phone,
            job,
          },
        },
        onCompleted: () => {
          toast({
            variant: 'success',
            description: t('MemberSigninForm.toastSuccess'),
          })
        },
        onError: () => {
          toast({
            variant: 'destructive',
            description: t('MemberSigninForm.toastError'),
          })
        },
      })
    // Set new password to authenticated user
    if (password && confirm) {
      const result = await nhost.auth.changePassword({ newPassword: password })

      if (result.error) {
        toast({
          variant: 'destructive',
          title: 'Erreur',
          description: result.error.message,
        })
        return
      } else {
        onSuccess?.()
      }
      // Refresh user data
      await nhost.auth.refreshSession()
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="tw-space-y-5">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('MemberSigninForm.email')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('MemberSigninForm.emailPlaceholder')}
                  disabled={!!member?.inviteEmail}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="new-password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('MemberSigninForm.password')}</FormLabel>
              <FormControl>
                <PasswordInput
                  id="new-password"
                  required
                  autoComplete="new-password"
                  {...field}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="confirm-password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('MemberSigninForm.confirm')}</FormLabel>
              <FormControl>
                <PasswordInput
                  id="confirm-password"
                  required
                  autoComplete="confirm-password"
                  {...field}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('MemberSigninForm.phone')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('MemberSigninForm.phonePlaceholder')}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="job"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('MemberSigninForm.job')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('MemberSigninForm.jobPlaceholder')}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" variant="green">
          {t('common.save')}
        </Button>
      </form>
    </Form>
  )
}
