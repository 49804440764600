'use client'
import React, { ForwardedRef, forwardRef, useState } from 'react'
import { Button } from '@/components/ui/button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { format, Locale } from 'date-fns'
import { CalendarDaysIcon } from 'lucide-react'
import { Input } from '@/components/ui/input'
import { fr } from 'date-fns/locale'
import { DayPickerSingleProps } from 'react-day-picker'
import { DatePicker } from './datePicker'
import { useTranslation } from 'react-i18next'

interface InputDatePickerProps
  extends Omit<
    DayPickerSingleProps,
    'value' | 'disabled' | 'onSelect' | 'mode'
  > {
  placeholder?: string
  locale?: Locale
  disabled?: boolean
  value?: Date
  onChange?: (value?: Date) => void
  disabledDates?: Date[]
}

const formatDate = (locale: Locale, date?: Date | string) => {
  if (!date) return ''
  if (typeof date === 'string') {
    if (date === 'Invalid Date') return ''
    if (date === 'NaN') return ''
    if (date.length === 0) return ''
    return format(new Date(date), 'PPP', { locale })
  }
  try {
    return format(date, 'PPP', { locale })
  } catch (error) {
    return ''
  }
}

const InputDatePickerInner = (
  {
    placeholder,
    disabled,
    locale = fr,
    value,
    onChange,
    disabledDates,
    ...props
  }: InputDatePickerProps,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const { t } = useTranslation()
  const [stringDate, setStringDate] = useState(
    value
      ? formatDate(locale, value)
      : placeholder ?? t('InputDatePicker.placeholder')
  )

  const [errorMessage, setErrorMessage] = useState<string>()

  const handleSelect = (selectedDate: Date | undefined) => {
    if (!selectedDate) return
    onChange?.(selectedDate)
    setStringDate(formatDate(locale, selectedDate))
    setErrorMessage(undefined)
  }

  return (
    <Popover>
      <div className="tw-relative tw-max-w-[280px]">
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            disabled={disabled}
            variant="outline"
            type="button"
            className={cn(
              'tw-font-normal tw-absolute tw-right-0 tw-translate-y-[-50%] tw-top-[50%] tw-rounded-l-none',
              !value && 'tw-text-muted-foreground'
            )}
          >
            <CalendarDaysIcon className="tw-w-4 tw-h-4" />
          </Button>
        </PopoverTrigger>
        <Input
          type="string"
          value={stringDate}
          readOnly
          onFocus={() => {
            if (value) setStringDate(formatDate(locale, value))
          }}
          onChange={(e) => {
            if (value) setStringDate('')
            setStringDate(e.target.value)
          }}
        />
        {errorMessage && (
          <div className="tw-absolute tw-bottom-[-1.75rem] tw-left-0 tw-text-red-400 tw-text-sm">
            {errorMessage}
          </div>
        )}
      </div>
      <PopoverContent align="end" className="tw-w-auto tw-p-0">
        <DatePicker
          locale={locale}
          mode="single"
          captionLayout="dropdown-buttons"
          selected={value}
          defaultMonth={value}
          disabled={disabledDates}
          onSelect={handleSelect}
          toYear={props.toYear ?? undefined}
          {...props}
        />
      </PopoverContent>
    </Popover>
  )
}

export const InputDatePicker = forwardRef(InputDatePickerInner)
