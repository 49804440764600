import React from 'react'
import { Form, FormField } from '@/components/ui/form'
import { Estate_Type_Enum, EstateFragment } from '@gql'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { EstateTypeSelect } from './EstateTypeSelect'

const schema = yup.object().shape({
  type: yup.mixed<Estate_Type_Enum>().oneOf(Object.values(Estate_Type_Enum)),
})

const resolver = yupResolver(schema)

export type FormValues = yup.InferType<typeof schema>

export type EstateTypeFormProps = {
  estate: EstateFragment
  onSubmit: (values: Partial<EstateFragment>) => void
}
export const EstateTypeForm = ({ estate, onSubmit }: EstateTypeFormProps) => {
  const defaultValues = {
    type: estate.type ?? Estate_Type_Enum.House,
  }

  const form = useForm<FormValues>({
    resolver,
    defaultValues,
  })

  form.watch(() => {
    form.handleSubmit(({ type }) => onSubmit({ type }))()
  })

  return (
    <Form {...form}>
      <FormField
        control={form.control}
        name="type"
        render={({ field }) => (
          <EstateTypeSelect field={field} className="tw-mb-4" />
        )}
      />
    </Form>
  )
}
