import * as React from 'react'

import { cn } from '@/lib/utils'

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'tw-rounded-xl tw-border tw-bg-card tw-text-card-foreground tw-shadow-sm',
      className
    )}
    {...props}
  />
))
Card.displayName = 'Card'

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('tw-flex tw-flex-col tw-space-y-1.5 tw-p-6', className)}
    {...props}
  />
))
CardHeader.displayName = 'CardHeader'

interface CardImageProps extends React.HTMLAttributes<HTMLDivElement> {
  src: string | undefined
  width?: number | string
  height?: number | string
  alt?: string
  ref: React.LegacyRef<HTMLImageElement>
  borderRadius?: number | string
}
const CardImage = React.forwardRef<HTMLDivElement, CardImageProps>(
  ({ className, src, width, height, borderRadius, alt, ...props }, ref) => (
    <img
      src={src}
      style={{ width: width, height: height, borderRadius: borderRadius }}
      alt={alt}
      className={cn('tw-flex tw-flex-col  tw-rounded-md tw-w-full', className)}
      {...props}
    />
  )
)
CardImage.displayName = 'CardImage'

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      'tw-text-2xl tw-font-semibold tw-leading-none tw-tracking-tight',
      className
    )}
    {...props}
  />
))
CardTitle.displayName = 'CardTitle'

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn('tw-text-sm tw-text-muted-foreground', className)}
    {...props}
  />
))
CardDescription.displayName = 'CardDescription'

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('tw-p-6 tw-pt-0', className)} {...props} />
))
CardContent.displayName = 'CardContent'

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('tw-flex tw-items-center tw-p-6 tw-pt-0', className)}
    {...props}
  />
))
CardFooter.displayName = 'CardFooter'

export {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
  CardImage,
}
