import React from 'react'
import { Button } from '@/components/ui/button'
import { ColumnDef } from '@tanstack/react-table'
import { ChevronsUpDownIcon } from 'lucide-react'

import { Link } from 'react-router-dom'
import { EstatesTableActionDropdown } from './EstatesTableActionDropdown'
import { TFunction } from 'react-i18next'
import {
  Application_Status_Enum,
  Estate_Media_Type_Group_Enum,
  EstateFragment,
  SellerFragment,
} from '@gql'
import { format } from 'date-fns'
import fr from 'date-fns/locale/fr'
import { useGetPresignedUrl } from '@/file/hooks/useGetPresignedUrl'
import { Skeleton } from '@/components/ui/skeleton'
import HouseSvg from 'src/images/house.svg'
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { EstateStatusBadge } from '../EstateStatusBadge/EstateStatusBadge'

const EstateMediaEmptyState = () => {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-h-10 tw-w-10 tw-rounded-full tw-text-zinc-500 tw-bg-zinc-100">
      <HouseSvg className=" tw-h-6 tw-w-6" />
    </div>
  )
}

const EstateMediaPreview = ({ fileId }: { fileId: string }) => {
  const { url, loading } = useGetPresignedUrl({
    fileId: fileId,
  })

  return (
    <div className="tw-h-10 tw-w-10 tw-rounded-full tw-overflow-hidden">
      {loading ? (
        <Skeleton className="tw-h-10 tw-w-10 " />
      ) : (
        <img src={url ?? ''} alt="Estate picture" className="tw-size-full" />
      )}
    </div>
  )
}
export const columns = (t: TFunction): ColumnDef<EstateFragment, any>[] => [
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('EstatesTableColumns.name')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const estate = row.original
      const fileId =
        estate.estate_media.find(
          (media) => media.type.group === Estate_Media_Type_Group_Enum.Media
        )?.file.id ?? null

      return (
        <Link
          key={estate.id}
          to={`estates/${estate.id}`}
          className="tw-font-semibold"
        >
          <div className="tw-flex tw-items-center tw-space-x-4">
            {fileId ? (
              <EstateMediaPreview fileId={fileId} />
            ) : (
              <EstateMediaEmptyState />
            )}
            <div>
              <p className="tw-text-sm tw-font-medium">{estate.name}</p>
              <p className="tw-text-sm tw-text-muted-foreground">
                {estate.address} {estate.zipCode} {estate.city}
              </p>
            </div>
          </div>
        </Link>
      )
    },
    enableGrouping: true,
    getGroupingValue: (row) => `${row.address}, ${row.zipCode}, ${row.city}`,
  },
  {
    id: 'seller',
    accessorKey: 'seller',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('EstatesTableColumns.seller')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const info = row.original
      return <span>{info.seller.name}</span>
    },
    accessorFn: (row) => row.seller,
    sortingFn: (rowA, rowB) => {
      const sellerA = rowA.original.seller
      const sellerB = rowB.original.seller
      return sellerA.name.localeCompare(sellerB.name)
    },
    filterFn: (row, columnId, filterValue: string[]) => {
      const seller = row.getValue<SellerFragment>(columnId)
      return filterValue.includes(seller.id)
    },
    enableGrouping: true,
    getGroupingValue: ({ seller }) => `${seller.name}`,
  },
  {
    accessorKey: 'status',
    header: t('EstatesTableColumns.status'),
    cell: ({ row }) => {
      const estate = row.original

      return <EstateStatusBadge estate={estate} />
    },
  },
  {
    accessorKey: 'endOfSale',
    header: t('EstatesTableColumns.endOfSale'),
    cell: ({ row }) => {
      const estateSaleData = row.original.saleData
      const endOfSaleDate =
        estateSaleData.secondRound?.endDate || estateSaleData.firstRound.endDate

      if (!endOfSaleDate)
        return (
          <p className="tw-text-zinc-400">
            {t('EstatesTableColumns.noEndOfSale')}
          </p>
        )
      return (
        <div>
          <p>
            {format(new Date(endOfSaleDate), 'dd MMMM yyyy', {
              locale: fr,
            })}
          </p>
          <p className="tw-text-zinc-500">
            {format(new Date(endOfSaleDate), 'HH:mm')}
          </p>
        </div>
      )
    },
  },
  {
    accessorKey: 'price',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('EstatesTableColumns.price')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const estate = row.original
      if (!estate.startEstimate || !estate.endEstimate) return
      return (
        <span>
          {estate.startEstimate} € - {estate.endEstimate} €
        </span>
      )
    },
    sortingFn: (rowA, rowB) => {
      if (!rowA.original.endEstimate || !rowB.original.endEstimate) return 0
      const priceA: number = rowA.original.endEstimate
      const priceB: number = rowB.original.endEstimate

      return priceA - priceB
    },
  },

  {
    accessorKey: 'visits',
    header: t('EstatesTableColumns.visits'),
    cell: ({ row }) => {
      const estate = row.original
      const applications = estate.applications

      const plannedVisitsLength = applications?.filter(
        (application) =>
          application.status === Application_Status_Enum.VisitPlanned
      ).length

      const doneVisitsLength = applications?.filter(
        (application) =>
          application.status === Application_Status_Enum.VisitDone
      ).length

      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <p>
                {plannedVisitsLength}/{doneVisitsLength + plannedVisitsLength}
              </p>
            </TooltipTrigger>
            <TooltipContent>
              <TooltipArrow />
              <p>
                {t('EstatesTableColumns.plannedVisits', {
                  count: plannedVisitsLength,
                })}
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    },
  },
  {
    accessorKey: 'offers',
    header: t('EstatesTableColumns.offers'),
    cell: ({ row }) => {
      const estate = row.original
      const applications = estate.applications

      const receivedApplicationsLength = applications?.filter(
        (application) =>
          application.status === Application_Status_Enum.OfferSubmitted
      ).length

      const inProgressApplicationsLength = applications?.filter(
        (application) =>
          application.status === Application_Status_Enum.VisitDone &&
          application.roundNumber > 0
      ).length

      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <p>
                {receivedApplicationsLength}/
                {inProgressApplicationsLength + receivedApplicationsLength}
              </p>
            </TooltipTrigger>
            <TooltipContent>
              <TooltipArrow />
              <p>
                {t('EstatesTableColumns.receivedApplications', {
                  count: receivedApplicationsLength,
                })}
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    },
  },
  {
    accessorKey: 'mission_head',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('EstatesTableColumns.missionHead')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const info = row.original
      return <span>{info.mission_head?.name}</span>
    },
    enableGrouping: true,
    getGroupingValue: ({ mission_head }) => `${mission_head?.name}`,
  },
  {
    id: 'actions',
    enableGrouping: false,
    cell: ({ row }) => {
      const estate = row.original
      return <EstatesTableActionDropdown estate={estate} />
    },
  },
]
