import agencies from '@/agency/store/agencies'
import agency from '@/agency/store/agency'
import sellerMembers from '@/sellerMember/store/sellerMembers'
import sellers from '@/seller/store/sellers'
import seller from '@/seller/store/seller'
import { createStore } from 'easy-peasy'
import agencyMembers from '@/agencyMember/store/agencyMembers'
import estates from '@/estate/store/estates'
import estate from '@/estate/store/estate'

const model = {
  sellers,
  seller,
  sellerMembers,
  agencies,
  agency,
  agencyMembers,
  estates,
  estate,
}

export type StoreModel = typeof model

export const store = createStore(model)
