import { TableCell, TableRow } from '@/components/ui/table'
import React from 'react'
import { useTranslation } from 'react-i18next'

type TableRowEmptyProps = {
  length?: number
}

export const TableRowEmpty = ({ length }: TableRowEmptyProps) => {
  const { t } = useTranslation()
  return (
    <TableRow>
      <TableCell colSpan={length} className="tw-h-24 tw-text-center">
        {t('TableRowEmpty.noResults')}
      </TableCell>
    </TableRow>
  )
}
