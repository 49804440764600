import React from 'react'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { useTranslation } from 'react-i18next'
import { Input } from '@/components/ui/input'
import { EstateFragment } from '@gql'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup.object().shape({
  name: yup.string().optional(),
  address: yup.string().optional(),
  city: yup.string().optional(),
  zipCode: yup.string().optional(),
})

const resolver = yupResolver(schema)

export type FormValues = yup.InferType<typeof schema>

export type EstateIdentityAndAddressFormProps = {
  estate: EstateFragment
  onSubmit: (values: Partial<EstateFragment>) => void
}
export const EstateIdentityAndAddressForm = ({
  estate,
  onSubmit,
}: EstateIdentityAndAddressFormProps) => {
  const { t } = useTranslation()

  const defaultValues = {
    name: estate.name ?? '',
    address: estate.address ?? '',
    city: estate.city ?? '',
    zipCode: estate.zipCode ?? '',
  }

  const form = useForm<FormValues>({
    resolver,
    defaultValues,
  })

  form.watch(() => {
    form.handleSubmit((sellerData) => onSubmit({ sellerData }))()
  })

  return (
    <Form {...form}>
      <div className="tw-grid tw-grid-cols-2 tw-gap-4">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('EstateIdentityAndAddressForm.name')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="address"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('EstateIdentityAndAddressForm.address')} *
              </FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="city"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('EstateIdentityAndAddressForm.city')} *</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="zipCode"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('EstateIdentityAndAddressForm.zipCode')} *
              </FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </Form>
  )
}
