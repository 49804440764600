import { ApplicationsStarScoreRow } from '@/application/components/ApplicationsStarScoreRow'
import { ApplicationFragment } from '@gql'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ApplicationTags } from './ApplicationTags'

type ApplicationFooterProps = {
  application: ApplicationFragment | null
}

const ApplicationFooter = ({ application }: ApplicationFooterProps) => {
  const { t } = useTranslation()

  if (!application) return null

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <div className="tw-w-full tw-flex tw-flex-row tw-justify-between tw-items-center tw-gap-1">
        <div className="tw-flex tw-flex-col tw-gap-2">
          <p className="tw-text-sm tw-font-medium tw-text-zinc-900">
            {t('ApplicationSheet.offerScore')}
          </p>
          <ApplicationsStarScoreRow application={application} />
        </div>
        <ApplicationTags application={application} />
      </div>
    </div>
  )
}

export default ApplicationFooter
