import { Button } from '@/components/ui/button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import EmptyEstateSvg from 'src/images/empty-estate.svg'

export default function Page404() {
  const { t } = useTranslation()

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full tw-gap-8 tw-p-8">
      <EmptyEstateSvg />
      <h2 className="tw-w-[300px] tw-text-2xl tw-font-semibold tw-text-center">
        {t('Page404.title')}
      </h2>
      <Button asChild className="tw-bg-oxley-400">
        <Link to="/">{t('Page404.cta')}</Link>
      </Button>
    </div>
  )
}
