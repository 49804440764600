import { EnumSelect, EnumSelectProps } from '@/enum/EnumSelect'
import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

export enum Area_Demand_Level_Enum {
  Tight = 'Tight',
  VeryTight = 'VeryTight',
}

export type AreaDemandLevelSelectProps = Omit<
  EnumSelectProps,
  'entries' | 'label' | 'placeholder'
>
export const AreaDemandLevelSelect = forwardRef<
  HTMLInputElement,
  AreaDemandLevelSelectProps
>((props, ref) => {
  const { t } = useTranslation()
  const name = 'AreaDemandLevelSelect'

  const entries = Object.values(Area_Demand_Level_Enum).reduce(
    (acc, type) => {
      acc[type] = t(`${name}.${type}`)
      return acc
    },
    {} as Record<Area_Demand_Level_Enum, string>
  )

  return (
    <div>
      <EnumSelect
        {...props}
        entries={entries}
        label={t('AreaDemandLevelSelect.label')}
        placeholder={t('AreaDemandLevelSelect.placeholder')}
        ref={ref}
      />
    </div>
  )
})

AreaDemandLevelSelect.displayName = 'AreaDemandLevelSelect'
