import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MandateInfoForm } from '../components/MandateInfoForm'
import { MandateStatusCard } from '../components/MandateStatusCard'
import { MandateFragment, useMandateSubscription } from '@gql'
import useSellers from '@/seller/hooks/useSellers'
import { useParams } from 'react-router-dom'
import { useToast } from '@/components/ui/use-toast'
import debounce from 'lodash.debounce'
import { useCreateMandateMutation, useUpdateMandateMutation } from '@gql'
import useCurrentEstate from '@/estate/hooks/useCurrentEstate'
import { omit } from '@utils/omit'
import TextError from '@/common/atoms/TextError'
import Loading from '@/common/atoms/Loading'

export const MandatePage = () => {
  const { t } = useTranslation()
  const currentEstate = useCurrentEstate()
  const { sellers: allSellers } = useSellers()

  const { data, loading, error } = useMandateSubscription({
    variables: {
      id: currentEstate?.mandate?.id!,
    },
    skip: !currentEstate?.mandate?.id,
  })
  const mandate = data?.mandate_by_pk

  const sellers = useMemo(() => {
    if (!currentEstate) return []
    return (
      allSellers?.filter(
        (s) => !s.archived && s.id !== currentEstate.seller.id
      ) || []
    )
  }, [currentEstate, allSellers])

  const { toast } = useToast()
  const { estateId } = useParams<{ estateId: string }>()
  const [createMandate] = useCreateMandateMutation()
  const [updateMandate] = useUpdateMandateMutation()

  const onCompleted = () => {
    toast({
      description: t('MandateInfoForm.toast.success'),
      variant: 'success',
    })
  }

  const onError = (error: Error) => {
    console.error(error.message)
    toast({
      description: t('MandateInfoForm.toast.error'),
      variant: 'destructive',
    })
  }

  const debouncedUpdateMandate = debounce(
    (mandateId: MandateFragment['id'], values: Partial<MandateFragment>) =>
      updateMandate({
        variables: {
          id: mandateId!,
          values,
        },
        onCompleted,
        onError,
      }),
    500
  )

  const debouncedCreateMandate = debounce(
    (values: Partial<MandateFragment>) =>
      createMandate({
        variables: {
          values: {
            ...omit(values, 'estate', 'mandate_media'),
            estateId,
          },
        },
        onCompleted,
        onError,
      }),
    500
  )

  const handleSubmit = (
    values: Partial<MandateFragment>,
    mandateId?: MandateFragment['id']
  ) => {
    if (mandateId) {
      debouncedUpdateMandate(mandateId, values)
    } else {
      debouncedCreateMandate(values)
    }
  }

  if (loading) return <Loading active center />
  if (error) return <TextError error={error} />

  return (
    <div className="tw-py-6 tw-px-8">
      <h1 className="tw-text-[40px] tw-font-semibold">
        {t('MandatePage.heading')}
      </h1>
      <div className="tw-w-full tw-grid md:tw-grid-cols-[1fr_40%] tw-gap-8 tw-p-4 md:tw-p-6">
        <MandateInfoForm
          mandate={mandate}
          sellers={sellers}
          readOnly={!!mandate?.registryNumber}
          onSubmit={handleSubmit}
        />
        <MandateStatusCard mandate={mandate} />
      </div>
    </div>
  )
}
