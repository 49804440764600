import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export type EstateMediaProps = {
  onEdit?: () => void
  onDelete?: () => void
  className?: string
}

export const EstateMediaMenu = ({
  onEdit,
  onDelete,
  className,
}: EstateMediaProps) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const handleMenuClick = () => {
    setIsOpen(true)
  }
  const handleEditClick = () => {
    setIsOpen(false)
    onEdit?.()
  }
  const handleDeleteClick = () => {
    setIsOpen(false)
    onDelete?.()
  }
  if (!onEdit && !onDelete) return null

  // TODO use dropdownmenu, but fix focus scope recursion loop
  if (!isOpen)
    return (
      <div className={className}>
        <Button variant={'secondary'} onClick={handleMenuClick}>
          ...
        </Button>
      </div>
    )

  return (
    <div className={cn('tw-flex tw-gap-2', className)}>
      {onEdit && (
        <Button variant={'outline'} onClick={handleEditClick}>
          {t('common.edit')}
        </Button>
      )}
      {onDelete && (
        <Button onClick={handleDeleteClick}>{t('common.delete')}</Button>
      )}
    </div>
  )
}
