import { EstateFragment } from '@gql'
import { Action, action } from 'easy-peasy'

interface EstateModel {
  currentId: string | undefined
  current: EstateFragment | undefined
  loading: boolean
  error: Error | undefined
  // Set Id instantly from URL params
  setCurrentId: Action<EstateModel, string | undefined>
  // Set current after loading
  setSubscriptionResult: Action<
    EstateModel,
    {
      result: EstateFragment | undefined
      loading: boolean
      error: Error | undefined
    }
  >
}

const model: EstateModel = {
  currentId: undefined,
  current: undefined,
  loading: false,
  error: undefined,

  setCurrentId: action((state, id) => {
    if (state.currentId !== id) {
      state.currentId = id
    }
  }),

  setSubscriptionResult: action((state, { result, loading, error }) => {
    if (result) {
      state.current = result
    }
    state.loading = loading
    state.error = error
  }),
}

export default model
