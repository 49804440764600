import { getSellerPath } from '@almaris/shared/helpers/getSellerPath'
import useCurrentSeller from './useCurrentSeller'
import { useSellerId } from './useSellerId'

export function usePathInSeller(path: string) {
  const sellerId = useSellerId()
  const seller = useCurrentSeller()
  if (!seller && !sellerId) return ''

  return `${seller ? getSellerPath(seller) : `/sellers/${sellerId}`}/${path}`
}
