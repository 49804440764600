import { AdminUsersList } from '@/agencyMember/components/AdminUsersList'
import React from 'react'

export const AdminUsersPage = () => {
  return (
    <div className="tw-p-8 tw-flex tw-justify-center">
      <AdminUsersList />
    </div>
  )
}
