import React, { useMemo, useState } from 'react'
import { Title } from '@/common/atoms/Title'
import { useTranslation } from 'react-i18next'
import SellerCreateModal from '../modals/SellerCreateModal'
import Loading from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { SellersTable } from '../components/table/SellersTable'
import useSellers from '../hooks/useSellers'

export default function SellersPage() {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)
  const [includeArchived, setIncludeArchived] = useState(false)

  const { sellers: allSellers, loading, error } = useSellers()
  const sellers = useMemo(() => {
    if (includeArchived) {
      return allSellers
    }
    return allSellers?.filter((s) => !s.archived)
  }, [allSellers, includeArchived])

  return (
    <div className="tw-p-8">
      <Title>{t('SellersPage.heading')}</Title>
      <div className="tw-flex tw-justify-between tw-items-baseline">
        <h1 className="tw-text-[40px] tw-font-semibold">
          {t('SellersPage.heading')}
        </h1>
        <SellerCreateModal open={openModal} setOpenModal={setOpenModal} />
      </div>
      <div>
        {loading && <Loading center active />}
        {error && <TextError error={error} />}
        <SellersTable
          sellers={sellers}
          setIncludeArchived={setIncludeArchived}
        />
      </div>
    </div>
  )
}
