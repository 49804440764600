import { EnumSelect, EnumSelectProps } from '@/enum/EnumSelect'
import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

export enum Urban_Preemption_Right_Type_Enum {
  Simple = 'Simple',
  Reinforced = 'Reinforced',
}

export type UrbanPreemptionRightTypeSelectProps = Omit<
  EnumSelectProps,
  'entries' | 'label' | 'placeholder'
>
export const UrbanPreemptionRightTypeSelect = forwardRef<
  HTMLInputElement,
  UrbanPreemptionRightTypeSelectProps
>((props, ref) => {
  const { t } = useTranslation()
  const name = 'UrbanPreemptionRightTypeSelect'

  const entries = Object.values(Urban_Preemption_Right_Type_Enum).reduce(
    (acc, type) => {
      acc[type] = t(`${name}.${type}`)
      return acc
    },
    {} as Record<Urban_Preemption_Right_Type_Enum, string>
  )

  return (
    <div>
      <EnumSelect
        {...props}
        entries={entries}
        label={t('UrbanPreemptionRightTypeSelect.label')}
        placeholder={t('UrbanPreemptionRightTypeSelect.placeholder')}
        ref={ref}
      />
    </div>
  )
})

UrbanPreemptionRightTypeSelect.displayName = 'UrbanPreemptionRightTypeSelect'
