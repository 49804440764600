import Homepage from '@/common/pages/Homepage'
import MemberInvitationPage from '@/seller/pages/MemberInvitationPage'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AuthLayout } from 'src/features/layout/AuthLayout'
import { ForgotPasswordForm } from 'src/features/user/components/ForgotPasswordForm'
import { ResetPasswordPage } from 'src/features/user/pages/ResetPasswordPage'

export default function PublicRoute() {
  return (
    <Routes>
      <Route index element={<Homepage />} />
      <Route path="/" element={<AuthLayout />}>
        <Route path="reset-password" element={<ResetPasswordPage />} />
        <Route path="forgot-password" element={<ForgotPasswordForm />} />
        <Route
          path="sellers/:sellerId/invitation"
          element={<MemberInvitationPage />}
        />
      </Route>
    </Routes>
  )
}
