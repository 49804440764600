import React, { ForwardedRef, forwardRef } from 'react'
import { Combobox } from '@/components/ui/combobox'
import type { ComboboxProps } from '@/components/ui/combobox'
import {
  SellerFragment,
  SellerMemberWithUserAndSellerNamesFragment,
  useGetSellerMembersBySellerIdsQuery,
} from '@gql'
import Loading from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { useTranslation } from 'react-i18next'

export type SellerMemberComboboxProps = Omit<
  ComboboxProps<SellerMemberWithUserAndSellerNamesFragment>,
  'items' | 'getId' | 'getLabel'
> & {
  sellerIds: SellerFragment['id'][]
}

const SellerMemberComboboxInner = (
  { sellerIds, ...props }: SellerMemberComboboxProps,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const { t } = useTranslation()
  const { data, loading, error } = useGetSellerMembersBySellerIdsQuery({
    variables: {
      sellerIds,
    },
  })

  const members = data?.seller_member || []

  if (loading) return <Loading center active />
  if (error) return <TextError error={error} />
  return (
    <Combobox
      ref={ref}
      {...props}
      items={members}
      getId={(m) => m.id}
      getLabel={(m) =>
        `${m.name || m.user?.displayName || m.inviteEmail || m.user?.email} - ${m.seller.name}`
      }
      placeholder={t('SellerMemberCombobox.placeholder')}
      searchPlaceholder={t('SellerMemberCombobox.searchPlaceholder')}
      noResultText={t('SellerMemberCombobox.noResultText')}
    />
  )
}

export const SellerMemberCombobox = forwardRef(SellerMemberComboboxInner)
