import React from 'react'
import { Control, useFieldArray } from 'react-hook-form'
import { Button } from '@/components/ui/button'
import { FormField, FormItem, FormLabel } from '@/components/ui/form'
import { TimePicker } from '@/components/ui/timePicker'
import { PlusIcon, Trash2Icon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { EstateSettingVisitSpanFormValues } from './EstateSettingVisitSpanForm'

type EstateSettingVisitSpanNestedFieldArrayProps = {
  currentDate: Date
  nestId: number
  control: Control<EstateSettingVisitSpanFormValues, any>
  readOnly?: boolean
}

export const EstateSettingVisitSpanNestedFieldArray = ({
  currentDate,
  nestId,
  control,
  readOnly,
}: EstateSettingVisitSpanNestedFieldArrayProps) => {
  const { t } = useTranslation()

  const { fields, remove, append } = useFieldArray({
    control,
    name: `visitSpans.${nestId}.span`,
  })

  const handleDeleteSpan = async (id: number) => {
    remove(id)
  }

  return (
    <>
      {fields.map((item, id) => {
        return (
          <div key={item.id} className="tw-flex tw-space-x-4 tw-items-end">
            <FormField
              control={control}
              name={`visitSpans.${nestId}.span.${id}.startTime`}
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('EstateSettingVisitSpanNestedFieldArray.from')}
                  </FormLabel>
                  <TimePicker
                    startTime="09:00"
                    endTime="19:00"
                    date={currentDate}
                    value={field.value}
                    onValueChange={field.onChange}
                    disabled={readOnly}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name={`visitSpans.${nestId}.span.${id}.endTime`}
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('EstateSettingVisitSpanNestedFieldArray.to')}
                  </FormLabel>
                  <TimePicker
                    startTime="09:00"
                    endTime="19:00"
                    date={currentDate}
                    value={field.value}
                    onValueChange={field.onChange}
                    disabled={readOnly}
                  />
                </FormItem>
              )}
            />
            {!readOnly && (
              <Button
                variant="ghost"
                size="icon"
                type="button"
                className="tw-w-7 tw-h-7"
                onClick={() => handleDeleteSpan(id)}
              >
                <Trash2Icon className="tw-w-4 tw-h-4 tw-stroke-zinc-500" />
              </Button>
            )}
          </div>
        )
      })}
      {!readOnly && (
        <Button
          variant="secondary"
          type="button"
          size="icon"
          className="tw-mt-3 tw-w-9 tw-h-9"
          onClick={() =>
            append({
              startTime: new Date(),
              endTime: new Date(),
            })
          }
        >
          <PlusIcon className="tw-w-4 tw-h-4" />
        </Button>
      )}
    </>
  )
}
