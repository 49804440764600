import React, { useEffect, useMemo } from 'react'
import { EstateFragment, useUpdateEstateMutation } from '@gql'
import { useTranslation } from 'react-i18next'
import { useToast } from '@/components/ui/use-toast'
import { EstateSellerDataForm } from './EstateSellerDataForm'
import debounce from 'lodash.debounce'

export type EstateSellerFormProps = {
  estate: EstateFragment
}
export const EstateSellerForm = ({ estate }: EstateSellerFormProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const [
    updateEstate,
    {
      data: updateEstateData,
      // loading: updateEstateLoading,
      error: updateEstateError,
    },
  ] = useUpdateEstateMutation()

  useEffect(() => {
    if (updateEstateData) {
      toast({
        variant: 'success',
        description: t('EstateDiagnosticsTab.toast.success'),
      })
    }
  }, [updateEstateData])

  useEffect(() => {
    if (updateEstateError) {
      toast({
        variant: 'destructive',
        description: t('EstateDiagnosticsTab.toast.error'),
      })
    }
  }, [updateEstateError])

  const debouncedUpdateEstate = useMemo(
    () =>
      debounce((values: Partial<EstateFragment>) => {
        updateEstate({
          variables: {
            id: estate.id,
            values,
          },
        })
      }, 500),
    [estate.id, updateEstate]
  )

  return (
    <EstateSellerDataForm estate={estate} onSubmit={debouncedUpdateEstate} />
  )
}
