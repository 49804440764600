import { SellerMemberFragment } from '@gql'
import { useStoreState } from '@store/hooks'
import { useMemo } from 'react'

export default function useMember(
  id?: string
): SellerMemberFragment | undefined {
  const sellerMembers = useStoreState((state) => state.seller.members)

  return useMemo(
    () =>
      sellerMembers
        ? sellerMembers?.find((m) => m.id === id || m.userId === id)
        : undefined,

    [sellerMembers, id]
  )
}
