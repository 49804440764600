import React, { useEffect } from 'react'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { EstateFragment, useCreateEstateMutation } from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useToast } from '@/components/ui/use-toast'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import Loading from '@/common/atoms/Loading'
import { SellerSelect } from '@/seller/components/SellerSelect'
import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'

const schema = yup.object().shape({
  name: yup.string().optional(),
  address: yup.string().min(2).required(),
  city: yup.string().min(2).required(),
  zipCode: yup.string().min(2).required(),
  sellerId: yup.string().required(),
})

const resolver = yupResolver(schema)

type EstateAddFormValues = yup.InferType<typeof schema>

export type EstateAddFormProps = {
  onCreate: (estate: EstateFragment) => void
}
export const EstateAddForm = ({ onCreate }: EstateAddFormProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const [createEstate, { data, loading, error }] = useCreateEstateMutation()
  const isAgencyMember = useIsAgencyMember()

  const defaultValues = {
    name: '',
    address: '',
    city: '',
    zipCode: '',
    sellerId: undefined,
  }

  const form = useForm<EstateAddFormValues>({
    resolver,
    defaultValues,
  })

  const onSubmit = async (values: EstateAddFormValues) => {
    await createEstate({
      variables: {
        values,
      },
    })
  }

  useEffect(() => {
    if (error)
      toast({
        variant: 'destructive',
        description: t('EstateAddForm.toast.error'),
      })
  }, [error])

  useEffect(() => {
    if (data?.insert_estate_one) {
      onCreate(data.insert_estate_one)
      toast({
        variant: 'success',
        description: t('EstateAddForm.toast.success'),
      })
    }
  }, [data])

  if (loading) return <Loading center active />

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="tw-space-y-5">
          <FormField
            control={form.control}
            name="sellerId"
            render={({ field }) => (
              <SellerSelect
                field={field}
                className={isAgencyMember ? undefined : 'tw-display-none'}
                filter={(seller) => !seller.archived}
              />
            )}
          />
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('EstateAddForm.name')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('EstateAddForm.address')} *</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="city"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('EstateAddForm.city')} *</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="zipCode"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('EstateAddForm.zipCode')} *</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button variant="green" type="submit">
            {t('EstateAddForm.submit')}
          </Button>
        </div>
      </form>
    </Form>
  )
}
