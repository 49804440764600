import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { HousePlusIcon } from 'lucide-react'
import React from 'react'
import { EstateAddForm } from './EstateAddForm'
import { useTranslation } from 'react-i18next'
import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'

type EstateAddFormModalProps = {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

export const EstateAddFormModal = ({
  isOpen,
  setIsOpen,
}: EstateAddFormModalProps) => {
  const { t } = useTranslation()
  const isAgencyMember = useIsAgencyMember()

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant={isAgencyMember ? 'default' : 'green'}>
          <HousePlusIcon className="tw-h-4 tw-w-4 tw-mr-2" />
          {t('EstatesPage.addEstate')}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('EstatesPage.addEstate')}</DialogTitle>
        </DialogHeader>
        <EstateAddForm onCreate={() => setIsOpen(false)} />
      </DialogContent>
    </Dialog>
  )
}
