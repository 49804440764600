import { RefreshCcwDotIcon } from 'lucide-react'
import React, { useMemo, useState } from 'react'
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { TimePicker } from '@/components/ui/timePicker'
import { useToast } from '@/components/ui/use-toast'
import {
  EstateFragment,
  useIncrementApplicationsRoundNumberMutation,
  useUpdateEstateMutation,
} from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Button } from '@/components/ui/button'
import { DialogFooter } from '@/components/ui/dialog'
import { EstateBuyerMemberMultiSelect } from '../EstateBuyerMember/EstateBuyerMemberMultiSelect'
import { trpc } from 'src/trpc'
import { InputDatePicker } from '@/components/ui/InputDatePicker'

const schema = yup.object().shape({
  endDate: yup
    .object()
    .shape({ day: yup.date().required(), hour: yup.date().required() }),
  buyerMembers: yup.array(yup.string().min(1).required()).min(1).required(),
})

const resolver = yupResolver(schema)

type EstateSettingOffersFormValues = yup.InferType<typeof schema>

type EstateSettingOffersFormProps = {
  estate: EstateFragment
  readOnly?: boolean
}

export const EstateSettingOffersForm = ({
  estate,
  readOnly,
}: EstateSettingOffersFormProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const [showForm, setShowForm] = useState(false)

  const [updateEstate] = useUpdateEstateMutation()
  const [incrementApplicationsRoundNumber] =
    useIncrementApplicationsRoundNumberMutation()

  const isSaleOpen = useMemo(() => {
    return estate.saleData.saleOpen
  }, [estate.saleData])

  const form = useForm<EstateSettingOffersFormValues>({
    resolver,
  })

  const hasDirtyFields = Object.keys(form.formState.dirtyFields).length > 0

  const onSubmit = async (values: EstateSettingOffersFormValues) => {
    const { data } = await updateEstate({
      variables: {
        id: estate.id,
        values: {
          saleData: {
            ...estate.saleData,
            secondRound: {
              endDate: values.endDate.hour,
              buyerIds: values.buyerMembers,
            },
          },
        },
      },
      onError: (error) => {
        toast({
          variant: 'destructive',
          title: t('EstateSettingOffersForm.toast.error'),
          description: error.message,
        })
      },
    })

    if (data?.update_estate_by_pk) {
      await incrementApplicationsRoundNumber({
        variables: {
          buyerIds: values.buyerMembers,
          estateId: estate.id,
        },
        onCompleted: () => {
          toast({
            variant: 'success',
            description: t('EstateSettingOffersForm.toast.success'),
          })
        },
        onError: (error) => {
          toast({
            variant: 'destructive',
            title: t('EstateSettingOffersForm.toast.error'),
            description: error.message,
          })
        },
      })
      try {
        await trpc.application.sendNewRoundMail.mutate({
          buyerIds: values.buyerMembers,
          estateId: estate.id,
        })
        toast({
          title: t('EstateSettingOffersForm.toast.sendNewRoundMail.success'),
          variant: 'success',
        })
      } catch (error) {
        toast({
          description: error instanceof Error ? error.message : '',
          variant: 'destructive',
        })
      }
    }
  }

  return (
    <>
      <div className="tw-mt-3 tw-bg-zinc-50 tw-text-zinc-500 tw-flex tw-items-start tw-p-5 tw-justify-between tw-gap-3">
        <div>
          <RefreshCcwDotIcon />
        </div>
        <div className="tw-flex tw-flex-col tw-gap-2">
          <div>
            <p className="tw-font-medium">
              {t('EstateSettingOffersForm.infoCard.title')}
            </p>
            <p className="tw-text-xs">
              {t('EstateSettingOffersForm.infoCard.subTitle')}
            </p>
          </div>
          <div>
            <p className="tw-text-sm">
              {t('EstateSettingOffersForm.infoCard.description')}
            </p>
          </div>
        </div>
        <Button
          disabled={!isSaleOpen || readOnly}
          className="tw-self-end"
          size="sm"
          onClick={() => setShowForm((prev) => !prev)}
        >
          {t('EstateSettingOffersForm.infoCard.button')}
        </Button>
      </div>
      {showForm && (
        <Form {...form}>
          <form
            className="tw-space-y-6 tw-pt-6 tw-h-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <div className="tw-flex tw-space-x-4">
              <FormField
                control={form.control}
                name="endDate.day"
                render={({ field }) => (
                  <FormItem className="tw-flex tw-flex-col tw-w-full">
                    <FormLabel>
                      {t('EstateSettingOffersForm.form.endDate.date')}
                    </FormLabel>
                    <InputDatePicker
                      {...field}
                      placeholder={t(
                        'EstateSettingOffersForm.form.endDate.placeholder'
                      )}
                      fromDate={new Date(estate.saleData?.firstRound.endDate)}
                      disabled={readOnly}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="endDate.hour"
                render={({ field }) => (
                  <FormItem className="tw-flex tw-flex-col tw-w-full">
                    <FormLabel>
                      {t('EstateSettingOffersForm.form.endDate.hour')}
                    </FormLabel>
                    <TimePicker
                      date={form.getValues('endDate.day')}
                      startTime="09:00"
                      endTime="19:00"
                      value={field.value}
                      onValueChange={field.onChange}
                      className="tw-w-full"
                      disabled={readOnly}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              name="buyerMembers"
              render={({ field }) => (
                <EstateBuyerMemberMultiSelect
                  estateId={estate.id}
                  defaultValue={field.value ?? []}
                  onValueChange={field.onChange}
                  disabled={readOnly}
                  {...field}
                />
              )}
            />
            <DialogFooter>
              {!readOnly && (
                <Button
                  variant="secondary"
                  type="submit"
                  disabled={!hasDirtyFields}
                >
                  {t('EstateSettingOffersForm.form.submit')}
                </Button>
              )}
            </DialogFooter>
          </form>
        </Form>
      )}
    </>
  )
}
