import { Button } from '@/components/ui/button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EstateFragment } from '@gql'
import { trpc } from 'src/trpc'
import { useToast } from '@/components/ui/use-toast'

type EstateSendMailEstimateButtonProps = {
  estate: EstateFragment
}
export const EstateSendMailEstimateButton = ({
  estate,
}: EstateSendMailEstimateButtonProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const handleSendMailEstimate = async () => {
    try {
      await trpc.estate.sendEstimateMail.mutate({
        estateId: estate.id,
      })
      toast({
        title: t('EstateSendMailEstimateButton.toast.success'),
        variant: 'success',
      })
    } catch (error) {
      toast({
        title: t('EstateSendMailEstimateButton.toast.failed'),
        description: error instanceof Error ? error.message : '',
        variant: 'destructive',
      })
    }
  }

  return (
    <Button
      type="button"
      className="tw-justify-self-center tw-w-1/2"
      onClick={handleSendMailEstimate}
    >
      {t('EstateSendMailEstimateButton.button')}
    </Button>
  )
}
