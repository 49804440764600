import React from 'react'
import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

import useUserSignOut from '@/user/hooks/useUserSignOut'
import { useNavigate } from 'react-router-dom'
import BrandLogo from '@/common/atoms/BrandLogo'
import { useUserId } from '@nhost/react'
import { Button } from '@/components/ui/button'
import { useTranslation } from 'react-i18next'
import { AdminNavbarMenu } from './AdminNavbarMenu'
import { getInitials } from '@utils/getInitials'
import { useNavigateSeller } from '@/seller/hooks/useNavigateSeller'
import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'
import {
  useGetCurrentMemberAsAgencyrMemberSubscription,
  useGetCurrentMemberAsSellerMemberSubscription,
} from '@gql'

export const Header = () => {
  const { t } = useTranslation()
  const navigateSeller = useNavigateSeller()
  const navigate = useNavigate()

  const userId = useUserId()

  const { data: agencyMemberData } =
    useGetCurrentMemberAsAgencyrMemberSubscription({
      variables: { userId: String(userId) },
      skip: !userId,
    })

  const { data: sellerMemberData } =
    useGetCurrentMemberAsSellerMemberSubscription({
      variables: { userId: String(userId) },
      skip: !userId,
    })

  const currentMember =
    sellerMemberData?.seller_member[0] || agencyMemberData?.agency_member[0]

  const isAgencyMember = useIsAgencyMember()
  const signOut = useUserSignOut()

  const handleSignOut = () => {
    signOut()
    navigate('/')
  }

  const handleClick = () =>
    isAgencyMember ? navigate('/') : navigateSeller('')

  return (
    <header className="tw-flex tw-h-20 tw-w-full tw-border-b tw-border-zinc-200 tw-bg-zinc-50 tw-shrink-0 tw-items-center tw-justify-between tw-px-4 tw-md:px-6">
      <div className="tw-flex tw-items-center tw-gap-14">
        <div
          onClick={handleClick}
          className="tw-flex tw-items-center hover:tw-cursor-pointer"
        >
          <BrandLogo size="md" />
        </div>
        {isAgencyMember && <AdminNavbarMenu />}
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="tw-relative tw-h-8 tw-w-8 tw-rounded-full hover:tw-rounded-full"
          >
            <Avatar className="tw-h-8 tw-w-8 tw-border">
              <AvatarFallback>
                {getInitials(currentMember?.name ?? currentMember?.email ?? '')}
              </AvatarFallback>
            </Avatar>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="tw-w-56">
          <DropdownMenuLabel className="tw-font-normal">
            <div className="tw-flex tw-flex-col tw-space-y-1">
              {currentMember?.name && (
                <p className="tw-text-sm tw-font-medium tw-leading-none">
                  {currentMember?.name}
                </p>
              )}
              <p className="tw-text-xs tw-leading-none tw-text-muted-foreground">
                {currentMember?.email}
              </p>
            </div>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItemUserInfoLink id={userId} />
            <DropdownMenuItemMembersLink />
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem onSelect={handleSignOut}>
            <span className="tw-text-red-600">{t('Navbar.items.signOut')}</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </header>
  )
}

const DropdownMenuItemUserInfoLink = ({ id }: { id?: string }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isAgencyMember = useIsAgencyMember()
  const navigateSeller = useNavigateSeller()

  const handleClick = () =>
    isAgencyMember
      ? navigate(`/user-info/${id}`)
      : navigateSeller(`user-info/${id}`)

  return (
    <DropdownMenuItem onClick={handleClick}>
      {t('Navbar.items.account')}
    </DropdownMenuItem>
  )
}

const DropdownMenuItemMembersLink = () => {
  const { t } = useTranslation()
  const navigateSeller = useNavigateSeller()
  const navigate = useNavigate()
  const isAgencyMember = useIsAgencyMember()

  const handleClick = () => {
    isAgencyMember
      ? navigate(`/agency-members`)
      : navigateSeller(`seller-members`)
  }

  return (
    <DropdownMenuItem onClick={handleClick}>
      {t('Navbar.items.members')}
    </DropdownMenuItem>
  )
}
