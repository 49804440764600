import React, { useMemo } from 'react'

import { EstateMediaTypeFragment, Estate_Media_Type_Name_Enum } from '@gql'
import { useTranslation } from 'react-i18next'

export type EstateMediaTypeNameTranslationProps = {
  type?: EstateMediaTypeFragment
  defaultValue?: string
}
export const EstateMediaTypeNameTranslation = ({
  type,
  defaultValue,
}: EstateMediaTypeNameTranslationProps) => {
  const { t } = useTranslation()

  const result = useMemo(() => {
    if (!type) return defaultValue

    switch (type.name) {
      case Estate_Media_Type_Name_Enum.Title:
        return t(`EstateMediaTypeName.Title`)
      case Estate_Media_Type_Name_Enum.CadastralPlan:
        return t(`EstateMediaTypeName.CadastralPlan`)
      case Estate_Media_Type_Name_Enum.UrbanismNotice:
        return t(`EstateMediaTypeName.UrbanismNotice`)
      case Estate_Media_Type_Name_Enum.InsuranceCertificate:
        return t(`EstateMediaTypeName.InsuranceCertificate`)
      case Estate_Media_Type_Name_Enum.Other:
        return defaultValue
          ? [t(`EstateMediaTypeName.Other`), defaultValue].join(' - ')
          : t(`EstateMediaTypeName.Other`)
      case Estate_Media_Type_Name_Enum.Plans:
        return t(`EstateMediaTypeName.Plans`)
      case Estate_Media_Type_Name_Enum.PropertyTax:
        return t(`EstateMediaTypeName.PropertyTax`)
      case Estate_Media_Type_Name_Enum.ResidenceOrEmptyPropertyTax:
        return t(`EstateMediaTypeName.ResidenceOrEmptyPropertyTax`)
      case Estate_Media_Type_Name_Enum.CoownershipRegulation:
        return t(`EstateMediaTypeName.CoownershipRegulation`)
      case Estate_Media_Type_Name_Enum.LotsPlan:
        return t(`EstateMediaTypeName.LotsPlan`)
      case Estate_Media_Type_Name_Enum.DatedPreState:
        return t(`EstateMediaTypeName.DatedPreState`)
      case Estate_Media_Type_Name_Enum.DatedState:
        return t(`EstateMediaTypeName.DatedState`)
      case Estate_Media_Type_Name_Enum.CoownershipEpc:
        return t(`EstateMediaTypeName.CoownershipEPC`)
      case Estate_Media_Type_Name_Enum.GeneralMeetingMinutes:
        return t(`EstateMediaTypeName.GeneralMeetingMinutes`)
      case Estate_Media_Type_Name_Enum.FundCalls:
        return t(`EstateMediaTypeName.FundCalls`)
      case Estate_Media_Type_Name_Enum.BuildingMaintenanceLog:
        return t(`EstateMediaTypeName.BuildingMaintenanceLog`)
      case Estate_Media_Type_Name_Enum.CommonAreaDiagnostics:
        return t(`EstateMediaTypeName.CommonAreaDiagnostics`)
      case Estate_Media_Type_Name_Enum.CoownershipSummarySheet:
        return t(`EstateMediaTypeName.CoownershipSummarySheet`)
      case Estate_Media_Type_Name_Enum.DraftCompromise:
        return t(`EstateMediaTypeName.DraftCompromise`)
      default:
        return defaultValue
    }
  }, [defaultValue])

  return <>{result}</>
}
