import React from 'react'
import { useVideoThumbnail, UseVideoThumbnailProps } from './useVideoThumbnail'
import { PlayCircle } from 'lucide-react'

export type VideoThumbnailProps = UseVideoThumbnailProps
export const VideoThumbnail = ({ url }: VideoThumbnailProps) => {
  const { thumbnail } = useVideoThumbnail({ url })

  if (!thumbnail) {
    return null
  }
  return (
    <div className="tw-relative tw-w-full tw-h-full">
      <img
        src={thumbnail}
        className="tw-object-cover tw-w-full tw-h-full"
        alt={thumbnail}
      />
      <PlayCircle
        size={64}
        color="black"
        fill="white"
        className="tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw-translate-x-[-50%] tw-translate-y-[-50%]"
      />
    </div>
  )
}
