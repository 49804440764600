import React from 'react'
import TextError from '@/common/atoms/TextError'
import { EstateEditForm } from '../components/Estate/EstateEditForm'
import { useTranslation } from 'react-i18next'
import { EstateEstimatePriceCard } from '../components/Estate/EstateEstimatePriceCard'
import useCurrentEstate from '../hooks/useCurrentEstate'

export const EstatePage = () => {
  const { t } = useTranslation()

  const estate = useCurrentEstate()
  if (!estate) return <TextError error={new Error('Estate not found')} />

  return (
    <div className="tw-py-6 tw-px-8">
      <h1 className="tw-text-[40px] tw-font-semibold">
        {t('EstatePage.title')}
      </h1>
      <div className="tw-w-full tw-grid md:tw-grid-cols-[1fr_40%] tw-gap-6 tw-p-4 md:tw-p-6">
        <EstateEditForm estate={estate} />
        <EstateEstimatePriceCard estate={estate} />
      </div>
    </div>
  )
}
