import { Badge } from '@/components/ui/badge'
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { X } from 'lucide-react'
import React, { forwardRef, useState } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export enum Equipment_Enum {
  IndividualGasHeating = 'IndividualGasHeating',
  IndividualFuelHeating = 'IndividualFuelHeating',
  IndividualBiomassHeating = 'IndividualBiomassHeating',
  IndividualElectricHeating = 'IndividualElectricHeating',
  CollectiveHeating = 'CollectiveHeating',
  IndividualHotWater = 'IndividualHotWater',
  CollectiveHotWater = 'CollectiveHotWater',
  Guardian = 'Guardian',
  BikeRoom = 'BikeRoom',
  Elevator = 'Elevator',
  Alarm = 'Alarm',
}

export const EquipmentMultiSelect = forwardRef<
  HTMLInputElement,
  { field: ControllerRenderProps<any>; className?: string; showLabel?: boolean }
>(({ field, className, showLabel = true }, ref) => {
  const { t } = useTranslation()

  const [values, setValues] = useState<Equipment_Enum[]>(field.value ?? [])
  const [key, setKey] = useState(0) // key to force re-render with empty value

  const handleChange = (value: Equipment_Enum) => {
    const newValues = values.includes(value)
      ? values.filter((item) => item !== value)
      : [...values, value]
    field.onChange?.(newValues)
    setValues(newValues)
    setKey((prevKey) => prevKey + 1)
  }

  return (
    <FormItem className={className}>
      {showLabel && <FormLabel>{t('EquipmentMultiSelect.label')}</FormLabel>}
      <div className="tw-flex tw-flex-wrap tw-gap-1">
        {values.map((value) => (
          <Badge key={value}>
            <button className="tw-mr-1" onClick={() => handleChange(value)}>
              <X className="tw-size-3 tw-stroke-current" />
            </button>
            {t(`EquipmentMultiSelect.${value}`)}
          </Badge>
        ))}
      </div>
      <Select onValueChange={handleChange} key={key}>
        <FormControl>
          <SelectTrigger className="tw-w-full">
            <SelectValue
              ref={ref}
              placeholder={t('EquipmentMultiSelect.placeholder')}
            >
              <span className="tw-text-muted-foreground">
                {t(`EquipmentMultiSelect.placeholder`)}
              </span>
            </SelectValue>
          </SelectTrigger>
        </FormControl>
        <SelectContent>
          {Object.values(Equipment_Enum).map((entry) => (
            <SelectItem value={entry} key={entry}>
              <span className="tw-flex tw-items-center tw-gap-1">
                {values.includes(entry) && (
                  <X className="tw-size-3 tw-stroke-current" />
                )}
                <span>{t(`EquipmentMultiSelect.${entry}`)}</span>
              </span>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <FormMessage />
    </FormItem>
  )
})

EquipmentMultiSelect.displayName = 'EquipmentMultiSelect'
