import { getSellerPath } from '@almaris/shared/helpers/getSellerPath'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { store } from '../../../store'

export function useNavigateSeller() {
  const navigate = useNavigate()
  return useCallback((path = '') => {
    const seller = store.getState().seller.current

    if (!seller) return
    navigate(`${getSellerPath(seller)}/${path}`)
  }, [])
}
