import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react'
import { Check, ChevronsUpDown } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

export type ComboboxProps<T> = {
  placeholder?: string
  searchPlaceholder?: string
  noResultText?: string
  items: T[]
  getLabel: (item: T) => string
  getId: (item: T) => string
  mode?: 'id' | 'object'
  value?: T | string
  onChange: (value: T | string | undefined) => void
}
const ComboboxInner = <T,>(
  {
    placeholder = 'Select item...',
    searchPlaceholder = 'Search item...',
    noResultText = 'No item found.',
    items = [],
    getLabel,
    getId,
    value,
    onChange,
    mode = 'id',
  }: ComboboxProps<T>,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const [open, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<T | undefined>(
    mode === 'id' ? items.find((item) => getId(item) === value) : (value as T)
  )

  const handleSelect = (itemId: string) => {
    const newSelectedItem = items.find((item) => getId(item) === itemId)
    if (selectedItem && getId(selectedItem) === itemId) {
      setSelectedItem(undefined)
    }
    setSelectedItem(newSelectedItem)
  }

  useEffect(() => {
    setOpen(false)
    onChange(selectedItem && mode === 'id' ? getId(selectedItem) : selectedItem)
  }, [selectedItem, mode, onChange])

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          ref={ref}
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          {selectedItem ? getLabel(selectedItem) : placeholder}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder={searchPlaceholder} />
          <CommandList>
            <CommandEmpty>{noResultText}</CommandEmpty>
            <CommandGroup>
              {items.map((item) => (
                <CommandItem
                  key={getId(item)}
                  value={getId(item)}
                  onSelect={handleSelect}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      selectedItem && getId(selectedItem) === getId(item)
                        ? 'opacity-100'
                        : 'opacity-0'
                    )}
                  />
                  {getLabel(item)}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export const Combobox = forwardRef(ComboboxInner) as <T>(
  props: ComboboxProps<T> & { ref?: ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof ComboboxInner>
