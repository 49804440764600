import { useCallback, useState } from 'react'
import { nhost } from 'src/nhost'
import { StorageDeleteResponse } from '@nhost/hasura-storage-js'

export const useFileDelete = (defaultFileId?: string) => {
  const [error, setError] = useState<StorageDeleteResponse['error']>()
  const [success, setSuccess] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const deleteFile = useCallback(
    async (fileId = defaultFileId) => {
      if (!fileId) {
        return { error: new Error('No fileId provided') }
      }
      setSuccess(false)
      setError(undefined)
      setLoading(true)
      const result = await nhost.storage.delete({
        fileId,
      })
      setLoading(false)
      if (result.error) {
        setError(result.error)
      } else {
        setSuccess(true)
      }
      return result
    },
    [defaultFileId]
  )
  return { deleteFile, error, success, loading }
}
