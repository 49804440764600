import React, { useCallback, useMemo } from 'react'
import {
  Application_Status_Enum,
  useGetVisitSlotQuery,
  useUpdateBuyerMemberVisitMutation,
} from '@gql'
import { Button } from '@/components/ui/button'
import { CheckIcon } from 'lucide-react'
import { useToast } from '@/components/ui/use-toast'
import { useTranslation } from 'react-i18next'
import getLatestApplication from '@almaris/shared/helpers/getLatestApplication'

type EstateVisitDoneButtonProps = {
  visitSlotId: string
}

export const EstateVisitDoneButton = ({
  visitSlotId,
}: EstateVisitDoneButtonProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const { data } = useGetVisitSlotQuery({
    variables: { id: visitSlotId },
    skip: !visitSlotId,
  })

  const application = useMemo(() => {
    const applications =
      data?.visit_slot_by_pk?.buyer_member?.applications ?? []
    return getLatestApplication(applications)
  }, [data])

  const [updateBuyerMemberVisit] = useUpdateBuyerMemberVisitMutation()

  const handleUpdateSlot = useCallback(
    async (slotId: string, isVisitDone: boolean) => {
      await updateBuyerMemberVisit({
        variables: {
          id: slotId,
          isVisitDone,
          applicationId: application?.id,
          status: Application_Status_Enum.VisitDone,
        },
        onCompleted: () => {
          toast({
            description: t('EstateVisitDoneButton.toast.success'),
            variant: 'success',
          })
        },
        onError(error) {
          toast({
            description: error.message,
            variant: 'destructive',
          })
        },
      })
    },
    [application]
  )

  const currentVisitSlot = useMemo(() => {
    if (data?.visit_slot_by_pk) {
      return data.visit_slot_by_pk
    }
  }, [data])

  if (!currentVisitSlot) return null

  return (
    <>
      <p className="tw-text-zinc-900 tw-font-medium">
        {currentVisitSlot.buyer_member.name}
      </p>
      {currentVisitSlot.isVisitDone ? (
        <Button
          size="sm"
          onClick={() => handleUpdateSlot(currentVisitSlot.id, false)}
        >
          <CheckIcon className="tw-h-5 tw-w-5" />
          <span className="tw-ml-1">
            {t('EstateVisitDoneButton.button.undone')}
          </span>
        </Button>
      ) : (
        <Button
          size="sm"
          variant="outline"
          onClick={() => handleUpdateSlot(currentVisitSlot.id, true)}
        >
          {t('EstateVisitDoneButton.button.done')}
        </Button>
      )}
    </>
  )
}
