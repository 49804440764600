import Loading from '@/common/atoms/Loading'
import Page404 from '@/common/pages/Page404'
import { EstateDocumentsPage } from '@/estate/pages/EstateDocumentsPage'
import { EstateMediasPage } from '@/estate/pages/EstateMediasPage'
import { EstatePage } from '@/estate/pages/EstatePage'
import { EstateSalePage } from '@/estate/pages/EstateSalePage'
import { EstateSellersPage } from '@/estate/pages/EstateSellersPage'
import { EstateLayout } from '@/layout/EstateLayout'
import { MandatePage } from '@/mandate/pages/MandatePage'
import { useEstateSubscription } from '@gql'
import { useStoreActions } from '@store/hooks'
import React, { Suspense, useEffect } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'

export const EstateRoute = () => {
  const { estateId } = useParams<{ estateId: string }>()

  // Subscribe to estate
  const {
    data: estateData,
    error,
    loading,
  } = useEstateSubscription({
    skip: !estateId,
    variables: { id: estateId! },
  })

  const actions = useStoreActions((actions) => ({
    setCurrentId: actions.estate.setCurrentId,
    setSubscriptionResult: actions.estate.setSubscriptionResult,
  }))

  useEffect(() => {
    if (!estateId) return
    actions.setCurrentId(estateId)
    actions.setSubscriptionResult({
      result: estateData?.estate_by_pk ?? undefined,
      loading,
      error,
    })
  }, [estateId, estateData])

  return (
    <Suspense fallback={<Loading active center />}>
      <Routes>
        <Route element={<EstateLayout />}>
          <Route index element={<EstatePage />} />

          <Route path="/sellers" element={<EstateSellersPage />} />
          <Route path="/files" element={<EstateDocumentsPage />} />
          <Route path="/medias" element={<EstateMediasPage />} />
          <Route path="/mandate" element={<MandatePage />} />
          <Route path="/sale" element={<EstateSalePage />} />

          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </Suspense>
  )
}
