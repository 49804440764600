import { Button } from '@/components/ui/button'
import { useNavigateSeller } from '@/seller/hooks/useNavigateSeller'
import { EstateFragment } from '@gql'
import { ExternalLinkIcon } from 'lucide-react'
import React from 'react'
import HouseSvg from 'src/images/house.svg'

type EstateCardListEmptyMediaProps = {
  estate: EstateFragment
}
export const EstateCardListEmptyMedia = ({
  estate,
}: EstateCardListEmptyMediaProps) => {
  const navigate = useNavigateSeller()

  return (
    <div className="tw-relative tw-bg-zinc-50 tw-rounded-lg tw-overflow-hidden tw-flex tw-items-center tw-justify-center tw-h-[270px] tw-max-w-[380px]">
      <Button
        variant="outline"
        className="tw-absolute tw-top-7 tw-left-7 tw-opacity-0 group-hover:tw-opacity-100"
        onClick={() => navigate(`estates/${estate.id}`)}
      >
        <ExternalLinkIcon className="tw-h-4 tw-w-4" />
      </Button>
      <HouseSvg />
    </div>
  )
}
