import * as React from 'react'

import { cn } from '@/lib/utils'

export interface InputNumberProps {
  placeholder?: string
  className?: string
  disabled?: boolean
  value?: string | number
  min?: number
  max?: number
}

const InputNumber = React.forwardRef<HTMLInputElement, InputNumberProps>(
  ({ className, value, ...props }, ref) => {
    return (
      <input
        type="number"
        className={cn(
          'tw-flex tw-h-9 tw-w-60 tw-rounded-md tw-border tw-border-input tw-bg-background tw-px-3 tw-py-2 tw-text-sm tw-ring-offset-background file:tw-border-0 file:tw-bg-transparent file:tw-text-sm file:tw-font-medium placeholder:tw-text-muted-foreground focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-cursor-not-allowed disabled:tw-opacity-50 disabled:tw-bg-zinc-200',
          className
        )}
        ref={ref}
        value={`${value}`}
        {...props}
      />
    )
  }
)
InputNumber.displayName = 'InputNumber'

export { InputNumber }
