import { useUserId } from '@nhost/react'
import { useStoreState } from '@store/hooks'
import { useMemo } from 'react'

export default function useIsAgencyMember(): boolean | undefined {
  const userId = useUserId()
  const agencyMembers = useStoreState((state) => state.agency.agencyMembers)

  return useMemo(() => {
    if (!userId) return undefined
    return agencyMembers?.some((member) => member.userId === userId)
  }, [userId, agencyMembers])
}
