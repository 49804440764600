export default function generateTimeSeriesRecursive(
  date: Date,
  startTime: string,
  endTime: string,
  intervalMinutes: number = 60
): Date[] {
  const timeSeries: Date[] = []

  const [startHours, startMinutes] = startTime.split(':').map(Number)
  const [endHours, endMinutes] = endTime.split(':').map(Number)

  const startDate = new Date(date)
  startDate.setHours(startHours, startMinutes, 0, 0)

  const endDate = new Date(date)
  endDate.setHours(endHours, endMinutes, 0, 0)

  let currentTime = startDate

  while (currentTime <= endDate) {
    timeSeries.push(new Date(currentTime))
    currentTime = new Date(currentTime.getTime() + intervalMinutes * 60000)
  }

  return timeSeries
}
