export enum UserLocalStorageKeys {}

export function resetUserLocalStorage() {
  for (const key in localStorage) {
    if (!UserLocalStorageKeys) continue
    for (const keyPattern of Object.values(UserLocalStorageKeys)) {
      if (
        new RegExp('^' + keyPattern.replace(/\{id\}/, '.*') + '$').test(key)
      ) {
        localStorage.removeItem(key)
      }
    }
  }
}
