import React from 'react'
import { MoreHorizontal } from 'lucide-react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { SellerFragment, useUpdateSellerMutation } from '@gql'
import { useTranslation } from 'react-i18next'
import { useToast } from '@/components/ui/use-toast'

type SellersTableActionDropdownProps = {
  seller: SellerFragment
}

export const SellersTableActionDropdown = ({
  seller,
}: SellersTableActionDropdownProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const [update] = useUpdateSellerMutation()

  const handleArchive = async () => {
    if (!seller.id) return
    if (seller.archived) {
      await update({
        variables: {
          id: seller.id,
          values: {
            archived: false,
          },
        },
        onCompleted: () => {
          toast({
            description: t('SellersTableActionDropdown.toast.activeDesc'),
            variant: 'success',
          })
        },
        onError: (error) => {
          toast({
            description: error.message,
            variant: 'destructive',
          })
        },
      })
    } else {
      await update({
        variables: {
          id: seller.id,
          values: {
            archived: true,
          },
        },
        onCompleted: () => {
          toast({
            description: t('SellersTableActionDropdown.toast.archivedDesc'),
            variant: 'success',
          })
        },
        onError: (error) => {
          toast({
            description: error.message,
            variant: 'destructive',
          })
        },
      })
    }
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost">
          <MoreHorizontal className="tw-h-4 tw-w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>
          {t('SellersTableActionDropdown.heading')}
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={handleArchive}>
          {seller.archived
            ? t('SellersTableActionDropdown.active')
            : t('SellersTableActionDropdown.archived')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
