import { EnumSelect, EnumSelectProps } from '@/enum/EnumSelect'
import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

export enum Occupation_Type_Enum {
  PrincipalResidenceLease = 'PrincipalResidenceLease',
  CivilCodeLease = 'CivilCodeLease',
  CommercialLease = 'CommercialLease',
  OccupiedWithoutRightsOrTitle = 'OccupiedWithoutRightsOrTitle',
  Other = 'Other',
}

export type OccupationTypeSelectProps = Omit<
  EnumSelectProps,
  'entries' | 'label' | 'placeholder'
>
export const OccupationTypeSelect = forwardRef<
  HTMLInputElement,
  OccupationTypeSelectProps
>((props, ref) => {
  const { t } = useTranslation()
  const name = 'OccupationTypeSelect'

  const entries = Object.values(Occupation_Type_Enum).reduce(
    (acc, type) => {
      acc[type] = t(`${name}.${type}`)
      return acc
    },
    {} as Record<Occupation_Type_Enum, string>
  )

  return (
    <div>
      <EnumSelect
        {...props}
        entries={entries}
        label={t('OccupationTypeSelect.label')}
        placeholder={t('OccupationTypeSelect.placeholder')}
        ref={ref}
      />
    </div>
  )
})

OccupationTypeSelect.displayName = 'OccupationTypeSelect'
