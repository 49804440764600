import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loading from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { Title } from '@/common/atoms/Title'
import { EstateAddFormModal } from '../components/Estate/EstateAddFormModal'
import useCurrentSeller from '@/seller/hooks/useCurrentSeller'
import { EstatesCardList } from '../components/EstateCardList/EstatesCardList'
import { nhost } from 'src/nhost'
import useEstates from '../hooks/useEstates'

export const EstatesPage = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const seller = useCurrentSeller()

  const [includeArchived, setIncludeArchived] = useState(false)

  const { estates: all_estates, loading, error } = useEstates()

  const estates = includeArchived
    ? all_estates
    : all_estates?.filter((estate) => !estate.archived)

  const getLogoUrl = useCallback(() => {
    if (!seller?.logo) return
    // TODO maybe replace by nhost.storage.getPresignedUrl
    return nhost.storage.getPublicUrl({
      fileId: seller?.logo,
    })
  }, [seller])

  return (
    <>
      <Title>{t('EstatesPage.heading')}</Title>
      {loading && <Loading center active />}
      {error && <TextError error={error} />}

      <div className="tw-bg-[#EBFFF6] tw-max-h-[200px] tw-w-full tw-flex tw-items-center tw-justify-between tw-p-8 tw-overflow-hidden tw-relative">
        <div>
          <img
            src={getLogoUrl()}
            alt="Logo"
            className="tw-max-h-[100px] tw-object-cover"
          />
          <p className="tw-text-lg tw-font-medium tw-mt-6">
            {t('EstatesPage.description', {
              count: estates?.length,
            })}
          </p>
        </div>
        <EstateAddFormModal isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
      <EstatesCardList
        estates={estates}
        setIncludeArchived={setIncludeArchived}
      />
    </>
  )
}
