import React, { useMemo } from 'react'
import { VisitSpanFragment } from '@gql'
import { addMinutes, format } from 'date-fns'
import generateTimeSeriesRecursive from '@almaris/shared/helpers/generateTimeSeriesRecursive'
import { ScrollArea } from '@/components/ui/scroll-area'
import { EstateVisitDoneButton } from './EstateVisitDoneButton'

type EstateVisitSpanRowsProps = {
  visitSpan?: VisitSpanFragment
}
export const EstateVisitSpanRows = ({
  visitSpan,
}: EstateVisitSpanRowsProps) => {
  const allTimeSeriesWithMember = useMemo(() => {
    if (visitSpan && visitSpan.span.length) {
      const span = visitSpan.span as { startTime: string; endTime: string }[]
      const allTimeSeries = span
        .map((date) => {
          const startTime = format(new Date(date.startTime), 'HH:mm')
          const endTime = format(new Date(date.endTime), 'HH:mm')
          return generateTimeSeriesRecursive(
            new Date(date.startTime),
            startTime,
            endTime,
            30
          )
        })
        .flat()

      return allTimeSeries.map((date, index, timeSeries) => {
        const buyerSlot =
          visitSpan.visit_slots?.filter(
            (slot) =>
              format(new Date(slot.startTime), 'HH:mm') ===
              format(date, 'HH:mm')
          ) ?? []

        return {
          start: date,
          end: addMinutes(new Date(date), 30),
          buyerSlot,
        }
      })
    }
  }, [])

  return (
    <ScrollArea className="tw-h-[400px] tw-p-4 tw-pb-[2px]">
      <div className="tw-grid tw-grid-cols-1 tw-gap-4">
        {allTimeSeriesWithMember?.map((date) => (
          <div
            key={`${date.start.toISOString()}`}
            className="tw-flex tw-gap-2 tw-text-zinc-500 tw-text-sm tw-items-center tw-justify-between"
          >
            <span>
              {format(date.start, 'HH:mm')} -{' '}
              {date.end && format(date.end, 'HH:mm')}
            </span>
            {date.buyerSlot.map((visitSlot) => (
              <EstateVisitDoneButton
                key={visitSlot.id}
                visitSlotId={visitSlot.id}
              />
            ))}
          </div>
        ))}
      </div>
    </ScrollArea>
  )
}
