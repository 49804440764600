import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { ConfirmationDialog } from '@/components/ui/ConfirmationDialog'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/use-toast'
import { Spinner } from '@chakra-ui/react'
import {
  Mandate_Status_Enum,
  MandateFragment,
  useGetMandateMediaSubscription,
  useGetMandatesQuery,
  useUpdateMandateMutation,
  useUploadMandateMediaMutation,
} from '@gql'
import { useFileUpload } from '@nhost/react'
import { HourglassIcon, PencilIcon } from 'lucide-react'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { nhost } from 'src/nhost'
import useGenerateRegisterNumber from '../hooks/useGenerateRegistryNumber'
import { useStoreState } from '@store/hooks'

type MandateStatusCardProps = {
  mandate?: MandateFragment | null
}

export const MandateStatusCard = ({ mandate }: MandateStatusCardProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const isAgencyMember = useIsAgencyMember()

  const [isFileSet, setIsFileSet] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)

  const [updateMandate] = useUpdateMandateMutation()
  const [uploadMandate] = useUploadMandateMediaMutation()

  const agencyId = useStoreState(
    (state) => state.estate.current?.seller?.agency.id
  )

  const { data: mandates } = useGetMandatesQuery({
    variables: {
      agencyId: String(agencyId),
    },
    skip: !agencyId,
  })

  const registryNumbers = useMemo(() => {
    if (!mandates?.mandate) return []
    return mandates.mandate.map((m) => m.registryNumber)
  }, [mandates])

  // find the last one to download
  const { data } = useGetMandateMediaSubscription({
    variables: {
      id: String(mandate?.id),
    },
    skip: !mandate?.id,
  })

  const {
    add,
    upload,
    // cancel,
    isUploaded,
    isUploading,
    isError,
    progress,
    id,
    bucketId,
    name,
  } = useFileUpload()

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!mandate) return
    const file = event.target.files?.[0]
    if (!file) return
    add({ file })
    setIsFileSet(true)

    if (!isFileSet) return
  }

  useEffect(() => {
    async function uploadFile() {
      if (!mandate) return
      if (!isFileSet) return
      const params = { id, bucketId, name }
      const { id: fileId } = await upload(params)
      setIsFileSet(false)

      if (!fileId) return
      const { data } = await uploadMandate({
        variables: {
          fileId: String(fileId),
          mandateId: String(mandate.id),
        },
      })

      if (!data?.insert_mandate_media_one) return

      await updateMandate({
        variables: {
          id: String(mandate?.id),
          values: {
            status: Mandate_Status_Enum.Available,
          },
        },
        onError: (error) => {
          toast({
            variant: 'destructive',
            description: error.message,
          })
        },
      })
    }
    uploadFile()
  }, [isFileSet])

  useEffect(() => {
    if (isUploaded) {
      toast({
        variant: 'success',
        description: t('FileUpload.toast.success'),
      })
    }
  }, [isUploaded])

  useEffect(() => {
    if (isError) {
      toast({
        variant: 'destructive',
        description: t('FileUpload.toast.error'),
      })
    }
  }, [isError])

  const handleDownloadMandate = async () => {
    const fileId = data?.mandate_media?.[0]?.fileId
    const { presignedUrl, error } = await nhost.storage.getPresignedUrl({
      fileId: String(fileId),
    })

    if (error)
      return toast({
        variant: 'destructive',
        title: 'Erreur lors de la récupération du lien de téléchargement',
        description: error.message,
      })

    if (!presignedUrl) return
    window.open(presignedUrl.url, '_blank')
  }

  const handleGenerateMandate = async () => {
    if (!mandate?.id) return
    const registryNumber = useGenerateRegisterNumber(registryNumbers)

    await updateMandate({
      variables: {
        id: mandate.id,
        values: {
          registryNumber,
          generatedAt: new Date().toISOString(),
        },
      },
    })
  }

  const isFileUploadedStatus =
    mandate?.status === Mandate_Status_Enum.Available ||
    mandate?.status === Mandate_Status_Enum.Active // In the future, will represent signed mandate

  const isGenerated = useMemo(() => {
    return !mandate?.id || !!mandate.generatedAt
  }, [mandate])

  return (
    <div className="tw-space-y-4">
      <p className="tw-text-2xl tw-font-semibold">
        {t('MandateStatusCard.heading')}
      </p>
      <Card>
        <CardContent className="tw-grid tw-gap-4 tw-pt-4">
          {(!mandate?.status ||
            mandate?.status === Mandate_Status_Enum.Draft) && (
            <>
              <div className="tw-py-3 tw-p-4  tw-bg-[#FFE8CC] tw-rounded-lg ">
                <p className="tw-text-sm tw-font-normal tw-text-[#D9841D] tw-flex tw-items-center">
                  <HourglassIcon
                    className="tw-h-4 tw-w-4 tw-mr-4"
                    strokeWidth={3}
                  />
                  {t(`MandateStatusCard.status.draft.text`)}
                </p>
              </div>
              <Button
                variant="green"
                className="tw-justify-center"
                disabled={!mandate}
              >
                <Label
                  htmlFor="file-upload"
                  className="tw-cursor-pointer tw-w-full"
                >
                  {isUploading && progress ? (
                    <Spinner />
                  ) : (
                    t('MandateStatusCard.status.draft.button')
                  )}
                </Label>
                <input
                  id="file-upload"
                  type="file"
                  className="tw-hidden tw-w-full"
                  onChange={handleFileChange}
                />
              </Button>
            </>
          )}

          {isFileUploadedStatus && (
            <>
              <div className="tw-py-3 tw-p-4 tw-bg-green-200 tw-rounded-lg ">
                <p className="tw-text-sm tw-font-normal tw-text-green-700 tw-flex tw-items-center">
                  <PencilIcon
                    className="tw-h-4 tw-w-4 tw-mr-4"
                    strokeWidth={3}
                  />
                  {t(`MandateStatusCard.status.available.text`)}
                </p>
              </div>
              <div className="tw-flex tw-justify-between tw-gap-3 tw-mt-4 tw-flex-wrap">
                <Button
                  variant="green"
                  className="tw-justify-center"
                  onClick={handleDownloadMandate}
                >
                  {t('MandateStatusCard.status.available.button.download')}
                </Button>

                {isAgencyMember && (
                  <Button variant="secondary" className="tw-justify-center">
                    <Label htmlFor="file-upload" className="tw-cursor-pointer">
                      {isUploading && progress ? (
                        <Spinner />
                      ) : (
                        t('MandateStatusCard.status.available.button.upload')
                      )}
                    </Label>
                    <input
                      id="file-upload"
                      type="file"
                      className="tw-hidden"
                      onChange={handleFileChange}
                    />
                  </Button>
                )}
              </div>
            </>
          )}

          <Button
            variant="secondary"
            onClick={() => setOpenConfirm(true)}
            disabled={isGenerated || !isAgencyMember}
          >
            {t(`MandateStatusCard.generate`)}
          </Button>
          <ConfirmationDialog
            open={openConfirm}
            onOpenChange={() => setOpenConfirm(false)}
            description="Attention, une fois le n° de mandat généré, vous ne pourrez pas modifier le contenu de la page mandat, souhaitez-vous confirmer ?"
            onConfirm={handleGenerateMandate}
          />
        </CardContent>
      </Card>
    </div>
  )
}
